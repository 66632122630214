import React, { Component } from 'react'
import { connect } from 'react-redux';

import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class AccountActivity extends Component {
    constructor(props) {
        super(props);       
    }

    getRecord = async (flg) => {
        let getIPad = "";
        try{
        if(flg!=1)
        {
        this.state.startDate=document.getElementById("date-time1").value;        
        this.state.endDate=document.getElementById("date-time2").value;
        }

        if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
            getIPad = this.props.ClntIPAddr;
        }
        else{
            getIPad = await commonClass.GetIPAddress();					
        }
        let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

        let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate)
        if(_flog)
    {   let lstrfromdate = this.state.startDate.split('/');
        var lstrstrt_datechg = lstrfromdate[1] + "/" + lstrfromdate[0] + "/" + lstrfromdate[2];

        let lstrTodate = this.state.endDate.split('/');
        var lstrEnd_datechg = lstrTodate[1] + "/" + lstrTodate[0] + "/" + lstrTodate[2];
        this.props.GetAccountActivity(this.props.user.mobileNo, lstrstrt_datechg, lstrEnd_datechg,intlangflag,getIPad);
    }

    }
    catch (ex) {
        commonClass.writeLog(ex,"AccActivity-getRecord");
        return false;
    }
    };
    reSetFilter() {
        try{
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    }
    catch (ex) {
        commonClass.writeLog(ex,"AccActivity-reSetFilter");
        return false;
    }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            AccountActivity: props.AccountActivity,
        }
    };

    componentDidMount() {
        try{
        window.timecalender();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    }
    catch (ex) {
        commonClass.writeLog(ex,"AccActivity-componentDidMount");
        return false;
    }
    };

    render() {
        const { t } = this.props;        
        return (
            <section id="cTab12" class="tabCont">
              <div className="accountDetls forpadd3">
              <div className="detailsHead">
                    <h2>{t('lblAccountSummary')}</h2>
                    </div>
                <div class="accutDetlsRow1">
                    <ul>
                        <li>
                        <div className="dateTimeCont">
                            <div class="HstryDetlsCont2">
                                <p>{t('lblfrom')} :</p>
                                <input class="startDate white hasDatepicker" type="text" value={this.state.startDate}  id="date-time1" name="startDate" placeholder="2017-06-10" onChange={(date) =>this.setState({ startDate: date.target.value })} />
                            </div>
                            <div class="HstryDetlsCont2">
                                <p>{t('lblto')} :</p>
                                <input class="endDate white hasDatepicker" type="text" value={this.state.endDate} id="date-time2" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                            </div>
                            </div>
                        </li>
                        <li>
                        <button onClick={() => { this.getRecord() }}>search</button>                            
                        </li>
                        {this.state.AccountActivity && this.state.AccountActivity.status == 0 && this.state.AccountActivity.data2.map((z, idx) =>  
                        <div>
                            <li>
										<span className="acctHintInfo">Account Summary for the period from {this.state.startDate} to {this.state.endDate}</span>
									</li>
									<li>
										<div className="acctValueCont">
											<div className="acctValueDetls">
												<p className="acctValTitle">{t('lblOpeningbal')}</p>
												<p className="acctValue">{z.OpeningBAL}</p>
											</div>
											<div className="acctValueDetls">
												<p className="acctValTitle">{t('lblClosingWalletBlnce')}</p>
												<p className="acctValue">{z.ClosingBAL}</p>
											</div>
										</div>
									</li>
									<li>
										<div className="acctValueCont">
											<div className="acctValueDetls">
												<p className="acctValTitle">{t('lblWinningAmt')}</p>
												<p className="acctValue">{z.Winning}</p>
											</div>
											<div className="acctValueDetls">
												<p className="acctValTitle">{t('lblRefComm')}</p>
												<p className="acctValue">{z.ReferralCommission}</p>
											</div>
										</div>
									</li>
                        </div>
    )}
                    </ul>
                </div>
               
                    <div class="trstractionHstryDetls">
                        <div class="trstractionHstryInrCont">
                        <div className="booking scrollbar">
                            <table>  
                            <tbody>                          
                                <tr>                                
                                <th className="Col13">{t('lblTransID')}</th>
                                    <th className="Col10">{t('lblDescription')}</th>                                      
                                    <th className="Col12">{t('lblAmount')} ({this.props.appvariables.currency})</th>
                                    <th className="Col14">{t('lblTransDate')}</th>                                    
                                </tr>                            
                                {this.state.AccountActivity && this.state.AccountActivity.status == 0 && this.state.AccountActivity.data.map((x, idx) =>
                                    <tr>
                                        <td>{x.TransID}</td>
                                        <td>{x.Descriptions}</td>                                        
                                        <td>{x.Amount}</td>
                                        <td>{x.TransDateTime}</td>                                        
                                    </tr>
                                )}
                                {this.state.AccountActivity && this.state.AccountActivity.status != 0 &&
                                    <tr>
                                        <td colspan="4">{this.state.AccountActivity.msg}</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>                   

<div class="trstractionHstryDetls">
                        <div class="trstractionHstryInrCont">
                        <div className="booking scrollbar">
                            <table>
                            <tbody>
                                <tr>                                    
                                    <th className="Col12">{t('lblTransDate')}</th>
                                    <th className="Col10">{t('lblPlayedAmt')} ({this.props.appvariables.currency})</th>
                                    <th className="Col14">{t('lblWinningAmt')} ({this.props.appvariables.currency})</th>
                                    <th className="Col12">{t('lblCommAmt')} ({this.props.appvariables.currency})</th>
                                    <th className="Col12">{t('lblAddmoneyAmt')} ({this.props.appvariables.currency})</th>
                                    <th className="Col12">{t('lblwithdrawalAmt')} ({this.props.appvariables.currency})</th>
                                </tr>
                                {this.state.AccountActivity && this.state.AccountActivity.status == 0 && this.state.AccountActivity.data1.map((x, idx) =>
                                    <tr>
                                        <td>{x.TransactionDate}</td>
                                        <td>{x.BetAmount}</td>
                                        <td>{x.WinAmount}</td>
                                        <td>{x.Commission}</td>
                                        <td>{x.AddMoney}</td>
                                        <td>{x.WithDraw}</td>
                                    </tr>
                                )}
                                {this.state.AccountActivity && this.state.AccountActivity.status != 0 &&
                                    <tr>
                                        <td colspan="6">{this.state.AccountActivity.msg}</td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                  
                                
           </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    const { AccountActivity } = state.accountReducer;
    const { user, appvariables } = state.loginReducer;
    return { AccountActivity, user, appvariables };
}

const mapActionToProps = {
    GetAccountActivity: accountActions.GetAccountActivity
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AccountActivity))