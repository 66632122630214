import React, { Component } from 'react'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';
//import { loginActions } from '../../action/loginAction';

class CookiePolicy extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //window.scrollTo(0, 0);
    };

    render() {
        const { i18n } = this.props;
        return (
            <div className="mainContainer termsbg">
                {/* <Header /> */}
                <div class="container">
                    <div class="logo2">
                        <img src="./assets/img/logo.png" alt="Bazookalotto Logo" />
                    </div>
                    {i18n.language == "en" ?
                        <div class="privacyCont">
                            <h1>Cookies Policy</h1>
                            <p>Welcome to <a href="http://carousel.com/">http://carousel.com/</a>(“Company,” “we” or “us”) through its product Bazooka lotto (www.Bazooka is the sole company authorized to operate the national lottery, licensed and regulated by the Ministry of Industry and Trade in charge of the gaming activities <a href="#">https://www.minicom.gov.com/.</a></p>
                            <p>The following Cookies policy, together with any documents expressly incorporate by reference (collectively, these “Cookies Policy”), govern you and any entity you represent (collectively, “you” or “your”), with respect to access and use of <a href="#"> https://Bazookalotto.com/</a> including any Content (as defined in this terms of service agreement), technology, processes, materials, functionality and services designed, offered, or marketed by the Company on or through <a href="#">https://Bazookalotto.com/</a>and at such other domain names used to identify websites on which the Company may offer Services (collectively, the “Service”), whether as a guest or a registered user.</p>
                            <p>This Privacy Notice explains how Bazookalotto.com deals with cookies. </p>
                            <h2>1. Application and Acceptance of the Policy</h2>
                            <p>1.1. By continuing to use Our online platformsand services, You are agreeing to the use of cookies (“the Cookies Policy”) and similar technologies for the purposes We describe in this Cookie policy. </p>
                            <p>1.2. If You do not accept the use of cookies and similar technologies, do not use this Website.</p>
                            <p>By using our Products, you consent to the terms of this Privacy Notice and to the processing of Personal Information for the purposes set forth herein. If you do not agree to this Privacy Notice, please do not use our Products.</p>
                            <h2>2.Understanding these terms</h2>
                            <p>2.1. In this policy, when we refer to "We“, ”Us", "Our" or “Ours”, we mean “Carousel Ltd. (<a href="#">http://carousel.com/)</a>, and when we refer to “You”, “Your”, “Yours” or “Yourself” We mean You, the person or the company accessing or using (“User”) our platforms; and together hereinafter referred to as “Parties”.</p>
                            <p>2.2. When using the term referred to as “User”, we mean the natural person (“Natural person”) or corporation (“Legal customer”) who enters into an agreement with Us and/or is registered on our online platforms. Natural User shall mean one who has its own legal personality that is an individual human being, as opposed to a Legal customer. Legal User shall mean a legal entity that is acting in a professional or commercial capacity.</p>
                            <p>2.3. When using the term “Cookie”, we shall be meaning the alphanumeric identifiers that our online platformstransfer to your computers hard driver or mobile device through your web browser. </p>

                            <h2>3. What is a cookie?</h2>
                            <p>3.1. A cookie is a simple text file that is stored on Your computer or mobile device by a Website’s server. Each cookie is unique to Your web browser. It will contain some anonymous data such as a unique identifier and the site name and some digits and numbers.</p>
                            <p>3.2. Paragraph 2 of the Section 23 of the Nigerian Constitution of 2015 mandates Us not to intercept, collect or store Your data without prior authorisation from Yourself.</p>
                            <p>3.3. Most websites You visit use cookies to improve Your user experience by allowing them to “remember” You, either for the duration of Your visit (using a “session cookie”) or for repeat visits (using a “persistent cookie”).</p>
                            <p>3.4. Cookies may be set by the website You are visiting (“first party cookies”) or they may be set by other websites which run content on the page You are viewing (“third party cookies”).</p>

                            <h2>4. What do cookies do?</h2>
                            <p>4.1. Cookies have lots of different jobs, like letting You navigate between pages efficiently, storing Your preferences, and improving Your experience of a website. Cookies make the interaction between You and websites faster and easier. If a website does not use cookies, it will think You are a new visitor every time You move to a new page on that website, for example, even after You “log in”, if You move to another page it will not recognize You and it will not be able to keep you logged in.</p>
                            <p>4.2. This is statistical data about our users’ browsing actions and patterns, and does not identify any individual.</p>

                            <h2>5. How does the Website use cookies?</h2>
                            <p>5.1 The Website uses different types of cookies to enhance and improve Your experience. The Website uses cookies for:</p>
                            <ul class="marleft">
                                <li><p>5.1.1Security: We use cookies to enable and support Our security features, for example: to authenticate Users, prevent fraudulent use of login credentials, and protect User data from unauthorized parties.</p></li>
                                <li><p>5.1.2Preferences: When you are signed in to Your account, cookies help Us display the correct data and personalize Your experience, by providing You with features, insights, and customized content. They can also help You fill out forms more easily, e.g., Request for information (RFI) forms. Loss of the data stored in a preference cookie may make the Website experience less functional, but should not prevent it from working.</p></li>
                                <li><p>5.1.3Session state: We collect data about how Our Users use and interact with the Website. This may include the pages You visit most often and when and where You get error messages. We use these “session state cookies” to help Us improve our Website and services. Blocking or deleting these cookies will not prevent the Website from working.</p></li>
                                <li><p>5.2.4. Analytics: These cookies help Us learn how our Website performs in different locations. We use cookies to understand and improve our services and features.</p></li>
                            </ul>

                            <h2>6. What third-party cookies does the Website use? </h2>
                            <p>Trusted partners like Banks and analytics companies like Google, through their Google Analytics service, may also place cookies on Your device. Please read our partners’ privacy policies listed on their website to ensure that You are comfortable with how they use cookies and to allow You to opt out if you would like.</p>
                            <h2>7. What should You do if You do not want cookies to be set?</h2>

                            <ul class="marleft">
                                <li><p>7.1. Some people find the idea of a website storing data on their computer or mobile device to be intrusive, particularly when these are stored and used by a third-party without them knowing. Although cookies are generally quite harmless, You may not, for example, want to see advertising that has been targeted to Your interests using Your browser’s history. If You prefer, You may choose to block some or all cookies, or even to delete cookies that have already been set; however, You should be aware that you might lose some functions of the Website.</p></li>
                                <li><p>7.2. If You want to restrict or block the cookies that are set by the Website, or any other website, You can do so through your browser’s setting. The “Help” function in your browser should explain how. The Help feature on most browsers will tell You how to prevent Your browser from accepting new cookies, how to have the browser notify You when You receive a new cookie, or how to disable cookies altogether. Additionally, You can disable or delete similar data used by Your browser’s add-ons, such as Flash cookies, by changing the add-on's settings or visiting the developer’s website. Because cookies allow You to take advantage of some of the Website's essential features, We recommend that you leave them turned on. For instance, if You block or otherwise reject our cookies, You will not be able to add games to Your Shopping card, proceed to checkout, or use any of the Website’s functionalities and services that require You to sign in.</p></li>
                                <li><p>7.3. Alternatively, You can visit <a href="#">www.aboutcookies.org</a>, which contains comprehensive information on how to do this on a wide variety of browsers. You will find general information about cookies and details on how to delete cookies from Your machine.</p></li>
                                <li><p>7.4. To opt-out of third-parties collecting any data regarding your interaction on Our Website, please refer to their websites for further information.</p></li>
                            </ul>
                            <h2>8. Contact information </h2>
                            <p>Questions about this Cookies policy and Our use of cookies should be sent to us by using Our contact form available at this link and choosing the subject “Privacy Concerns”.</p>
                        </div>
                        :
                        <div class="privacyCont">
                            <h1>AMABWIRIZA Y’IKORESHWA RY’AMAKURU</h1>
                            <p>Murakaza neza kuri <a href="http://carousel.com">http://carousel.com/</a>ari cyo kigo rukumbi cyemerewe gukoresha no kugenzura imikino ya tombola ku rwego rw’igihugu, kikaba ari ikigo cyahawe uburenganzira na minisiteri y’ubucuruzi n’inganda ifite iimikino mu nshingano zayo. <a href="#">https://www.minicom.gov.com/.</a></p>
                            <p>Aya mabwiriza y’ikoreshwa ry’amakuru, hakubiyemo ibyanditswe byose, yemeza mu buryo bweruye (byose hamwe, aya “mabwiriza y’ikoreshwa ry’amakuru”), irakuyobora hamwe n’ikigo icyaricyo cyose uhagarariye (byose hamwe, “wowe” or “yawe”), kubijyanye no kugera no gukoresha <a href="https://Bazookalotto.com/"> https://Bazookalotto.com/</a> harimo Ibirimo byose (nkuko byemejwe muri aya mabwiriza yemeza serivisi), tikinoloji, imikorere, ibikoresho, na serivisi zose zigurishwa na Carousel cyangwa se zitangirwa ku rubuga rwa <a href="#">https://Bazookalotto.com/</a>ndetse n’izindi mbuga zose zakoreshwa mu gusakaza no kwamamaza serivisi za Bazooka Lotto.</p>
                            <p>Aya mabwiriza agenga ubuzima bwite asobanura uburyo Bazookalotto.com ikoresha tekinoloji y’ikoreshwa ry’amakuru</p>
                            <h2>1. Gushyira mu bikorwa no kwemera aya mabwiriza</h2>
                            <p>1.1. Mu gukomeza gukoresha imbuga zacu na serivisi zacu, wemeye ikoreshwa rya tekinoloji y’ikoreshwa ry’amakuru (“amabwiriza y’ikoreshwa ry’amakuru”) hamwe n’ikoranabuhanga risa naryo ku bw’impamvu dusobanura muri aya mabwiriza y’ikoreshwa ry’amakuru.</p>
                            <p>1.2. Niba utemeye ikoreshwa rya tekinoloji y’ikoreshwa ry’amakuru n’irindi koranabuhanga risa nkaryo, wikoresha uru rubuga.</p>
                            <p>Mu gukomeza gukoresha ibicuruzwa byacu, wemeye ibikubiye muri aya mabwiriza agenga ubuzima bwite ndetse n’ikoreshwa ry’amakuru bwite ku bw’impamvu zateganyijwe. Niba utemeye aya mabwiriza agenga ubuzima bwite wikoresha ibicuruzwa byacu.</p>
                            <h2>2.Gusobanukirwa aya mabwiriza </h2>
                            <p>2.1. Muri aya mabwiriza, iyo tuvuze “Twebwe”, “Twe” cyangwa “Byacu” tuba tuvuze “Carousel Ltd. (<a href="http://carousel.com">http://carousel.com/)</a>, naho iyo tuvuze “Wowe”, “Yawe” cyangwa “Wowe ubwawe” tuba tuvuze wowe, umuntu cyangwa ikigo gikoresha imbuga zacu; twese hamwe muri rusange tukitwa “abagize aya mabwiriza”</p>
                            <p>2.2. Iyo dukoresha ijambo “umukoresha”, tuba tuvuze umuntu (“Umuntu”) cyangwa ikigo (“Umukiriya wemewe n’amategeko”) winjiye mu masezerano natwe ndetse akaba yiyandikishije ku mbuga zacu. Umukoresha bishatse kuvuga uwo ari we wese ufite ubuzima gatozi bivuze umuntu ku giti cye bitandukanye n’umukiriya wemewe n’amategeko. Umukoresha wemewe n’amategeko bivuze ikigo cyemewe n’amategeko gikora mu bushobozi bw’umwuga cyangwa ubucuruzi.</p>
                            <p>2.3. Iyo dukoresha ijambo “tekinoloji y’ikoreshwa ry’amakuru” tuba tuvuze ibiranga mibare (alphanumeric identifiers) imbuga zacu zohereza kuri disiki ya mudasobwa yawe cyangwa telephone ngendanwa biciye muri murandasi.</p>
                            <h2>3. Tekinoloji y’ikoreshwa ry’amakuru ni iki?</h2>
                            <p>3.1. Tekinoloji y’ikoreshwa ry’amakuru ni inyandiko ibikwa kuri mudasobwa cyangwa telephone ngendanwa na seriveri y’urubuga. Buri tekinoloji y’ikoreshwa ry’amakuru irihariye kuri murandasi yawe. Ifite amakuru atamenyekana akubiyemo ibirango bwite, izina ry’urubuga n’indi mibare itandukanye.</p>
                            <p>3.2. Igika cya 2 cy’igice cya 23 cy’Itegeko Nshinga ry’u Bazooka ryo muri 2015 ridutegeka kudahagarika, gukusanya cyangwa kubika amakuru yawe utabanje kubiduhera uruhushya.</p>
                            <p>3.3. Imbuga nyinshi usura zikoresha tekinoloji y’ikoreshwa ry’amakuru mu kunoza imikoreshereze y’urubuga ku barusura ubemerera “Kukwibuka”, mu gihe umaze ku rubuga cyangwa inshuro usura urubuga.</p>
                            <p>3.4. Tekinoloji y’ikoreshwa ry’amakuru ishyirwaho n’urubuga usura cyangwa igashyirwaho n’izindi mbuga zishyiraho ibiri ku rubuga.</p>
                            <h2>4. Tekinoloji y’ikoreshwa ry’amakuru ikora iki?</h2>
                            <p>4.1. Tekinoloji y’ikoreshwa ry’amakuru ifite imikorere igiye itandukanye irimo nko kukorohereza gusura ibice bitandukanye by’urubuga mu buryo bunoze, kubika ibyo ukunda, ndetse no kunoza imikoreshereze y’urubuga. Tekinoloji y’ikoreshwa ry’amakuru ituma imikoranire hagati yawe n’urubuga yoroha kandi ikihuta. Iyo urubuga rudakoresha tekinoloji y’ikoreshwa ry’amakuru, rugufata nk’umushyitsi mushya igihe cyose ugiye ku yindi paji y’urwo rubuga, urugero; ni yo umaze kwinjira ku rubuga, iyo ugiye ku yindi paji, ntabwo rukumenya ndetse ntabwo ugumamo.</p>
                            <p>4.2. Aya ni amakuru y’ibarurishamibare yerekeye ibikorwa by’abasura urubuga rwacu, ntabwo igaragaza imyirondoro by’uwo ari we wese.</p>
                            <h2>5. Urubuga rukoresha gute Tekinoloji y’ikoreshwa ry’amakuru?</h2>
                            <p>5.1 Urubuga rukoresha tekinoloji zitandukanye z’ikoreshwa ry’amakuru mu kuzamura no kunoza imikoreshereze y’urubuga. Urubuga rukoresha iyi tekinoloji mu buryo bukurikira:</p>
                            <ul class="marleft">
                                <li><p>5.1.1 Umutekano: Dukoresha tekinoloji y’ikoreshwa ry’amakuru mu kongerera ubushobozi ibikoresho by’umutekano byacu, urugero: kugenzura abakoresha urubuga rwacu, kurwanya uburiganya mu gukoresha ibyangombwa byo kwinjira ku rubuga, ndetse no kurinda amakuru y’umukoresha w’urubuga.</p></li>
                                <li><p>5.1.2 Ibyo ukunda: Iyo winjiye muri konti yawe, tekinoloji y’ikoreshwa ry’amakuru idufasha kukwereka amakuru ya nyayo ndetse no kukwereka ibyo ukunda byihariye, iguha imiterere, amakuru n’ibigize urubuga byihariye. Igufasha kandi kuzuza amafishi mu buryo bworosye, urugero: amafishi yo gusaba amakuru. Kubura kw’amakuru abitse muri tekinoloji y’ikoreshwa ry’amakuru bishobora gutuma imikoreshereze y’urubuga itagenda neza, ariko ntibirubuza gukora burundu.</p></li>
                                <li><p>5.1.3 Imiterere y’imikoreshereze y’urubuga: Dukusanya amakuru ajyanye n’uburyo abasura urubuga rwacu barukoresha. Ibi bikubiyemo amapaji usura cyane n’igihe n’aho ubona ubutumwa by’ibitagenze neza. Dukoresha ubu buryo mu kudufasha kuvugurura urubuga rwacu na serivisi zacu. Guhagarika no gusiba iyi tekinoloji ntibibuza urubuga gukora.</p></li>
                                <li><p>5.1.4 Isesengura: tekinoloji y’ikoreshwa ry’amakuru idufasha kumenya uko urubuga rwacu rukora mu bice bitandukanye. Tuyikoresha mu gusesengura no kuvugurura serivisi zacu.</p></li>
                            </ul>
                            <h2>6. Ni izihe tekinoloji z’ikoreshwa ry’amakuru z’abandi bantu urubuga rukoresha? </h2>
                            <p>Abafatanyabikorwa bizewe nk’amabanki n’ibigo bikora ubusesenguzi nka Google, biciye muri serivisi yabo ya Google Analytics, bashobora gushyira tekinoloji y’ikoreshwa ry’amakuru mu bikoresho byawe. Soma neza amabwiriza agenga ubuzima bwite y’abafatanyabikorwa bacu yanditse ku mbuga zabo kugira ngo wemeze ko nta kibazo utewe n’uko bakoresha tekinoloji y’ikoreshwa ry’amakuru ndetse no kukwemera guhitamo kutayikoresha niba ubyifuza.</p>
                            <h2>7. Wakora iki igihe utifuza ko tekinoloji y’ikoreshwa ry’amakuru ikoreshwa?</h2>
                            <ul class="marleft">
                                <li><p>7.1. Abantu bamwe na bamwe basanga kubikwa kw’amakuru kuri mudasobwa na telefone ngendanwa zabo bikozwe n’imbuga ari ukwinjira mu buzima bwabo bwite, cyane cyane iyo bikozwe n’abandi bantu batabizi. Nubwo tekinoloji y’ikoreshwa ry’amakuru ntacyo itwaye muri rusange, ushobora kuba utifuza kubona ibyamamajwe byagenewe ibyo ukunda hifashishijwe ibyo wasuye kuri murandasi. Niba ubyifuza ushobora guhagarika zimwe muri tekinoloji z’ikoreshwa ry’amakuru cyangwa zose muri rusange, cyangwa se ugasiba izamaze kujya mu bikoresho byawe; gusa ugomba kumenya ko ushobora gutakaza imikorere imwe n’imwe y’urubuga.</p></li>
                                <li><p>7.2. Niba wifuza kugabanya cyangwa guhagarika burundu tekinoloji y’ikoreshwa ry’amakuru yashyizweho n’urubuga, cyangwa urundi urwo ari rwo rwose ushobora kubikora uciye mu igenamitere (settings) rya murandasi yawe. Uburyo bwa “Help” buri muri murandasi yawe bugufasha kugusobanurira uko wabigenza. Uburyo bwa help muri murandasi zitandukanye buzakubwira uko warinda murandasi yawe kwemera tekinoloji y’ikoreshwa ry’amakuru, uko murandasi yawe yakumenyesha igihe wakiriye tekinoloji y’ikoreshwa ry’amakuru, cyangwa se uko wahagarika burundu tekinoloji y’ikoreshwa ry’amakuru. Ushobora no guhagarika cyangwa ugasiba amakuru asa ya add-ons yakoreshejwe na murandasi yawe uhindura igenamiterere rya add-ons. Kubera ko tekinoloji y’ikoreshwa ry’amakuru igufasha gukoresha bimwe mu bigize urubuga by’ingenzi, turakugira inama yo kuyigumishaho. Urugero, iyo uhagaritse tekinoloji y’ikoreshwa ry’amakuru yacu ntuzabasha kongera imikino ku ikarita yawe cyangwa gukoresha ibindi bigize urubuga rwacu na serivisi zacu zisaba kwinjira ku rubuga rwacu.</p></li>
                                <li><p>7.3. Ushobora gusura urubuga rwa <a href="#">www.aboutcookies.org</a>, rufite amakuru yuzuye y’uburyo wakoresha iyi tekinoloji kuri murandasi. Uzahasanga amakuru rusange kuri tekinoloji y’ikoreshwa ry’amakuru n’uburyo wayisiba muri mudasobwa yawe.</p></li>
                                <li><p>7.4. Kugira ngo uhagarike abandi bantu bakusanya amakuru ajyanye n’uburyo ukoresha urubuga rwacu, sura imbuga zabo ku bindi bisobanuro birambuye.</p></li>
                            </ul>
                            <h2>8. Uko watuvugisha </h2>
                            <p>Ibibazo bijyanye n’aya mabwiriza y’ikoreshwa ry’amakuru n’uburyo dukoresha tekinoloji y’ikoreshwa ry’amakuru byoherezwa ukoresheje ifishi yo gutangaho amakuru usanga hano ugahitamo ingingo ya “Privacy Concerns”.</p>
                        </div>
                    }
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(CookiePolicy))