import {appConstants} from "../helpers/actionType";
let cFamily = JSON.parse(localStorage.getItem('cFamily'));
cFamily = cFamily ? cFamily : {};

let cDraw = JSON.parse(localStorage.getItem('cDraw'));
cDraw = cDraw ? cDraw : {};

let allFamily = JSON.parse(localStorage.getItem('allFamily'));
allFamily= allFamily ? allFamily : [];

const initialState={games:allFamily,currentGamesFamily:cFamily,currentAvailableDraws:[],currentDraw:cDraw,serverTime:0,latestResult:[],casinoGames:[]};
export const gameReducer=(state=initialState,action)=>{
    switch(action.type)
    {
        case appConstants.GET_GAMEFAMILY_SUCCESS:                        
          return {
            ...state,
            games:action.games
          };   
        case appConstants.GET_GAMEFAMILY_ERROR:
          return {...state};
          case appConstants.SET_GAMEFAMILY:                        
          return {
            ...state,
            currentGamesFamily:action.gFamily
          };
          case appConstants.GET_AVAILDRAWS_SUCCESS:                        
          return {
            ...state,
            currentAvailableDraws:action.currentAvailableDraws
          };
          case appConstants.GET_AVAILDRAWS_ERROR:
          return {
            ...state,
            currentAvailableDraws:[]
          };

          case appConstants.BUY_SUCCESS:                        
          return {
            ...state,
            myBet:action.myBet
          };           
          case appConstants.BUY_ERROR:
          return {
            ...state,
            myBet:null
          };
          case appConstants.GET_LATEST_RESULT_SUCCESS:
            return {
              ...state,
              latestResult: action.lResult
            };            
          case appConstants.GET_LATEST_RESULT_ERROR:
            return {
              ...state,
              latestResult: null
            };
            case appConstants.GET_CASINO_GAMES:
            return {
              ...state,
              casinoGames: action.casinoGame
            };
         //create new reducer for servertime
          // case appConstants.GET_SERVERTIME_SUCCESS:                        
          // return {
          //   ...state,
          //   serverTime:action.serverTime
          // };     
          // case appConstants.GET_SERVERTIME_ERROR:
          //   return {...state};        
            
        default:
          return {...state}
    }

}