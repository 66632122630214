import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../action/accountAction';
import { alertActions } from "../../action/alertAction";
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withTranslation } from 'react-i18next';

class NetworkRewards extends Component {
    constructor(props) {
        super(props);

		this.state = {		
			appsts: 1
		}
		const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

		//if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;
		
		//let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.Gethowtoplay(langflag,1);		
    }	

	static getDerivedStateFromProps(props, state) {
        return {            
            gethowtoplay: props.gethowtoplay != undefined ? props.gethowtoplay : {}
        }
    };	

	 tet=()=> {

		//let ddd='<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p><h2>EXAMPLE:</h2>		<div className="contntPrizeTble"><div className="contntInrPrizeTble"></div></div>';
		let ddd=this.state.gethowtoplay;
		 let dd= <div dangerouslySetInnerHTML={{__html:ddd }}></div>			
		  return dd;
	}
    render() {
        const { t } = this.props;
		const { appsts } = this.state;
        return (
			<div>
            <div className="mainContainer">	
			{appsts	== 1 && <Header /> }
		<div className="container forPadd2">
		<div className="privacyCont addContforPadd">
			{/* <div>{this.state.gethowtoplay}</div> */}
				{this.tet()}		  		
			
				{/* <h1>AFFILIATE NETWORK REWARDS</h1>	
				<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p>
								<h2>EXAMPLE:</h2>
								<div className="contntPrizeTble">										
										<div className="contntInrPrizeTble">											
											<table>
												<tbody>
													<tr>
														<th>PRINCIPAL PLAYER (YOU)</th>
														<th>REFERENCE</th>
														<th>PRINCIPAL AGENT</th>
														<th>TIER</th>
														<th>COMMISSION</th>														
													</tr>
													<tr>
														<td>1st Referral</td>
														<td>Referred by YOU</td>
														<td>AGENT</td>
														<td>Tier 1</td>
														<td>6%</td>												
													</tr>
													<tr>
														<td>2nd Referral</td>
														<td>Referred by your Tier 1</td>
														<td>SUB AGENT</td>
														<td>Tier 2</td>
														<td>3%</td>														
													</tr>
													<tr>
														<td>3rd Referral</td>
														<td>Referred by Tier 2</td>
														<td>PLAYER</td>
														<td>Tier 3</td>
														<td>1%</td>														
													</tr>													
												</tbody>
											</table>											
										</div>
										</div>

										<ul className="marleft">
										<li><p className="text-bold">•	PRINCIPAL PLAYER (YOU) - Principal Agent</p></li>
										<li><p className="text-bold">•	1ST REFERRAL - Agent (6%) First Tier</p></li>
										<li><p className="text-bold">•	2ND REFERRAL - Sub Agent (3%) Second Tier</p></li>
										<li><p className="text-bold">•	3RD REFERRAL - Bottom Player (1%) Third Tier</p></li>
									</ul>
									<ul className="marleft">
										<li><p>1.The first column shows your relationships to the players you have referred and players that have been referred by the other tiers, e.g., your 1st Tier Refers the 2ndTier, and the 2nd tier refers the 3rdTier.</p></li>
										<li><p>2.The thirdcolumn illustrates the same order, by relating them to a laid-out agency structure.</p></li>
										<li><p>3.The fifthcolumn illustrates the percentage commissions due to the Principal Player from each tier of referrals.</p></li>
										<li><p>4.Obviously the more actively you recruit referees to play BazookaLotto, the higher your potential commissions.</p></li>
										<li><p>5.If we use 30 as an average number of people referred by the principal agent, it means that you as the Principal Player will have 30 people referred by you and under your 1st Referral tier. You will make 6% commissions from your 1st Tierfrom the money they spend playing the games.</p></li>
										<li><p>6.As we’re using 30, let us assume that each of your 1st Tier also refers 30 people to sign up and play the game, thereby creating your 2nd Referral tier. That means your 2nd Referral tier will have 900 people, from which you earn 3% on all their total spend playing the game.</p></li>
										<li><p>7.Likewise your 2nd Referral tier each recruiting 30 people (900 x 30) will mean you will have 27,000 players in your 3rd Referral tier. You will make 1% from all monies spent playing the game by each of your 3rd Referrals.</p></li>
										<li><p>8.BazookaLotto, therefore, rewards players who recruit other players and encourage them to play regularly.</p></li>
										<li><p>9.BazookaLotto also ensures that only active players are eligible for commissions, which ensures you benefit from your various tiers. There is a minimum required spend of N1,500 by the 17th of each month to remain within the agency network and qualify to collect commissions.</p></li>
										<li><p>10.This is why everybody is a winner on BazookaLotto because you win in multiple ways : </p>
										<p>i.	One, by being lucky and picking winning numbers as you play the game.</p>
										<p>ii.	Two, by recruiting others to play and benefitting from our generous commission structure.</p>
										<p>iii.	By buying bulk discounted game playing credit, which you can either use to play or sell to players in your network and other networks.</p>
										</li>

									</ul> */}
			</div>
		</div>		
	</div>
	{appsts	== 1 && <Footer /> }
	</div>
        )
    };
}

function mapStateToProps(state) {
	const { gethowtoplay } = state.accountReducer;    
    return { gethowtoplay };
}
const mapActionToProps = {
	Gethowtoplay: accountActions.Gethowtoplay
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(NetworkRewards))