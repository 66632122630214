import { appConstants } from '../helpers/actionType';
import { userService } from '../services/userService';
import { history } from '../helpers/history';
import { alertActions } from "../action/alertAction";
import { commonClass } from "..//helpers/commonClass"

export const loginActions = {
	login,
	loginSkip,
	registration,
	otpAuthentication,
	setPage,
	forgotPassword,
	changePassword,
	logout,
	setPageMenu,
	getBalance,
	reSendOtp,
	saveCustomerComplaint,
	saveAgentRequest,
	GetUserProfile
}


function loginSkip(_flog) {
	let _timeout = (1000 * 60 * 2);
	return dispatch => {
		let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: _timeout, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode: "", kycstatus: "", kycapprvalstatus: "" };
		localStorage.setItem('user', JSON.stringify(userdata));
		dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
		if (_flog != 1) {
			history.push('/');
		}
	}
};

function login(mobileNo, password, langID, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			dispatch({ type: appConstants.LOADING });						
			
			let data = `'${mobileNo}','${password}','${getIPad}',2,''`;  
			//MobAPI -- CrossRefNo,TVN,SESSION,IMENO,ConfigurationID,DeviceID,Version,MobileNo,PWD,Languageid
			
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${mobileNo}','${password}',${langID}`; 
			commonClass.writeLog(data, "App_LoginReq", getIPad);

			userService.login(data)
				.then(
					user => {
						dispatch({ type: appConstants.LOADED });
						let ClntIPAddr=getIPad;
						dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});
						let strArr = user.data.split('~');
						if (strArr[0] == '0') {
							setTimeout(() => { dispatch(alertActions.success(strArr[8])) }, 500); //dispatch(alertActions.success(strArr[8]));
							////let dt=(new Date().getTime() + (1 * 1 * 1 * 60 * 1000)); //(1 * 24 * 60 * 60 * 1000) //day hour  min  sec  msec 												
							//let userdata = { userid: strArr[2], username: strArr[3], mobileNo: strArr[2], balance: strArr[8], winbalance: strArr[9], loginKey: strArr[7], emailID: strArr[4], loginExpiry:parseInt(20) };
							let userdata = { userid: strArr[1], username: strArr[2], mobileNo: strArr[1], winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: strArr[3], winbalance: strArr[4], loginKey: strArr[5], emailID: strArr[6], loginExpiry: parseInt(strArr[7]), LoginMsg: strArr[8], AgentStatus: strArr[9], ImgBase64str: strArr[10], PlayLastName: strArr[11], PlayRefMobNo: strArr[12], myRefralcode: strArr[13], kycstatus: strArr[14], kycapprvalstatus: strArr[15] };
							localStorage.setItem('user', JSON.stringify(userdata));
							dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
							history.push('/');
						}
						else {
							let error = strArr[1];
							dispatch({ type: appConstants.LOGIN_ERROR, error });
							dispatch(alertActions.error(error));

							let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
							localStorage.setItem('user', JSON.stringify(userdata));
							dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.LOGIN_ERROR, error });
						dispatch(alertActions.error(error));

						let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
						localStorage.setItem('user', JSON.stringify(userdata));
						dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex,"", getIPad);
	}
};

//function registration(regFirstName, regLastName, regPassword, regEmailID, regMobileNo, regDob, regReffererMobileNo, regOtpCode, ipAddress, regRefNo,langID) {
function registration(regFirstName, regLastName, regPassword, regEmailID, regMobileNo, regReffererMobileNo, ipAddress, regRefNo, langID, ReigtUserFlag, ImageBase64str) {
	try {
		return dispatch => {
			let ClntIPAddr=ipAddress;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			dispatch({ type: appConstants.LOADING });
			////let data = `0,'0','${regMobileNo}','${regPassword}','${regFirstName} ${regLastName}','${regReffererMobileNo}','${regEmailID}','${regDob}','${regOtpCode}','${langID}'`;
			let data = `'${regFirstName}','${regEmailID}','${regMobileNo}','${regPassword}','${regReffererMobileNo}','','${ipAddress}','${ImageBase64str}','${ReigtUserFlag}','${regLastName}'`;			

			//let data = `'0','0','0','${ipAddress}','0','1','${process.env.REACT_APP_VERSION}','${regFirstName}','${regEmailID}','${regMobileNo}','${regPassword}','${regReffererMobileNo}','${ImageBase64str}',${ReigtUserFlag},'${regLastName}',${langID}`; 
			commonClass.writeLog(data, "App_RegistReq", ipAddress);

			userService.registration(data)
				.then(
					reg => {
						dispatch({ type: appConstants.LOADED });
						let ClntIPAddr=ipAddress;
						dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {

							//This is register only
							// dispatch(alertActions.success(strArr[1])); //setTimeout(() => { dispatch(alertActions.success(strArr[1])) }, 500); //dispatch(alertActions.success(strArr[1]));
							// let pageFlog = 0;
							// dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog });
							// setTimeout(() => { history.push('/') }, 4000);

							// let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "",ImgBase64str:"", PlayLastName:"",PlayRefMobNo:"",kycstatus:"", kycapprvalstatus:"" };
							// localStorage.setItem('user', JSON.stringify(userdata));
							// dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });


							//This is login after registration automatically
							let datalgn = `'${regMobileNo}','${regPassword}','${ipAddress}',2,''`;
							//let datalgn = `'0','0','0','${ipAddress}','0','1','${process.env.REACT_APP_VERSION}','${regMobileNo}','${regPassword}',${langID}`; 
							commonClass.writeLog(datalgn, "App_DirectLoginReq", ipAddress);

							userService.login(datalgn)
								.then(
									user => {
										dispatch({ type: appConstants.LOADED });
										let strArrlgn = user.data.split('~');
										if (strArrlgn[0] == '0') {
											setTimeout(() => { dispatch(alertActions.success(strArrlgn[8])) }, 500);
											let userdata = { userid: strArrlgn[1], username: strArrlgn[2], mobileNo: strArrlgn[1], winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: strArrlgn[3], winbalance: strArrlgn[4], loginKey: strArrlgn[5], emailID: strArrlgn[6], loginExpiry: parseInt(strArrlgn[7]), LoginMsg: strArrlgn[8], AgentStatus: strArrlgn[9], ImgBase64str: strArrlgn[10], PlayLastName: strArrlgn[11], PlayRefMobNo: strArrlgn[12], myRefralcode: strArrlgn[13], kycstatus: strArrlgn[14], kycapprvalstatus: strArrlgn[15] };
											localStorage.setItem('user', JSON.stringify(userdata));
											dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
											history.push('/');
										}
										else {
											let error = strArrlgn[1];
											dispatch({ type: appConstants.REGISTER_ERROR, error });
											let strmsg = "You are registered successfully. Kindly Login. Happy Gaming!";
											dispatch(alertActions.error(strmsg));

											let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
											localStorage.setItem('user', JSON.stringify(userdata));
											dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
										}
									},
									error => {
										dispatch({ type: appConstants.LOADED });
										error = error.message;
										dispatch({ type: appConstants.REGISTER_ERROR, error });
										let strmsg = "You are registered successfully. Kindly Login. Happy Gaming!";
										dispatch(alertActions.error(strmsg));

										let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
										localStorage.setItem('user', JSON.stringify(userdata));
										dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
									}
								);
						}
						else if (strArr[0] == '2') {
							dispatch(alertActions.success(strArr[1]));
							let pageFlog = 3;
							let otpMobileNo = strArr[2];
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });

							let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
							localStorage.setItem('user', JSON.stringify(userdata));
							dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
						}
						else {
							let error = strArr[1];
							dispatch({ type: appConstants.REGISTER_ERROR, error });
							dispatch(alertActions.error(error));

							let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
							localStorage.setItem('user', JSON.stringify(userdata));
							dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));

						let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
						localStorage.setItem('user', JSON.stringify(userdata));
						dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex,"", ipAddress);
	}
};

function forgotPassword(forgotMobileNo, ConfirmforgotMobileNo, langID, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			dispatch({ type: appConstants.LOADING });
			let data = `'${forgotMobileNo}','${ConfirmforgotMobileNo}'`; //let data = `'${forgotMobileNo}','${langID}'`;
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${forgotMobileNo}','${ConfirmforgotMobileNo}',${langID}`; 
			commonClass.writeLog(data, "App_fgpwdReq");

			userService.forgotPassword(data)
				.then(
					reg => {
						dispatch({ type: appConstants.LOADED });
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1])); //setTimeout(() => { dispatch(alertActions.success(strArr[1])) }, 500); //dispatch(alertActions.success(strArr[1]));

							let pageFlog = 0;
							let otpMobileNo = forgotMobileNo;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
							setTimeout(() => { history.push('/') }, 4000);
						}
						else {
							let error = strArr[1];
							dispatch({ type: appConstants.REGISTER_ERROR, error });
							dispatch(alertActions.error(error));

							let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
							localStorage.setItem('user', JSON.stringify(userdata));
							dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));

						let userdata = { userid: "0", username: "", mobileNo: "0", winningAmt: "0", currentcommissions: "0", availabeCommissions: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, LoginMsg: "", AgentStatus: "", ImgBase64str: "", PlayLastName: "", PlayRefMobNo: "", myRefralcode:"", kycstatus: "", kycapprvalstatus: "" };
						localStorage.setItem('user', JSON.stringify(userdata));
						dispatch({ type: appConstants.LOGIN_SUCCESS, userdata });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function changePassword(forgotMobileNo, RegEmailID, oldPassword, newPassword, langID, IPAddr) {
	try {
		return dispatch => {
			let ClntIPAddr=IPAddr;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});			

			let data = `'${forgotMobileNo}','${RegEmailID}','${oldPassword}','${newPassword}','${IPAddr}'`;
			//let data = `'0','0','0','${IPAddr}','0','1','${process.env.REACT_APP_VERSION}','${forgotMobileNo}','${RegEmailID}','${oldPassword}','${newPassword}',${langID}`; 

			commonClass.writeLog(data, "App_chgpwdReq",IPAddr);

			userService.changePassword(data)
				.then(
					reg => {
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							let pageFlog = 0;
							let otpMobileNo = forgotMobileNo;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
							dispatch(alertActions.success(strArr[1]));

							let Lgotdata = `'${forgotMobileNo}'`;
							//let Lgotdata = `'0','0','0','${IPAddr}','0','1','${process.env.REACT_APP_VERSION}','${forgotMobileNo}',${langID}`; 
							userService.logout(Lgotdata); //logout();
							setTimeout(() => { history.push('/login') }, 4000);
							dispatch({ type: appConstants.LOGOUT });
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex,"",IPAddr);
	}
};

function otpAuthentication(regMobileNo, regOtp, langID) {
	try {
		return dispatch => {
			let data = `'${regMobileNo}','${regOtp}','${langID}'`;
			userService.otpAuthentication(data)
				.then(
					reg => {
						if (reg.Status == '0') {
							dispatch(alertActions.success(reg.Msg));
							let pageFlog = 2;
							dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog });
							history.push('/');
						}
						else {
							let error = reg.Msg;
							dispatch({ type: appConstants.REGISTER_ERROR, error });
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function reSendOtp(regMobileNo, regEmailID, otpFor, langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${regMobileNo}','${regEmailID}',${otpFor},'${langID}'`;
			userService.reSendOtp(data)
				.then(
					reg => {
						dispatch({ type: appConstants.LOADED });
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
							logout();
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error = error.message;
						dispatch({ type: appConstants.REGISTER_ERROR, error });
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetUserProfile(mobileNo, UpdateEmailID, ConfirmEmailID, langID, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${UpdateEmailID}','${ConfirmEmailID}','${langID}'`;
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${mobileNo}','${UpdateEmailID}','${ConfirmEmailID}',${langID}`; 
			userService.getUserProfile(data)
				.then(
					userProfile => {
						let strArr = userProfile.split('~');

						if (strArr[0] == '0') {														
							let emailID = strArr[2];
							dispatch({ type: appConstants.GET_USER_PROFILE, emailID });
							dispatch(alertActions.success(strArr[1]));							
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = userProfile.data;
							dispatch(alertActions.error(error));
						}

						//dispatch({ type: appConstants.LOADED });
						//dispatch({ type: appConstants.GET_USER_PROFILE, profile });
					},
					error => {
						//let profile = { userProfile: null };
						//dispatch({ type: appConstants.GET_USER_PROFILE, profile });
						//dispatch({ type: appConstants.LOADED });

						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function getBalance(regMobileNo, loginKey, langID) {
	try {
		return dispatch => {
			let data = `'${regMobileNo}','${loginKey}','${langID}'`;
			//let data = `'0','0','0','1','0','1','${process.env.REACT_APP_VERSION}','${regMobileNo}','${loginKey}',${langID}`;
			userService.getBalance(data)
				.then(
					reg => {
						let _balArr = reg.data.split('@')[0].split('~');
						if (_balArr[0] == '0') {

							let winningAmt = _balArr[1];
							let currentcommissions = _balArr[2];
							let availabeCommissions = _balArr[3];
							let winbalance = _balArr[4];
							let balance = _balArr[5];
							let Totwithdrawal = _balArr[7];
							let Nooftktplay = _balArr[8];
							let affiliateID = _balArr[9];
							let totWing = _balArr[11];
							let withdrawlimit = _balArr[12];
							let AutoPurchseTkt = _balArr[13];

							let kycstatus = _balArr[14];
							let kycapprvalstatus = _balArr[15];

							let WithdrawApprSts = reg.data.split('@')[1];

							dispatch({ type: appConstants.GET_BALANCE, winningAmt, currentcommissions, availabeCommissions, balance, winbalance, Totwithdrawal, Nooftktplay, affiliateID, totWing, withdrawlimit, WithdrawApprSts, AutoPurchseTkt, kycstatus, kycapprvalstatus });
						}
						else if (_balArr[0] == '4') {
							dispatch(alertActions.error(_balArr[1]));
							setTimeout(() => { history.push('/login') }, 2000);
						}
					},
					error => {
						let winningAmt = 0;
						let currentcommissions = 0;
						let availabeCommissions = 0;
						let winbalance = 0;
						let balance = 0;
						let Totwithdrawal = 0;
						let Nooftktplay = 0;
						let affiliateID = 0;
						let AutoPurchseTkt = 0;
						let totWing = 0;
						let withdrawlimit = "0,0";
						let WithdrawApprSts = "";						

						dispatch({ type: appConstants.GET_BALANCE, winningAmt, currentcommissions, availabeCommissions, balance, winbalance, Totwithdrawal, Nooftktplay, affiliateID, totWing, withdrawlimit, WithdrawApprSts, AutoPurchseTkt });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function setPage(pageFlog, otpMobileNo) {
	return dispatch => {
		dispatch({ type: appConstants.REGISTER_SUCCESS, pageFlog, otpMobileNo });
	}
};

function setPageMenu(pageMenuID) {
	return dispatch => {
		dispatch({ type: appConstants.SET_PAGE_MENU, pageMenuID });
	}
};

function saveCustomerComplaint(playerName, mobNo, email, complaint, langID) {
	try {
		return dispatch => {
			let data = `'${playerName}','${mobNo}','${email}','${complaint}','${langID}'`;
			userService.saveCustomerComplaint(data)
				.then(
					reg => {
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function saveAgentRequest(playerName, mobNo, email, businessName, businessLocation, langID) {
	try {
		return dispatch => {
			let data = `'${playerName}','${mobNo}','${email}','${businessName}','${businessLocation}','${langID}'`;
			userService.saveAgentRequest(data)
				.then(
					reg => {
						let strArr = reg.data.split('~');
						if (strArr[0] == '0') {
							dispatch(alertActions.success(strArr[1]));
						}
						else if (strArr[0] != '0') {
							dispatch(alertActions.error(strArr[1]));
						}
						else {
							let error = reg.data;
							dispatch(alertActions.error(error));
						}
					},
					error => {
						error = error.message;
						dispatch(alertActions.error(error));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

// function logout() {
// 	userService.logout();
// 	history.push('/login');
// 	return { type: appConstants.LOGOUT }
// };

function logout(mobileNo,langID, IPAddr) {

	try {
		return dispatch => {
			let Lgotdata = `'${mobileNo}'`;

			//let Lgotdata = `'0','0','0','${IPAddr}','0','1','${process.env.REACT_APP_VERSION}','${mobileNo}',${langID}`; 

			userService.logout(Lgotdata)
				.then(
					reg => {
						history.push('/login');
						dispatch({ type: appConstants.LOGOUT });
					},
					error => {
						history.push('/login');
						dispatch({ type: appConstants.LOGOUT });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};


