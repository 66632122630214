import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
class Right extends Component {
    constructor(props) {
        super(props);

    }
    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        };
    }
    render() {
		const { t } = this.props;
        return (            
                <div class="col-md-4">
                    <div class="betslip bg1">
                        <h2>{t('lblgameslip')}</h2>
                        <div class="betSlip">
                            {this.state.cartItems && this.state.cartItems.length <= 0 && <div className="placeYorder bg1">
                                <p className="warningMsg bounceIn animated infinite">{t('lblplcurbet')}</p>
                            </div>}
                            <ul className="border-color-left border-color-top border-color-right scrollbar">
                                {this.state.cartItems.map((x, indx) =>
                                    <li key={`bet_${x.id}`}>
                                        <div class="betSlipLft">
                                            <span>{x.drawDateTime} {x.gameName}</span>
                                            <div className="betClose" onClick={() => { this.props.removeFromCart(x.id) }}>
                                                <a className="betCloseBtn"><i className="fa fa-times" aria-hidden="true"></i></a>
                                            </div>
                                        </div>

                                        <div class="betSlipBall">
                                        {x.balls && x.balls.map((bx, indx1) => <div key={`lstrslb_${indx1}`} id={`${x.id}_${bx.id}`} className="betregularBalls">{bx.id}</div>)}
                                        {x.A_balls.length > 0 && <small> - </small>}
                                        {x.A_balls && x.A_balls.map((bx, indx1) =><div key={`lstrslb_${indx1}`} id={`${x.id}_${bx.id}`} className="betregularBalls">{bx.id}</div>)}                      
                  </div>
                  <div class="BetslipDraw">
                    <span>{x.ClassID}@{x.amount}</span>
                    <small>{x.total}</small>
                  </div>  
                                     
                                    </li>
                                )}

                            </ul>
                        </div>

                        <div class="countArea">
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotbet')} : {this.state.cartItems.length}</span>
                            </div>
                            <div class="betSlipBtomContNew">
                                <span class="lftTxt">{t('lbltotamt')} : {this.state.cartItems.reduce((a, b) => a = a + b.total, 0)}</span>
                            </div>
                        </div>
                        <div class="lottoBtns">
                            <button class="YellowBg sucessShown" onClick={() => { this.props.clearCart() }}>{t('lblclear')}</button>
                            <button class="btn1 newcoShown" onClick={() => { this.props.buyLotteryBet() }}>{t('lblBuy')}</button>
                        </div>
                    </div>
                </div>    
        );
    }
}


function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { cartItems } = state.lottoReducer;
    return { user, cartItems };
}

const mapActionToProps = {

}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Right))