import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { alertActions } from '../../../action/alertAction';
import { withTranslation  } from 'react-i18next';
import { commonClass } from "../../../helpers/commonClass"

class Addmoney extends Component {

    constructor(props) {
        super(props);
        this.state = {
            txnNo: ""
        };
    }
    // submitAddMoney = () => {
    //     if (this.state.txnNo.trim() != "") {
    //         var sTime = new Date(this.props.serverTime);
    //         let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;
    //         this.state.txnNo = this.state.txnNo.replace(/'/g, "''");
    //         this.props.Addmoney(this.props.user.mobileNo, this.state.txnNo, TransID,this.props.i18n.language);
    //         this.clearAddMoney();
    //     }
    //     else {
    //         this.props.error(this.props.t('lblInvaildTransactionCode'));
    //     }
    // };

    handleInputChange(e) {        
        let name = e.target.name;
        let value = e.target.value;

       document.getElementById('row1').Checked = false;        
       document.getElementById('row2').Checked = false;
       document.getElementById('row3').Checked = false;
       document.getElementById('row4').Checked = false;
       document.getElementById('row5').Checked = false;
       document.getElementById('row6').Checked = false;

        e.target.Checked = true;

if(name == 'radio1'){window.location = value; }
else if(name == 'radio2'){window.location = value; }
else if(name == 'radio3'){window.location = value; }
else if(name == 'radio4'){window.location = value; }
else if(name == 'radio5'){window.location = value; }
else if(name == 'radio6'){window.location = value; }
    };

    clearAddMoney = () => {
        this.setState({
            txnNo: ""
        });
        this.state.txnNo = "";
    };
    render() {
        const { t } = this.props;        
        //let _addUrl = `${process.env.REACT_APP_PG_URL}/Paymode/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`;
        //let _addMTNUrl = `${process.env.REACT_APP_PG_URL}/MTN/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}|login_key~${this.props.user.loginKey}`;
        let _edata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1`)
        let _edataTFC=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1~1`) //Tstatus = 1
        let _edataTCC=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1~2`) //Tstatus = 2

        let _addUrl = `${process.env.REACT_APP_PG_URL}/payment/Addmoney?PlayerInfo=${_edata}`; //let _addUrl = `${process.env.REACT_APP_ADDMONEYPG_URL}/AddFundCard.aspx?PlayerInfo=${_edata}`;
        let _addUrlFWW = `${process.env.REACT_APP_ADDMONEYPG_URL}/fundwalletwin.aspx?PlayerInfo=${_edata}`;
        let _addUrlP2M = `${process.env.REACT_APP_ADDMONEYPG_URL}/P2PMTransfer.aspx?PlayerInfo=${_edata}`;
        let _addUrlLCP = `${process.env.REACT_APP_ADDMONEYPG_URL}/LoadCreditPIN.aspx?PlayerInfo=${_edata}`;
        let _addUrlTFC = `${process.env.REACT_APP_ADDMONEYPG_URL}/TransferCredit.aspx?PlayerInfo=${_edataTFC}`;
        let _addUrlTCC = `${process.env.REACT_APP_ADDMONEYPG_URL}/TransferCredit.aspx?PlayerInfo=${_edataTCC}`;
        return (
            <section class="tabCont">
                <div className="accountDetls forpadd1">
                <div class="detailsHead">
                    <h2>{t('lblAddMoney')}
                    
                    </h2>
                    </div>
                    <div class="accountDetlsInr">                        
                            <span><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.balance}</span>                        
                        {/*<div class="accountinr">
                            <input type="text" maxLength="30" placeholder="TXN No *" value={this.state.txnNo} onChange={(e) => this.setState({ txnNo: e.target.value })} />
                        </div>
                        <div class="accountBtn">
                            <a class="blueBg" onClick={() => { this.submitAddMoney() }}>{t('lblAddMoney')}</a>
        </div>*/}
                    </div>
                    <div className="depstMoney">
								<form>
									<div className="form-group">
										<input onChange={this.handleInputChange} type="radio" id="row1" name="radio1" value={_addUrl}/>
										<label for="row1">
											<span>Debit/Credit card</span>
										</label>                                        
									</div>
									<div className="form-group">
										<input onChange={this.handleInputChange} type="radio" id="row2" name="radio2" value={_addUrlFWW} />
										<label for="row2">
											<span>Fund Wallet from Winnings</span>
										</label>
									</div>
									<div className="form-group">
										<input onChange={this.handleInputChange} type="radio" id="row3" name="radio3" value={_addUrlP2M} />
										<label for="row3">
											<span>Transfer Credit to other player</span>
										</label>
									</div>
									<div className="form-group">
										<input onChange={this.handleInputChange} type="radio" id="row4" name="radio4" value={_addUrlLCP} />
										<label for="row4">
											<span>Load Credit PIN</span>
										</label>
									</div>
									<div className="form-group">
										<input onChange={this.handleInputChange} type="radio" id="row5" name="radio5" value={_addUrlTFC} />
										<label for="row5">
											<span>Transfer Credit from Winnings</span>
										</label>
									</div>
									<div className="form-group">
										<input onChange={this.handleInputChange} type="radio" id="row6" name="radio6" value={_addUrlTCC} />
										<label for="row6">
											<span>Transfer Credit from Commission</span>
										</label>
									</div>
								</form>
							</div>

                            <div className="payStacks">
							<p>{t('lblOtherMethods')} </p>
								<ul>
									<li>
										<a href="https://paystack.com/" target="_blank"><img src="./assets/img/icons/Paystackimg.png"/></a>
									</li>
									<li>
                                    <a href="#"><img src="./assets/img/icons/MasterCard_Logo.jpg"/></a>
									</li>           
									<li>            
                                    <a href="#"><img src="./assets/img/icons/vervehighestres.png"/></a>
									</li>           
									<li>            
                                    <a href="#"><img src="./assets/img/icons/Visa_Logo.jpg"/></a>
									</li>           
									<li>            
                                    <a href="#"><img src="./assets/img/icons/Secure-Payment.jpg"/></a>
									</li>
								</ul>
							</div>
                
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { AddMoney } = state.accountReducer;
    const { user, appvariables } = state.loginReducer;
    //const { serverTime } = state.gameReducer;
    const {serverTime } = state.commonReducer;
    return { AddMoney, user, serverTime, appvariables };
}

const mapActionToProps = {
    Addmoney: accountActions.AddMoney,
    error: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Addmoney))