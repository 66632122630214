import React, { Component } from 'react'
import { connect } from 'react-redux';

import { gameActions } from '../../../action/gamesAction';
//import { loginActions } from '../../../action/loginAction';
//import UpcomingDraws from './UpcomingDraws';
import RecentResult from './RecentResult';

import Header from "../../Header/Header";
import { Link } from 'react-router-dom';
//import Footer from "../../Footer/Footer";
//import bannerList from "../../../banner.json";
import { lottoActions } from '../../../action/lottoAction';
import Countdown, { zeroPad } from 'react-countdown';
import BazookaNews from './bazooka-news'
import { withTranslation } from 'react-i18next';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			games: [],
			bannerList: [],
			currentAvailableDraws: [],
			coundownStart: 0
		};
		//props.GameFamily(this.props.user.mobileMo);
		let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	
		props.GameFamily(props.user.mobileNo, intlangflag);
		this.props.GameAvailableGames(props.user.mobileNo, 0, intlangflag);
	}

	componentDidMount() {
		//	window.documentReadyjQuery();
		window.myaccountacc();
		this.setjsondata();
		window.lstloadnews();
	};
	componentDidUpdate() {
		window.bannerslider(); //window.documentReadyjQueryFlex();				
		window.newsslider();
	};

	setjsondata = (myJson) => {
		fetch('assets/img/banner/banner.json')
			.then(response => response.json())
			.then(data =>
				this.setState({ bannerList: data.homeList })
			);
	};

	handleInputChange = (e, inx) => {
		var items = this.state.games
		var item = this.state.games[inx];
		let name = e.target.name;
		let value = e.target.value;
		item[name] = value;
		this.setState({
			games: items
		});
	};

	selectGameFamily = (inx) => {
		var item = this.state.games[inx];
		// item.ball = parseInt(item.ball);
		// item.minSelect = parseInt(item.minSelect);
		// item.maxSelect = parseInt(item.maxSelect);
		this.props.SelectGameFamily(item);
		//this.props.GameAvailableGames(this.props.user.mobileNo, item.id);
	};

	BindAvaildraws = () => {
		if (this.props.currentAvailableDraws && this.props.currentAvailableDraws.length > 0) {
			let ballLst = [];
			let intcnt = 0;

			for (let i = 0; i < this.props.currentAvailableDraws.length; i++) {
				let Imgidntfy = "";
				let btnclass = "";
				let gameidnfy = "";

				intcnt += 1;
				if (this.props.currentAvailableDraws[i].GameGroupId == 4) { 

					if (this.props.currentAvailableDraws[i].LotName.endsWith("Awoof Mach")) { gameidnfy = 6; }
					if (this.props.currentAvailableDraws[i].LotName.endsWith("Awoof")) { gameidnfy = 1; }		

					if (this.props.currentAvailableDraws[i].LotName.endsWith("Cash Mach")) { gameidnfy = 7; }
					if (this.props.currentAvailableDraws[i].LotName.endsWith("Cash")) {gameidnfy = 2; }

					if (this.props.currentAvailableDraws[i].LotName.endsWith("Billionaire Mach")) {gameidnfy = 3; }
					if (this.props.currentAvailableDraws[i].LotName.endsWith("Billionaire")) {gameidnfy = 8; }

					if (this.props.currentAvailableDraws[i].LotName.endsWith("Hammer Mach")) {gameidnfy = 9; }
					if (this.props.currentAvailableDraws[i].LotName.endsWith("Hammer")) { gameidnfy = 4;}

					if (this.props.currentAvailableDraws[i].LotName.endsWith("Jackpot Mach")) { gameidnfy = 10;}
					if (this.props.currentAvailableDraws[i].LotName.endsWith("Jackpot")) {gameidnfy = 5; }

					Imgidntfy = <img src={`./assets/img/games/game${gameidnfy}.png`} draggable="false" />
					
					ballLst.push(
						<li>
							<div class={`nextDraw borColor${gameidnfy}`}>
								{Imgidntfy}
								<h4 class={`txtColor${gameidnfy}`}>{this.props.currentAvailableDraws[i].LotName}</h4>
								<h5>{this.props.currentAvailableDraws[i].DrawDate + " " + this.props.currentAvailableDraws[i].DrawTime}</h5>
								<a onClick={() => { this.selectDraw(this.props.currentAvailableDraws[i]) }} key={`draw_${this.props.currentAvailableDraws[i].DrawID}`} className={`gamebtn${gameidnfy}`}>Play Now</a>
								<span>Draw Closes In </span>
								{/* <h6>00d : 21m : 12s</h6> */}
								{this.reCount(this.props.currentAvailableDraws[i])}
							</div>
						</li>
					);
				}
				else if (this.props.currentAvailableDraws[i].GameGroupId == 5) { 
					Imgidntfy = <img src={`./assets/img/ghana/ghanaBall${intcnt}.png`} draggable="false" />; btnclass = "btn2"; 
					ballLst.push(
						<li>
							<div class={`nextDraw borColor${intcnt}`}>
								{Imgidntfy}
								<h4 class={`txtColor${intcnt}`}>{this.props.currentAvailableDraws[i].LotName}</h4>
								<h5>{this.props.currentAvailableDraws[i].DrawDate + " " + this.props.currentAvailableDraws[i].DrawTime}</h5>
								<a onClick={() => { this.selectDraw(this.props.currentAvailableDraws[i]) }} key={`draw_${this.props.currentAvailableDraws[i].DrawID}`} className={`gamebtn${intcnt}`}>Play Now</a>
								<span>Draw Closes In </span>
								{/* <h6>00d : 21m : 12s</h6> */}
								{this.reCount(this.props.currentAvailableDraws[i])}
							</div>
						</li>
					);
				}
				if(intcnt == 10){intcnt = 0;}
			}

			return ballLst;
		}
	};

	selectDraw = (_draw) => {
		this.props.SetCurrentDraw_call(_draw);

		{
			this.props.games && this.props.games.map((z, indx) => {
				if (z.id == _draw.GameGroupId) {
					this.props.SelectGameFamily(z)
				}
			}
			)
		}
	};

	countdownCompleted = () => {
		//this.RefreshAfterGameClose();
		// document.location.reload();
		this.state.coundownStart = 0;
		//window.location.reload(false);
	};

	reCount = (rundraw) => {
		//return <span class="DropCnt" data-pid={this.state.currentDraw ? this.state.currentDraw.DrawID : ""} data-countdown={this.state.currentDraw ? this.state.currentDraw.DrawDateTime : ""}></span>;
		//return <span class="DropCnt" data-pid={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawID : ""} data-countdown={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : ""}></span>;		
		//return <span><Countdown now={Date.now} date={Date.now() + 100000} onTick={this.rendererTick()} /> </span>;

		if (rundraw == null || Object.keys(rundraw).length == 0) {

			return <h6>Game Closed</h6>;
		}
		else {
			this.state.coundownStart = 1;
			return <h6 key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime(rundraw)} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{zeroPad((days * 24) + hours)}h:{zeroPad(minutes)}m:{zeroPad(seconds)}s</div>) }} /> </h6>;
		}
	};

	getGameTime = (rundraw) => {
		let _date = rundraw ? rundraw.DrawDateTime : "";
		if (_date != undefined && _date != "") {
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
			//console.log("getGameTime : " + sTime);
			//console.log("DateNow : " + Date.now());
			return sTime;
		}
		else {
			return 0;
		}
	};

	static getDerivedStateFromProps(props, state) {
		return {
			games: props.games,
			currentAvailableDraws: props.currentAvailableDraws,
			ResentResult: props.ResentResult
		};
	};

	render() {
		const { t } = this.props;
		return (
			<div>
				<Header />
				<div className="row" id="home">
					<div class="banner">
						<div class="flexslider">
							<ul class="slides">
								{/* <li><img src="./assets/img/banner/banner8.jpg" /></li>
							<li><img src="./assets/img/banner/banner6.jpeg" /></li>
							<li><img src="./assets/img/banner/banner7.jpg" /></li> */}

								 {this.state.bannerList && this.state.bannerList.map((x, indx) =>
									// <li key={`banimg_${indx}`}>{x.linkUrl !=undefined && x.linkUrl != "" ? <Link to={x.linkUrl} ><img src={x.imagPath} /></Link>:<img src={x.imagPath} />}</li>							
									<li key={`banimg_${indx}`}><img src={x.imagPath} alt="Banner" />
										{/* {indx == 2 &&
											<div className="overlay">
												<div className="overlayCont">
													<h1>Everyone's a Winner!</h1>
													<p>Play Bazooka Lotto and stand a chance of winning any of the games if your number is selected in our daily draw.</p>
													<p>You can also earn by becoming an affiliate by recruiting other players and encouraging them to play.</p>
												</div>
											</div>
										} */}

									</li>
								)}
								
						{/* {this.props.RecentResultdts && this.props.RecentResultdts.length > 0 && this.props.RecentResultdts[1][0].Banner.split('|').map((x, idx) => 							
<li key={`banimg_${idx}`}><img src={x.split('~')[0]} /></li>
)} */}
							</ul>
						</div>
					</div>
				</div>
				<div className="row bg2">
					<div className="container">
						<div className="col-md-8 ">

							<section class="categories">
								<div class="cateCont">
									<div>
										<ul class="slides">

											{this.BindAvaildraws()}

											{/* <li>
							<div class="nextDraw">
								<img src="./assets/img/games/game1.png" draggable="false" />
								<h4>Cash Cash Mach</h4>
								<h5>Thu 14 Feb 2019</h5>
								<a href="#">Play Now</a>
								<span>Next draw:</span>
								<h6>00d : 21m : 12s</h6>
							</div>
						</li>
						<li>
							<div class="nextDraw">
								<img src="./assets/img/games/game2.png" draggable="false" />
								<h4>#1234</h4>
								<h5>Thu 14 Feb 2019</h5>
								<a class="btn2" href="#">Play Now</a>
								<span>Next draw:</span>
								<h6>00d : 21m : 12s</h6>
							</div>
						</li> */}

										</ul>
									</div>
								</div>
							</section>

							<RecentResult />
						</div>
						<BazookaNews />
					</div>
				</div>
			</div>
		)
	};
}

function mapStateToProps(state) {
	const { games, currentAvailableDraws } = state.gameReducer;
	const { serverTime } = state.commonReducer;
	const { RecentResultdts } = state.accountReducer;
	const { user } = state.loginReducer;
	return { games, currentAvailableDraws, user, serverTime, RecentResultdts };
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	SelectGameFamily: gameActions.SetGameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	SetCurrentDraw_call: lottoActions.SetCurrentDraw_call
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))