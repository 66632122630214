import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { alertReducer } from './alertReducer';
import { gameReducer } from './gameReducer';
import { lottoReducer } from './lottoReducer';
import { accountReducer } from './accountReducer';
import { quickWinReducer } from './quickWinReducer';
import {target10Reducer } from './target10Reducer';

import { commonReducer } from './commonReducer';
const rootReducer = combineReducers({
    loginReducer,
    alertReducer,
    gameReducer,
    lottoReducer,
    accountReducer,
    quickWinReducer,
    target10Reducer,
    commonReducer
});

export default rootReducer;