import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { gameActions } from '../../action/gamesAction';
import { withTranslation  } from 'react-i18next';

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = { _serverTimeDiff: 0 };
		props.GetServerTime();
	}
	componentDidMount() {
		setInterval(this.getServerTimeF, 1000);
	}
	
	getServerTimeF = () => {
		if (this.props.serverTime != undefined && this.props.serverTime != null) {
			//console.log("ServerTime : " + this.props.serverTime);			
			this.props.SetServerTime((this.props.serverTime+1000));
		}
		else {
			return 0;
		}
	};

	render() {
		const { serverTime,t}=this.props;		
		var sTime=new Date(serverTime);
		return (			
			<footer>
        <div className="container">
            <div className="footerView">			
                
					
						
			<div className="col-md-2 col-sm-2 col-xs-12">			
			<span>A Product of GAB LOTTO</span>
						<a className="lotterycrt" target="_blank" href="http://www.natlotregcom.gov.ng/index/pages/page/licensees/?&amp;nav=269"><img src="./assets/img/partners/Partners -1.jpg"/></a>
						</div>

					<div className="col-md-7  col-sm-10">
                    <ul className="footMenu">
						<li><Link to="/termsandcondition" target='_blank'>{t('lblTercon')}</Link></li>
						<li><Link to="/privacypolicy" onClick={() => {window.scrollTo({top: 0, left: 0});}}>{t('lblPripol')}</Link></li>
						<li><Link to="/Disclaimer" onClick={() => {window.scrollTo({top: 0, left: 0});}}>Disclaimer</Link></li>						
						<li><Link to="/faq" onClick={() => {window.scrollTo({top: 0, left: 0});}}>{t('lblFAQ')}</Link></li>						
                    </ul>
					<ul className="footMenu">
						<li><Link to="/NetworkRewards" onClick={() => {window.scrollTo({top: 0, left: 0});}}>Affiliate Network Rewards</Link></li>
						<li><Link to="/howtoplay" onClick={() => {window.scrollTo({top: 0, left: 0});}}>{t('lblhowitwork')}</Link></li>										
						<li><Link to="/ClassificationChat" onClick={() => {window.scrollTo({top: 0, left: 0});}}>Classification Chart</Link></li>
						<li><Link to="/contact" onClick={() => {window.scrollTo({top: 0, left: 0});}}>{t('lblContus')}</Link></li>
						</ul>
                    </div>
					<div className="col-md-3 col-sm-12 col-xs-12">
                    <ul className="soc-icons">
                        <li><a target="_blank" href="https://www.facebook.com/Bazookalotto"><i className="fa fa-facebook"></i></a></li>
                        <li><a target="_blank" href="https://twitter.com/Bazookalotto"><i className="fa fa-twitter"></i></a></li>
						<li><a href="https://www.instagram.com/Bazookalotto/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                        <li><a target="_blank" href="https://www.youtube.com/channel/UCLAJNa1LVuFLEzJC2PoOwZQ/videos"><i className="fa fa-youtube"></i></a></li>
						<li><span>18+</span></li>
                    </ul>
					<div className="appstore">                  
                    <div className="androidMbl">                                              
						<Link to="/mobileapp" target="_blank"><img src="./assets/img/Appstore.png"/></Link>												
                    </div>					
					<img src="./assets/img/partners/siteseal_gd_3_h_l_m.gif"/>
                </div>				
                </div>
				</div>
		</div> 
        <div className="copyrights">
            <span>All rights reserved Bazookalotto.com</span>
        </div>
    </footer>
		)
	};
}

function mapStateToProps(state) {
	//const { serverTime } = state.gameReducer;
	const {serverTime } = state.commonReducer;
	const { appvariables} = state.loginReducer;
	return { serverTime,appvariables };
}
const mapActionToProps = {
	SetServerTime: gameActions.SetServerTime,
	GetServerTime: gameActions.GetServerTime
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Footer))