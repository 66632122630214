import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../action/accountAction';
import { Link } from 'react-router-dom';
//import Header from "../Header/Header";
//import { loginActions } from '../../action/loginAction';
import { withTranslation } from 'react-i18next';

class TermsAndCondition extends Component {
    constructor(props) {
        super(props);

        this.state = {		
			appsts: 1
		}

        const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

		//if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}	
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

        this.state.appsts = intflsts;

        //let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.Gethowtoplay(langflag,2);
    }

    componentDidMount() {
        if(this.state.appsts == "1"){
        document.getElementById('intro').classList.add('activeBrdrLft');
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {            
            gethowtoplay: props.gethowtoplay != undefined ? props.gethowtoplay : {}
        }
    };

	 tet=()=> {
		//let ddd='<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p><h2>EXAMPLE:</h2>		<div className="contntPrizeTble"><div className="contntInrPrizeTble"></div></div>';
		let ddd=this.state.gethowtoplay;
		 let dd= <div className="privacyRhtCont" dangerouslySetInnerHTML={{__html:ddd }}></div>			
		  return dd;
	}

    handleDaysChange= (e) => {
        document.getElementById('intro').classList.remove('activeBrdrLft');
        document.getElementById('intro1').classList.remove('activeBrdrLft');
        document.getElementById('intro2').classList.remove('activeBrdrLft');
        document.getElementById('intro3').classList.remove('activeBrdrLft');
        document.getElementById('intro4').classList.remove('activeBrdrLft');
        document.getElementById('intro5').classList.remove('activeBrdrLft');
        document.getElementById('intro6').classList.remove('activeBrdrLft');
        document.getElementById('intro7').classList.remove('activeBrdrLft');
        document.getElementById('intro8').classList.remove('activeBrdrLft');
        document.getElementById('intro9').classList.remove('activeBrdrLft');
        document.getElementById('intro10').classList.remove('activeBrdrLft');
        document.getElementById('intro11').classList.remove('activeBrdrLft');
        document.getElementById('intro12').classList.remove('activeBrdrLft');
        document.getElementById('intro13').classList.remove('activeBrdrLft');
        document.getElementById('intro14').classList.remove('activeBrdrLft');
        document.getElementById('intro15').classList.remove('activeBrdrLft');
        document.getElementById('intro16').classList.remove('activeBrdrLft');
        document.getElementById('intro17').classList.remove('activeBrdrLft');
        document.getElementById('intro18').classList.remove('activeBrdrLft');
        document.getElementById('intro19').classList.remove('activeBrdrLft');
        document.getElementById('intro20').classList.remove('activeBrdrLft');

        e.target.classList.add('activeBrdrLft');        
   };

    render() {
        const { i18n } = this.props;
        const { appsts } = this.state;
        return (
            <div className="mainContainer">
		
            <div className="row">
                <div className="container forPadd1">
                    <div className="privacyCont">                    
                         <div className="privacyLftCont">
                            <h1>Terms of Use</h1>
                            {appsts	== 1 &&
                            <ul className="contentFlowLinks">
                                <li><a id='intro' href="#intro" onClick={this.handleDaysChange}>Introduction</a></li>
                                <li><a id='intro1' href="#user_elgibilty" onClick={this.handleDaysChange}>User elgibility</a></li>
                                <li><a id='intro2' href="#usr_response" onClick={this.handleDaysChange}>Users responsibilities</a></li>
                                <li><a id='intro3' href="#game_details" onClick={this.handleDaysChange}>Game details</a></li>
                                <li><a id='intro4' href="#game_play" onClick={this.handleDaysChange}>Game play</a></li>
                                <li><a id='intro5' href="#drw_result" onClick={this.handleDaysChange}>Draw results</a></li>
                                <li><a id='intro6' href="#prize redemption" onClick={this.handleDaysChange}>Prize redemption</a></li>
                                <li><a id='intro7' href="#privcy" onClick={this.handleDaysChange}>Privacy</a></li>
                                <li><a id='intro8' href="#copyright" onClick={this.handleDaysChange}>Copyright</a></li>
                                <li><a id='intro9' href="#trademarks" onClick={this.handleDaysChange}>Trademarks</a></li>
                                <li><a id='intro10' href="#third_party" onClick={this.handleDaysChange}>Third-party charges</a></li>
                                <li><a id='intro11' href="#email_newslettr" onClick={this.handleDaysChange}>Emails and newsletters</a></li>
                                <li><a id='intro12' href="#thrd_party_lks" onClick={this.handleDaysChange}>Third party links</a></li>
                                <li><a id='intro13' href="#email_sms" onClick={this.handleDaysChange}>E-mails/sms</a></li>
                                <li><a id='intro14' href="#feedback" onClick={this.handleDaysChange}>Feedback</a></li>
                                <li><a id='intro15' href="#indemnity" onClick={this.handleDaysChange}>Indemnity</a></li>
                                <li><a id='intro16' href="#disclimar_warranty" onClick={this.handleDaysChange}>Disclaimer or warranties and limitation of liability</a></li>
                                <li><a id='intro17' href="#gover_law" onClick={this.handleDaysChange}>Governing law</a></li>
                                <li><a id='intro18' href="#disput_resol" onClick={this.handleDaysChange}>Dispute resolution</a></li>
                                <li><a id='intro19' href="#severability" onClick={this.handleDaysChange}>Severability</a></li>
                                <li><a id='intro20' href="#miscellaneous" onClick={this.handleDaysChange}>Miscellaneous</a></li>
                            </ul>
    }
                        </div>
                        {this.tet()}
                        {/*<div className="privacyRhtCont">
                            <h2 id="intro">INTRODUCTION</h2>
                            <p>This terms of use refers to the terms and conditions (hereinafter referred to as “the agreement”) relevant to your right to use Bazooka online lotto website/Mobile Application(hereinafter referred to as “the Mobile App”). It is an agreement between you as the user(s) of this mobile app (hereinafter referred to as “you”, “your” or “user”) and Sagenic Technologies (hereinafter referred to as “we”, “us”, “our” or “Sagenic Tech.”).
                            Please note that your continued use of this mobile app/website is subject to this terms of use as well as all other rules of the mobile app/website that may be issued by Sagenic Tech.
                            Please refer to these Terms and Conditions regularly. Sagenic Tech. lotto may at any time and without prior notice change, modify, add, or remove portions of these Terms of Use by updating this posting. Your continued use of this mobile app/website following posting of changes to these Terms of use signifies that you accept these changes.
                            By accessing and using the mobile Application and/ web site, you acknowledge that you have read and agree to these Terms and Conditions. If you do not agree to or wish to be bound by these Terms and Conditions, you are requested to immediately exit.</p>
                            
                            
                            <h2 id="user_elgibilty">USER ELGIBILITY</h2>
                            <p>The use of this mobile app/Website is available only to persons who are above the age of 18. No one under the age of 18 is allowed to create an account or use the services. Sagenic Tech. reserves the right to deny you access to the mobile app/website if it is brought to our notice or if it is discovered that you are under the age of 18 years.</p>
                            <ul className="marleft">
                                <li><p>1.By using this mobile app/website, you state that :</p></li>
                                <li><p>2.You can form legally binding contracts under the applicable law;</p></li>
                                <li><p>3.You will comply with these terms of use and all applicable laws, rules and regulations.</p></li>
                                
                            </ul>
                            <p>If you intend to use the services on behalf of a business or some other entity, you state that you are authorised to grant all licenses set forth in these terms of use and to agree to these terms on behalf of the business or entity.</p>
                            
                            <h2 id="usr_response">USERS RESPONSIBILITIES</h2>
                            <p>By using this mobile app/website, you agree that :</p>
                            <ul className="marleft">
                                <li><p>1.You will use this mobile app/website in accordance with the applicable law and terms of this agreement;</p></li>
                                <li><p>2.You will provide accurate and correct personal information;</p></li>
                                <li><p>3.You will be solely responsible for any activity that occurs in your Sagenic Tech. account;</p></li>
                                <li><p>4.You will not create more than one account for yourself, neither will you create another account if your account has been previously disabled by us, unless you have our written permission to do so;</p></li>
                                <li><p>5. You will not share your account details with others;</p></li>
                                <li><p>6. You will not use the mobile app for any purpose that is illegal or prohibited in these Terms;</p></li>
                                <li><p>7. You will not upload viruses or other malicious code or otherwise compromise the security of the mobile app;</p></li>
                                <li><p>8. You will not use any robot or other automated means or interface to access the mobile app or extract other user’s information;</p></li>
                                <li><p>9. You will not use the mobile app in a way that could interfere with, negatively affect, or inhibit other users from fully enjoying the mobile app, or that could damage, disable or impair the functioning of the mobile app;</p></li>
                                <li><p>10.You will not encourage or promote any activity that violates these Terms.</p></li>
                            </ul>
                            
                            
                            <h2 id="game_details">GAME DETAILS</h2>
                            <p>5/90 is an online lotto game which allows players to select at least one out of ninety balls numbered 1-90. A draw is held at the end of each day except on Sundays to randomly select 5 out of the 90 balls. If the numbers on the 5 selected balls match any of the numbers selected by the player at the time of play, he is a winner. The daily draw game is Lotto. The variations of games to play each day are below</p>
                            <ul className="marleft">
                                <li><p>1.Direct-2 :- Any two numbers out of the 5 winning numbers to be drawn. (e.g., If a player selects 37 and 17, he's a winner if 37 and 17 are among the 5 numbers drawn)</p></li>
                                <li><p>2.Direct-3 :- Any three numbers out of the 5 winning numbers to be drawn. (e.g., If a player selects 21, 48 and 83, he's a winner if 21, 48 and 83 are among the 5 numbers drawn)</p></li>
                                <li><p>3. Direct-4 :- Any 4 numbers out of the 5 winning numbers to be drawn. (e.g., If a player selects 21, 48, 83 and 45, he's a winner if they are among the 5 numbers drawn.)</p></li>
                                <li><p>4.Direct-5 :- All 5 numbers chosen must be winning numbers. (e.g., If a player selects any 5 numbers from 1 to 90, and all 5 numbers are drawn, then he is winner.</p></li>
                                <li><p>5.  Perm-2 :- This is basically selecting more than 2 Numbers and combining them in sets of 2. If a player selects numbers 10-20-30, Perm2 will give combinations of (i) 10-20, (ii) 10-30 and (iii) 20-30. Here, the winning pattern is same as direct-2.</p></li>
                                <li><p>6. Perm-3 :- This is basically selecting more than 3 Numbers and combining them in sets of 3. If a player selects numbers 10-20-30-40, Perm3 will give combinations of (i) 10-20-30, (ii) 10-20-40, (iii) 10-30-40 (iv) 20-30-40. Here, the winning pattern is same as direct-3.</p></li>
                                <li><p>7. Banker-1 against all : - This is selecting one number and pairing it with the rest of 89 numbers. (e.g., if player selects 45 against all, Means 45 his banker.) This banker must be one of the 5 winning numbers.</p></li>
                                
                            </ul>
                            
                            <h2 id="game_play">GAME PLAY</h2>
                            <p>Players of the 5/90 game must ensure that the 5/90 game is selected and played before the draw of a particular game The player shall ensure that the numbers played, the date of draw and ticket number of the e-ticket generated are visible on the said e-ticket on the 5/90 mobile platform.</p>
                            <h2 id="drw_result">DRAW RESULTS</h2>
                            <p>The draw of 5/90 on mobile shall have regard to the provisions relating to regulation (21) of the Lottery Regulation 2008 (LI 1948)</p>
                            <h2 id="prize redemption">PRIZE REDEMPTION</h2>
                            <p>A player who wins shall personally present an online winning ticket (5/90) at the place designated by the Sagenic Tech. lotto or its accredited partner bank or agent. A prize shall be paid during the normal business hours of the National Lottery Regulatory or its accredited partner banks, except that an online system must be operational and be able to validate any claim. A prize shall be claimed within seven working days after the date of draw. Winning amounts up to N 426 shall be uploaded to the player wallet after the draw. The amount may be withdrawn via any of the available options. Amounts exceeding that limit can be redeemed at any Sagenic Tech. lotto affiliated Bank after the draw If the partner bank cannot validate the claim, the player who has won may personally present the disputed ticket to the offices of the authority. If a ticket is determined to be a non-winning ticket, the claim shall be denied, and the claimant shall be promptly notified. Where the claim is presented to the Sagenic Tech. lotto, the winner shall complete a claim form, provided by the Director-general, and personally submit it with the winning ticket to the Authority. The winner, by submitting the claim, agrees to the discharge of the relevant duties the republic, the Sagenic Tech. lotto and its officers that are not liable after payment for the prize.</p>
                            
                            <h2 id="privcy">PRIVACY</h2>
                            <p>Personal information supplied by you during the use of the mobile app/website is governed by our privacy policy. You can learn how we handle your information when you use the mobile app/website.</p>
                            
                            <h2 id="copyright">COPYRIGHT</h2>
                            <p>All content included on this mobile app/website is the property of Sagenic Tech. or its affiliates and is protected by copyright law. You may not produce, copy, archive, download, syndicate, broadcast, perform, display, make available or otherwise use any portion of this mobile app/website or the content on the mobile app/website except as set forth in these Terms of use or with the written consent of Sagenic Tech..</p>
                            <p>If Sagenic Tech. becomes aware that one of its users infringed copyrights, reasonable steps would be taken against such user which may involve termination of the user’s account.
                            If you as a user suspect or believe that anything on the mobile app/website infringes a copyright that you own or control, you may report such infringement by filing a notice to us at :</p>
                            
                            <p>Gab Lotto Ltd,</p>
                            <p>4B Olatunda Street,</p>
                            <p>Ilupeju,</p>
                            <p>Lagos,</p>
                            <p>Nigeria.</p>
        
                            <h2 id="trademarks">TRADEMARKS</h2>
                            <p>The name "Sagenic Tech. lotto," and all other associated trademarks and logos are registered trademarks or service marks of Sagenic Tech. and are protected under applicable copyright, trademark and other proprietary rights laws and may not be used without the prior written permission of the Sagenic Tech. lotto. Sagenic Tech. lotto does not permit the use of its name, graphics, or logos in advertising, as an endorsement for any product or service, or for any other purpose, commercial or otherwise, without Sagenic Tech. lotto's prior written approval.
                            All other trademarks not owned by Sagenic Tech. that appear on the website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Sagenic Tech.</p>
        
                            <h2 id="third_party">THIRD-PARTY CHARGES</h2>
                            <ul className="marleft">
                                <li><p>* Data Charges</p></li>
                                <li><p>Data charges from internet service providers may apply from using the mobile app. Users agree to bear the cost of data charges including text-messaging that may be incurred from using the mobile app.</p></li>
                                <li><p>Transaction Charges</p></li>
                                <li><p>When depositing or withdrawing money from the Sagenic Tech. lotto website Player wallet, players may be charged per transaction based on the payment channel used. A player shall bear the cost of transaction from these payment channels.</p></li>
                            </ul>
        
                            <h2 id="email_newslettr">EMAILS AND NEWSLETTERS</h2>
                            <p>Sagenic Tech. lotto may provide email and newsletter services to notify interested players about winning lottery numbers and jackpot amounts and other such information. Individuals who represent that they are at least 18 years of age or older may submit a request to receive emails and newsletters by providing their name, email address, and other information to Sagenic Tech. lotto in the players' registration area. It is the responsibility of the individuals submitting such requests to verify the accuracy of their email address. Sagenic Tech. lotto does not warrant the accuracy, completeness, or reliability of the information in the email or newsletter and disclaims all liability that results from the use any such information. Every reasonable effort is made to ensure that the information in the email and newsletter is accurate. However, in the event of an error, the winning numbers and prize amounts in the official records of Sagenic Tech. lotto shall control.</p>
        
                            <h2 id="thrd_party_lks">THIRD PARTY LINKS</h2>
                            <p>Sagenic Tech. lotto may, from time to time, provide links to other outside web sites. Sagenic Tech. lotto is not responsible for such linked web sites or the content of any of the linked web sites. Sagenic Tech. lotto provides these links as an additional resource for its web site users and makes no representations regarding the content of any linked web site or any companies that own, control, or manage the linked web sites. Consequently, Sagenic Tech. lotto is not responsible for the accuracy, relevancy, copyright compliance, legality or decency of material contained in, programs used by, or services or products offered by, web sites linked to from this web site.</p>
                            
                            <h2 id="email_sms">E-MAILS/SMS</h2>
                            <p>Sagenic Tech. lotto may provide email and SMS services to notify users on all transactions performed on the Sagenic Tech. lotto website. In addition, text messages in the form of SMS will come to promote products on the application/website.</p>
                            
                            <h2 id="feedback">FEEDBACK</h2>
                            <p>Any comments or materials you send or transmit, including, without limitation, via electronic mail, or standard mail, to Sagenic Tech. lotto Mobile regarding any content or information found on the web site/application, including, without limitation, data, questions, comments or suggestions (collectively "Feedback"), will not be treated as confidential, will become the property of Sagenic Tech. lotto Mobile and may be subject to disclosure under certain laws, Sagenic Tech. lotto Mobile has no obligation of any kind with respect to Feedback and will be free to reproduce, use, disclose, exhibit, display, transform, distribute, and create derivative works from Feedback.</p>
                            
                            <h2 id="indemnity">INDEMNITY</h2>
                            <p>You agree, to the extent permitted under applicable law, to indemnify, defend, and hold harmless Sagenic Tech., our directors, officers, employees, and affiliates from and against all complaints, charges, claims, damages, losses, costs, liabilities, and expenses (including legal fees) due to, arising out of, or relating in any way to your access or use of the mobile app.</p>
                            
                            <h2 id="disclimar_warranty">DISCLAIMER OR WARRANTIES AND LIMITATION OF LIABILITY</h2>
                            <p>THE SERVICES PROVIDED BY SAGENIC TECH. ON THE MOBILE APP/WEBSITE ARE ON AN "AS IS" AND "AS AVAILABLE" BASIS TO THE FULLEST EXTENT PERMITTED BY LAW. SAGENIC TECH. MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE SAGENIC TECH. ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT THE SERVICES WILL ALWAYS BE SECURE, ERROR-FREE, OR TIMELY. WE DO NOT REPRESENT OR WARRANTY THAT THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS OR THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE TIMELY ACCURATE. YOU EXPRESSLY AGREE THAT YOUR USE OF THE MOBILE APP IS AT YOUR SOLE RISK.
                            TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE BY LAW, SAGENIC TECH. AND OUR AFFILIATES, SHAREHOLDERS, EMPLOYEES, LICENSORS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY CONSEQUENTIAL, SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE MOBILE APP; OR THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON OR THROUGH THE SERVICES; OR UNAUTHORISED ACCESS, USE, OR ALTERATION OF YOUR CONTENT UNLESS OTHERWISE SPECIFIED IN WRITING.</p>
        
                            <h2 id="gover_law">GOVERNING LAW</h2>
                            <p>This Agreement will be governed by and construed under the laws of the Federal Republic of Nigeria. The parties agree that jurisdiction shall be in the courts of the Federal Republic of Nigeria.</p>
                            
                            <h2 id="disput_resol">DISPUTE RESOLUTION</h2>
                            <p>In the event of any dispute in connection with this Agreement, you and Sagenic Tech agree that such dispute will be resolved by arbitration.</p>
                            
                            <h2 id="severability">SEVERABILITY</h2>
                            <p>If any provision of this Agreement shall be held by any court to be illegal, void, or unenforceable, such provision shall be of no force and effect, but the unenforceability of such provision will not affect or impair the enforceability of any other provisions of this Agreement.</p>
                            
                            <h2 id="miscellaneous">MISCELLANEOUS</h2>
                            <ul className="marleft">
                                <li><p>These Terms make up the entire agreement between you and Sagenic Tech. and supersede any prior agreement</p></li>
                                <li><p>If we do not enforce a provision in these terms, it will not be considered a waiver.</p></li>
                                <li><p>You may not transfer any of your rights or obligations under these Terms without our consent.</p></li>
                            </ul>
                        </div>	 */}
                    </div>		
                </div>
            </div>
        </div>
        )
    };
}

function mapStateToProps(state) {
    const { gethowtoplay } = state.accountReducer;    
    return { gethowtoplay };
}
const mapActionToProps = {
    Gethowtoplay: accountActions.Gethowtoplay
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(TermsAndCondition))