import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';

//function BazookaNews() {

class BazookaNews extends Component {
    constructor(props) {
        super(props);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            ResentResult: props.ResentResult
        };
    }        

    render() {
		const { t } = this.props;
  return (  
    
    <div className="col-md-4">
        <div className="bazzokNews forPadd">
        <h3>Bazooka News</h3>
             <div className="myAccount">
                <div className="accTabs">
                    <nav>
                        <ul id="accTabLi">
                            <li className="Active"><a className="tabBg0" href="#cTab0" data="#cTab0">Latest News</a></li>
                            <li><a className="tabBg1" href="#cTab1" data="#cTab1">Top News</a></li>
                        </ul>
                    </nav>
                    <div className="accTabsRht">
                        <section id="cTab0" className="tabCont" style={{display: "block"}}>				
                            <div className="accTabsRhtCont">
                                <ul>
                                {this.props.RecentResultdts && this.props.RecentResultdts.length > 0 && this.props.RecentResultdts[1].map((x, idx) =>
                                    <div>
                                       {x.LatestNews.split('|').map((z, inx) => 
                                       <li>                                                                              
                                            <p>{z.split('~')[0]}</p>                                                                                        
                                            <small>{z.split('~')[1]}</small>                                            
                                        </li>
                                        )}
                                    </div>
                                    )}
                                </ul>
                            </div>
                        </section>
                        <section id="cTab1" className="tabCont">				
                            <div className="accTabsRhtCont">
                                <ul>
                                {this.props.RecentResultdts && this.props.RecentResultdts.length > 0 && this.props.RecentResultdts[1].map((x, idx) =>
                                    <div>
                                       {x.TopNews.split('|').map((z, inx) => 
                                       <li>                                                                              
                                            <p>{z.split('~')[0]}</p>                                                                                        
                                            <small>{z.split('~')[1]}</small>                                            
                                        </li>
                                        )}
                                    </div>
                                    )}
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
		</div>
    </div>
    
  );
}
}

function mapStateToProps(state) {
    const { RecentResultdts } = state.accountReducer;
    return { RecentResultdts };
}

const mapActionToProps = {    
}

//export default BazookaNews
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(BazookaNews))