import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";

import Header from "../../Header/Header";
import { withTranslation } from 'react-i18next';
class ResultReport extends Component {

    constructor(props) {
        super(props);
        this.setState({
            reportID: 4,
            DaysSelectStaus: 0,
        });        
    }    

    getRecord = async (flg) => { 
        let getIPad = "";
        let { DaysSelectStaus } = this.state;
        if (flg != 1) {
            this.state.startDate = document.getElementById("date-time1").value;
            this.state.endDate = document.getElementById("date-time2").value;
        }

        if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
            getIPad = this.props.ClntIPAddr;
        }
        else{
            getIPad = await commonClass.GetIPAddress();					
        }

        let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

        let _flog = commonClass.checkRptDateFilter(this.state.startDate, this.state.endDate)
        if (_flog)
            this.props.GetResultReport(this.state.startDate, this.state.endDate, this.state.reportID, intlangflag, this.state.DaysSelectStaus, getIPad);
    };

    handleDaysChange= (e) => {
        let _Daysvalue = 0;

        _Daysvalue = e.target.value;

        this.setState({
            DaysSelectStaus: _Daysvalue,
        });
        this.state.DaysSelectStaus = _Daysvalue;       
        
        document.getElementById('dfjactiv').childNodes.forEach(e => e.classList.remove('resltBtnActv'))

        e.target.classList.add('resltBtnActv');
        this.getRecord();
    };

   handleddChange = (event) => {
        let _ddlgaegrpvalue = 0;
        _ddlgaegrpvalue = event.target.value;

        this.setState({
            reportID: _ddlgaegrpvalue,
        });
        this.state.reportID = _ddlgaegrpvalue;
    };

    handleChange = (e) => {
        let _id = 0;
        switch (e) {
            case "r3":
                _id = 3;
                break;
            case "r4":
                _id = 4;
                break;
            case "r5":
                _id = 5;
                break;
                case "r6":
                    _id = 3;
                    break;
        }
        this.setState({
            reportID: _id,
        });
        this.state.reportID = _id;
        if (this.state.reportID == _id) {
            this.getRecord();
        }
    };

    CurDateFilter() {
        this.state.DaysSelectStaus = 0;
        document.getElementById('dfjactiv').childNodes.forEach(e => e.classList.remove('resltBtnActv'));
        this.getRecord();
    }

    reSetFilter() {
        document.getElementById('dfjactiv').childNodes.forEach(e => e.classList.remove('resltBtnActv'));
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss,
            reportID: 4,
            DaysSelectStaus: 0
        });
        this.state.reportID =4;
        this.state.DaysSelectStaus = 0;
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            ResultReport: props.ResultReport != undefined ? props.ResultReport : [],
        }
    };

    componentDidMount() {
        window.timecalender();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss,
            reportID: 4,
            DaysSelectStaus:0
        });
        this.state.reportID = 4;
        this.state.DaysSelectStaus = 0;
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };    

    render() {
        const { t } = this.props;        
        return (
            <div>
                <Header /> 
                <div className="mainContainer">
		
		<div className="row">
			<div className="container"></div>               
                <div className="dashRht">
                <div class="detailsHead"><h2>{t('lblResults')}</h2></div>
                    {/* <div class="accHeadCont">
                        {/*<input id="r1" checked={this.state.reportID == 4} onChange={(e) => { this.handleChange("r4") }} type="radio" name="r4" class="radio1" /><label class="" for="r1">Jackpot Lotto</label>
                        <input id="r2" checked={this.state.reportID == 5} onChange={(e) => { this.handleChange("r5") }} type="radio" name="r5" class="radio2" /><label class="" for="r2">Quick Lotto</label>
        <input id="r6" checked={this.state.reportID == 3} onChange={(e) => { this.handleChange("r6") }} type="radio" name="r3" class="radio2" /><label class="" for="r2">Quick 10</label>-->

        <input id="r1" checked={this.state.reportID == 4} onChange={(e) => { this.handleChange("r4") }} type="radio" name="r4" class="radio1" /><label class="" for="r1">Indoor Game 5/90</label>
                    </div> */}
                    <div class="Winningselect bg1">
								<div class="winningnoEnter">
									<div class="winningnoArea">
										<span>Game Group</span>
										<select onChange={this.handleddChange}>
											<option value="4">Indoor Games 5/90</option>
                                            <option value="5">Ghana Game 5/90</option>
										</select>
									</div>
								</div>
								</div>

                    <div class="HstryDetls">
                        <ul>
                            <li class="dateCont">
                                <div class="HstryDetlsCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate result" type="text" value={this.state.startDate} id="date-time1" name="date-time1" placeholder="DD/MM/YYYY" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="HstryDetlsCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="startDate result" type="text" value={this.state.endDate} id="date-time2" name="date-time2" placeholder="DD/MM/YYYY" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="btn1" onClick={() => { this.CurDateFilter() }}>{t('lblcustom')}</a>
                                <a href="#" class="btn1" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>

                    <div className="lottryRslt">
								<ul>
									<li id='dfjactiv' className="BtnCont">                                        
										<button className="resltBtn" value={1} onClick={this.handleDaysChange}>TODAY</button>
										<button className="resltBtn" value={2} onClick={this.handleDaysChange}>YESTERDAY</button>
										<button className="resltBtn" value={7} onClick={this.handleDaysChange}>7 DAYS</button>
										<button className="resltBtn" value={14} onClick={this.handleDaysChange}>14 DAYS</button>
										<button className="resltBtn" value={90} onClick={this.handleDaysChange}>90 DAYS</button>
									</li>
								</ul>
					</div>

                    <div className="DateTime">
								<span>WINNING NUMBERS</span>
							</div>                    
                        <div class="ReprtPAge">
                        <div className="booking scrollbar">
                            <table>
                                <tr>
                                    <th className="Col20">{t('lblgamename')}</th>                                    
                                    <th className="Col20">{t('lbldrawno')}</th>
                                    <th className="Col20">{t('lblResultNo')}</th>
                                    <th className="Col20">{t('lblMachineNo')}</th>                                    
                                </tr>
                                {this.state.ResultReport && this.state.ResultReport.length > 0 && this.state.ResultReport[0].SubGameName && this.state.ResultReport.map((x, idx) =>
                                    <tr>
                                        <td className="Col20">{x.SubGameName} {x.GameDateTime}</td>                                        
                                        <td className="Col20">{x.GameID}</td>
                                        <td className="Col20">
                                        <li className="rePortBallsMthr">
                                            {x.Result.split(',').map((z, inx) =>
                                                <span className="rePortBalls">{z}</span>
                                            )}
                                            </li>
                                        </td>
                                        <td className="Col20">
                                        <li className="rePortBallsMthr">
                                            {x.MachineNo.split(',').map((m, inx) =>
                                                <span className="rePortBalls">{m}</span>
                                            )}
                                        </li>
                                        </td>
                                    </tr>
                                )}
                                {(this.state.ResultReport == null || this.state.ResultReport.length <= 0 || (this.state.ResultReport.length > 0 && this.state.ResultReport[0].SubGameName == undefined)) &&
                                    <tr>
                                        <td colspan="4">{t('lblnorecordfound')}</td>
                                    </tr>
                                }
                            </table>
                            </div>
                        </div>
                    
                    </div>
                    </div>
                    </div>

                </div>                            
        )
    }
}

function mapStateToProps(state) {
    const { ResultReport } = state.accountReducer;
    // const { user } = state.loginReducer;
    return { ResultReport };
}

const mapActionToProps = {
    GetResultReport: accountActions.GetResultReport
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ResultReport))
