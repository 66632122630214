import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withTranslation } from 'react-i18next';
import { gameActions } from '../../action/gamesAction';
class HowToPlay extends Component {
    constructor(props) {
        super(props);
        this.state = { cTab: 1, appsts: 1 }

		const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

		//if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;
    }

    componentDidMount() { 		
		window.myaccountacc();
        //window.scrollTo(0, 0);
    };	

    SelectGameFamily = (inx) => {
        var item = this.props.games.filter(x => x.id == inx);
        // item.ball = parseInt(item.ball);
        // item.minSelect = parseInt(item.minSelect);
        // item.maxSelect = parseInt(item.maxSelect);
        if (item && item.length > 0) {
            this.props.SelectGameFamily(item[0]);
            //this.props.GameAvailableGames(this.props.user.mobileNo, item.id);
        }
    };
    static getDerivedStateFromProps(props, state) {
        return {
            gameFamily: props.games.filter(a => a.id == 4),			
        };
    };

    render() {
        const { t, i18n } = this.props;
		const { appsts } = this.state;
        return (
			<div>
            <div className="mainContainer">
		{appsts	== 1 && <Header /> }
		{appsts	== 1 && 
		<div className="row">
			<div className="container forPadd1">
				<div className="privacyCont">
					<div className="privacyLftCont">
						<ul className="contentFlowLinks" id="accTabLi">
							<li><a className="activeBrdrLft" href="cTab17" data="#cTab17">How to play</a></li>
							<li><a href="cTab18" data="#cTab18">Prize structure</a></li>
							<li><a href="cTab19" data="#cTab19">Affiliate Network Rewards</a></li>
						</ul>	
					</div>
					<div className="privacyRhtCont">
						<section id="cTab17" className="tabCont" style={{display: "block"}}>
						<h2 id="bazoko_lotto">THE BAZOOKA LOTTO MANUAL</h2>
							<p>The following is intended as a guide for both new players and player coaches for the Bazooka lotto website. Please study the manual carefully and master all aspects of the game. Each reader is expected to both benefit from the knowledge earned as well as being able to pass on the knowledge to other players in a detailed and easy to follow manner.</p>
							
						<h2 id="player_log">PLAYER LOGIN</h2>
							<p>The first screen that appears on the application will be the Login Screen. Already registered users can enter their registered mobile number and their chosen password to login into the application.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/login.png"/>
							</div>
							<p>After entering the mobile number and password, Press the "LOGIN" button to log into the application.</p>
							
						<h2 id="newuser_signup">CREATE YOUR ACCOUNT</h2>
							<p>New Users can Register with Bazooka Lotto using the "REGISTER" link available on the "Home" Screen shown above. Once the new player presses "REGISTER", you will be taken to the screen.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/signup.png"/>
							</div>
							<p>User must enter his mobile number, First Name, Last Name, Password, Confirm Password, Email address and Referral Code (Mobile Number) and press the "REGISTER" button. The user account will be created on the Bazooka server and confirmation pop up upon successful validation.</p>
							<p>Next, After Success Pop up message automatically redirect to Login screen page and enter your mobile number and password and start enjoying the games.</p>
							
						<h2 id="forgot_passwrd">FORGOT PASSWORD</h2>
							<p>Press the Forgot Password on the Login Screen. Enter you registered Mobile number with starting 0 and retype the Same Mobile Number in "Confirm Mobile No" for Confirmation. Click submits to forgot password. system will send the new OTP to you registered E-mail Address & SMS to your registered Mobile number.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/forgot-pass.png"/>
							</div>
							
						<h2 id="home">HOME</h2>
							<p>Upon successful login, the application displays the Home Screen. The Home Screen displays the menu options and sub options under each menu when selected.</p>
							<p>The lists of menus available in the home screen are:</p>
							<h2 style={{fontstyle:"italic",color:"#666464"}}>Home / Play / Winning Nos / My Network / How To Play / Rewards and Another Menu show in left side of Screen menu as Check Balance / Fund Account / Cash Out / Ticket Play History / Commission Details / Account Summary / Change Password / Profile / Logout</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/home.png"/>								
							</div>														
							<p>By default, the "Home" menu that shows Username, Latest Winning Nos in Scrolling, Next Draw Close Time, News. Other Menus will be shown at the top next to "HOME" and at the left side of screen and respective submenus will be displayed after clicking on those Menus.</p>
							<div className="text-ImageCont">								
								<img src="./assets/img/how_to_play_images/Home2.png"/>
							</div>
							
						<h2 id="play">PLAY</h2>
							<p>As noted before the "PLAY" screen has a list of games available for playing, with Game Name, Draw Date and Time. User can select from the list which of the games the user wishes to play.
							Example of available draws are shown above, and they are Cash Cash; Hammer; Awoof; Jackpot; and Billionaire / Ghana Games.
							Once a Game has been selected the "GAME LIST" and "Stake Amount" and "Option" and "class" and Betting Balls screen below in superimposed on the Games page</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/lotto.png"/>
							</div>
							<p>On selecting a game, the application will Clear already Selected Betting Balls Selection (Lucky Number, Banker, Stand, Against) and bet options available for play. Currently we have four general betting options as listed below:</p>
							<ul className="marleft">
								<li><p>1.Direct</p></li>
								<li><p>2.Permutation</p></li>
								<li><p>3.Banker</p></li>
								<li><p>4.Against options.</p></li>
							</ul>
							<p>Each option will be explained in the next section, "GAMES EXPLAINED"</p>
						
						<h2 id="game_explan">GAMES EXPLAINED:</h2>
							<p>It is important to note that there are general principles that guide the classNamees and rewards in all the types of sub options of games. 5/90 is a fixed odds betting game. The payout is derived from a multiplication of the amount staked. The following are the general payouts based on the odds in each className:</p>
							<ul className="marleft">
								{/* <li><p> • className1 (1 number) = 40 x staked amount</p></li> */}
								<li><p> • className2 (2 numbers) = 240 x staked amount</p></li>
								<li><p> • className3 (3 numbers) = 2,100 x staked amount</p></li>
								<li><p> • className4 (4 numbers) = 6,000 x staked amount</p></li>
								<li><p> • className5 (5 numbers) = 50,000 x staked amount</p></li>
							</ul>
							<p>The classNamees are determined by the amount of numbers picked per game. For example, in className 2 the player picks his two lucky numbers. When the numbers picked per className are drawn in the draw for that specific game, the player wins 240x the amount they’ve staked. i.e., if the player has staked N1,000 and his numbers are drawn together, then he wins N240,000.</p>
						<h2 id="direct">DIRECT</h2>
							<p>The Direct option also referred to as Simple, has four classNamees S2 / S3 / S4 / S5. For S2, the user must select 2 numbers, for S3 the user must select 3 numbers, for S4 the user must select 4 numbers, for S5 the user must select 5 numbers. After selecting the numbers, the user should Select the stake amount the user wishes to bet on the selected numbers. The prize payout depends on the option selected. For example, for S2 if the number the user has selected matches with the draw result, the user will get a payout of 240 times the stake amount he has bet on that number. Similarly, each bet type has different payout schemes.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/Play_Direct.png"/>
							</div>
							<p>User must select the className S2/S3/S4/S5 in the Direct Option on the top of the screen. For S2 Press the number in the rectangular box on which the user wishes to place his bets. Select the Stake amount in the top of screen. The application will display the Total amount based on the className selected and the expected payout (if the player wins) will be displayed to the left.</p>
							<p>User can select multiple combinations of bets by using the “ADD TO CART” button. After selecting required bets, the Application Will Redirect to Ticket Preview Screen. user can use the “PURCHASE” button to send the bet information to server for confirmation.</p>
						<h2 id="permutation">PERMUTATION</h2>
							<p>The Permutation option has four classNamees P2 / P3 / P4 / P5. The Permutation option gives a player higher chance of winning by creating different combination based on the className selection. For P2 user must select minimum 2 numbers, for P3 user must select minimum 3 numbers, for P4 user must select minimum 4 times and for P5 user must select minimum 5 numbers, with a maximum of up to 15 numbers to be selected in all “P” classNamees. After selecting the numbers user must Select the stake amount the user wishes to bet on the selected numbers. The prize payout depends on the className selected. For example, for P2 if the number user has selected matches with result, user will get a payout of 240 times of the stake amount he has bet on that number. Similarly, each className has different payout schemes (see above). The amount of numbers selected will correlate with the number of possible combinations, depending on the exact className. The system will calculate the number of possible number combinations, while the amount staked will be multiplied by the number of combinations. For example, user selects P2 and selects 3 numbers (12, 13, 15) with stake of 50, then the permutation outcome is three combinations (12, 13 / 12, 15 and 13, 15) with a total stake of 150, (i.e., 50 stake for each number combination).</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/Play_Per.png"/>
							</div>
						<h2 id="banker">BANKER</h2>
							<p>The Banker option has four classNamees B2 / B3 / B4 / B5. The Banker option gives player higher chances of winning by creating different combination based on the className selection. This is a little different from Permutation, in that the user chooses a minimum of one “Banker” number, (with a maximum of 5 numbers) and the user will choose “Against” numbers, with a minimum of 1 and maximum of 10 based on the selected className. Combination is generated between Banker Numbers Against, (all numbers must be different from each other). For B2 user must select minimum 1 Banker number and 1 Against number, for B3 user must select minimum 3 numbers both combined (Banker + Against), for B4 user must select minimum 4 numbers both combined and for B5 user must select minimum 5 both combined. After selecting the numbers user must Select the stake amount user wishes to bet on the selected numbers. The prize payout depends on the className selected. For example, for B2 if the number user has selected matches with result, user will get payout of 240 times of the stake amount he has bet on that number combination. Similarly, each className has different payout schemes. For example, user selects B2 and selects One Banker number and two Variable Number (Banker-12, Variable/Against Number 15, 18, 19) with stake of 50, and then the outcome is three combinations (12, 15, 18 / 12, 18, 19 / 12, 15, 19) with a total stake of 150, 50 stake for each combination.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/Play_Bank.png"/>
							</div>
						<h2 id="against">AGAINST</h2>
							<p>The Against option allows user can choose minimum 1 number in the fixed number and 1 number in the "Against" number. Combination is generated for each fixed number against each number in the "Against" numbers. For example, if player chooses 12, 14 in fixed row and 13, 15 in "Against" number with stake amount of 50, then the outcome is four combinations (12,13 / 12,15 / 14,13 / 14,15) with a total stake of 200, 50 stake for each combination. All other rules regarding payout apply as before.</p>
							<div className="text-ImageCont">
								<img src="./assets/img//how_to_play_images/Play_Agan.png"/>
							</div>
						<h2 id="howto_buy">HOW TO BUY?</h2>
							<p>After selecting the required bet lines, user must press the "Add to Slip" button to buy the selected bet lines. The application will display the selected bet lines with the total amount to pay.</p>
							<div className="text-ImageCont" style={{width: "300px"}}>
								<img src="./assets/img/how_to_play_images/buy.png"/>
							</div>
							<p>Press "Buy" to proceed and buy. Upon validation on the system appropriate message will be displayed on the application. The "BUY" page is a confirmation page for purchased ticket(s).</p>
							<div className="text-ImageCont" style={{width: "300px"}}>
								<img src="./assets/img/how_to_play_images/Buyslip.png"/>
							</div>
						<h2 id="fund_account">FUND ACCOUNT</h2>
							<p>The Fund account Menu allows user to add fund to wallet or transfer funds out of wallet. Currently the following modes of funding wallet are supported by Bazooka Lotto.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/fund.png"/>
								<img src="./assets/img/how_to_play_images/Deposit1.png"/>
								<img src="./assets/img/how_to_play_images/Deposit2.png"/>

							</div>
							
						<h2 id="depit_credit_card">DEBIT/CREDIT CARD</h2>
							<p>Allows user to fund player wallet using his Credit or Debit Card.</p>
							
						<h2 id="fund_wallet">FUND WALLET FROM WINNINGS</h2>
							<p>Allows user to fund player wallet from the prize-winning amount in previous transactions.</p>
						
						<h2 id="transfer_credit">TRANSFER CREDIT TO OTHER PLAYER</h2>
							<p>Allows user to transfer credits from his wallet to other players account.</p>
							
						<h2 id="load_credit">LOAD CREDIT PIN</h2>
							<p>Allows user to load his wallet from prepaid Credit PINs obtained from Bazooka.</p>
							
						<h2 id="credit_forwin">TRANSFER CREDIT FROM WINNINGS</h2>
							<p>Allows user to transfer his winnings credit to other player account.</p>
							
						<h2 id="credit_forcommision">TRANSFER CREDIT FROM COMMISSION</h2>
							<p>Allows user to transfer his commission credit to other player account.</p>
							
						<h2 id="my_account">CHECK BALANCE</h2>
							<p>Allows user to view his earning from Winnings and Commissions from the Bazooka game. It also allows the user to view his wallet details. Press the “Check Balance” to view the total earnings accrued so far.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/balance.png"/>
							</div>
								
						<h2 id="cash_out">CASH OUT</h2>
							<p>Allows user to cash out his winnings and commission through his bank account or other withdrawal methods provided.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/cash_out.png"/>								
							</div>
							<p>then KYC Status screen, withdrawal screen</p>
							<div className="text-ImageCont">								
								<img src="./assets/img/how_to_play_images/Cashout1.png"/>
								<img src="./assets/img/how_to_play_images/Cashout2.png"/>
							</div>
							
						<h2 id="my_network">MY NETWORK</h2>
							<p>My Network allows users to view the sales and commission details from players in their network who have registered with his/her referrer mobile number in the first Tier, and under linked users in the two subsequent Tiers. To view over all three tier sales and commissions from network press the "Show My Network" button.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/my-account.png"/>
							</div>
							<p>To view individual sales and commission earned from a specific player, enter the specific player’s mobile number, and press the "Show My Network" button.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/my-networks.png"/>
							</div>
							
						<h2 id="winning_nos">WINNINGS NOS</h2>
							<p>The Winnings allows user to view the winning numbers for past draws. The user can view a specific day or range of selected days by selecting the dates in the "From" and "To" Calendar.
							After selecting the date press the "OK" button. The Game name, Draw No, Result No, Machine No will be displayed as below. </p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/winning-nos.png"/>
							</div>
							
						<h2 id="account_summary">ACCOUNT SUMMARY</h2>
							<p>Account Summary allows user to view the transaction summary and Account Summary for the last 'N' transactions.
							The list of 'N' transactions will be displayed. User can select the From Date and To Date and view additional Details such as stake and bet line information.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/ac-summary.png"/>
							</div>
							
						<h2 id="myprofile_update">PROFILE</h2>
							<p>Here the user is allowed to change his/her password or Update Profile. (See below)
							The Change Password allows user to change password. User must enter Old Password, New Password and Confirm New password in the fields and press the "OK" button. A confirmation popup will appear after the password is changed successfully.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/my-profile.png"/>
							</div>
							
						<h2 id="myprofile_changepasswrd">CHANGE PASSWORD</h2>
							<p>The Change Password allows user to change password. User must enter Old Password, New Password and Confirm New password in the fields and press the "OK" button. A confirmation popup will appear after the password is changed successfully.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/change-pass.png"/>
							</div>
						
						<h2 id="log_out">LOGOUT</h2>
							<p>The Logout option allows user to exit from the application.</p>
							
						<h2 id="others">OTHERS</h2>
							<p>The Others option displays information related to the Bazooka application, such as links to "How to Play", "Prize Structure", “Affiliate Network Rewards”, and other useful information.</p>
							
						<h2 id="prize_structure">PRIZE STRUCTURE</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/prize_structure.png"/>
							</div>
							
						<h2 id="network_reward">AFFILIATE NETWORK REWARDS</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/network-reward.png"/>
							</div>
							<p>The Bottom of screen Menu option displays information related to the Bazooka application, such as links to "Contact Us", "Terms of Use", “Privacy Policy”, "FAQs", "Disclaimer" and other useful information.</p>
							
						<h2 id="terms_ofuse">TERMS OF USE</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/terms.png"/>
							</div>
							
						<h2 id="pricacy_policy">PRIVACY POLICY</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/privacy.png"/>
							</div>
							
						<h2 id="faq">FAQS</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/faq.png"/>
							</div>
						</section>
						<section id="cTab18" className="tabCont" style={{display: "none"}}>
							<div className="privacyCont">
								<span className="contentForHead">The following is the scheme, common for all draws in a day and also for all days in a week.</span>
								<h2>SCHEME</h2>
									<p>Per Stake Value Winning Multiples</p>
									
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<p className="text-right">Selected Balls</p>
										<table>
											<tbody>
												<tr>
													<th>Matching Balls</th>
													<th>1</th>
													<th>2</th>
													<th>3</th>
													<th>4</th>
													<th>5</th>
												</tr>
												<tr>
													<td>1</td>
													<td>40</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>2</td>
													<td></td>
													<td>240</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>3</td>
													<td></td>
													<td></td>
													<td>2100</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>4</td>
													<td></td>
													<td></td>
													<td></td>
													<td>6000</td>
													<td>0</td>
												</tr>
												<tr>
													<td>5</td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td>50000</td>
												</tr>
											</tbody>
										</table>
										<span className="contentForHead">The winning steam is powered by different types of the bet types(className 1,className 2,className 3,className 4,className 5) this Bazooka Lotto has,this is elaborated as below.</span>
									</div>
									</div>
								<h2>a. className 1:</h2>
									<p>Here the player has to select minimum 1 number for getting qu alified for the ticket. If the selected any one number matches with the first digit of the 5 numbers drawn, then the player is entitled to prize winning of 40 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
									
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>COMBINATION</td>
													<td>P1 at N50 10 80 55</td>
													<td>55 10 80 90 05 (combinations 10 80 55)</td>
													<td>WIN 40 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>BANKER</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
												<tr>
													<td>3</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>b. className 2:</h2>
									<p>Here the player has to select minimum 2 numbers for getting qualified for the ticket. If the selected any two numbers matches with any two digits of the 5 numbers drawn in any order, then the player is entitled to prize winning of 240 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div classNames="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S2 at N50 05 55</td>
													<td>55 10 80 90 05</td>
													<td>WIN 240 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P2 at N50 10 80 20 40 (combinations 10 80, 10 20, 10 40, 80 20, 80 40, 20 40) Here only one winning combinations</td>
													<td>55 10 80 90 05	</td>
													<td>IN 240 TIMES N50 multiplied with number of winning combinations, 240*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B2 at N50 10 AGAINST B2 at N50 80 90 55 (combinations 10 80, 10 90, 10 55) Here 3 winning combinations</td>
													<td>55 10 80 90 05</td>
													<td>WIN 240 TIMES N50 multiplied with number of winning combin ations, 240*N50*3</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>AGAINST A2 at N50 11 05 80 (combinations 01 11, 01 05, 01 80, 10 11, 10 05, 10 80, 90 11, 90 05, 90 80) Here 2 winning combinations</td>
													<td>55 10 80 90 05</td>
													<td>WIN 240 TIMES N50 multiplied with number of winning combinations, 240*N50*2</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>c. className 3:</h2>
									<p>Here the player has to select minimum 3 numbers for getting qualified for the ticket. If the selected any three numbers matches with any three digits of the 5 numbers drawn in any order, then the player is entitled to prize winn ing of 2100 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S3 at N50 90 05 55</td>
													<td>55 10 80 90 05</td>
													<td>WIN 2100 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P3 at N50 10 80 20 90 (combinations 10 80 20, 10 80 90, 10 20 90, 80 20 90) Here only one winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 2100 TIMES N50 multiplied with number of w inning combinations,2100*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B3 at N50 10 AGAINST B2 at N50 80 90 55 (combinations 10 80 90, 10 80 55, 10 90 55) Here 3 winning combinations</td>
													<td>55 10 80 90 05</td>
													<td>WIN 2100 TIMES N50 multiplied with number of winn ing combinations,2100*N50*3</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>d. className 4:</h2>
									<p>Here the player has to select minimum 4 numbers for getting qualified for the ticket. If the selected any four numbers matches with any four digits of the 5 numbers drawn in any order, then the player is entitled to prize winnin g of 6000 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S4 at N50 55 10 80 05</td>
													<td>55 10 80 90 05</td>
													<td>WIN 6000 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P4 at N50 55 10 80 90 20 (combinations 55 10 80 90, 55 10 80 20, 55 80 90 20, 10 80 90 20, 55 10 90 20) Here only one winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 6000 TIMES N50 multiplied with number of winning combinations,6000*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B4 at N50 05 AGAINST B2at N50 55 10 80 02 20 (combinations 05 55 10 80, 05 55 10 02, 05 55 10 20, 05 55 80 02, 05 55 80 20, 05 55 02 20, 05 10 80 02, 05 10 80 20, 05 10 02 20, 05 80 02 20) Here 1 winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 6000 TIMES N50 multiplied with number of winning combinations,6000*N50*1</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>e. className 5:</h2>
									<p>Here the player has to select minimum 5 numbers for getting qualified for the ticket. If the selected any five numbers matches with all digits of the 5 numbers drawn in any order, then the player is entitled to prize winning of 50000 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S5 at N50 55 10 80 05</td>
													<td>55 10 80 90 05</td>
													<td>WIN 50000 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P5 at N50 55 10 80 90 01 05 (combinations 55 10 80 90 01, 55 10 80 90 05, 55 10 80 01 05, 55 10 90 01 05, 55 80 90 01 05, 10 80 90 01 05) Here, only one winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 50000 TIMES N50 multiplied with number of winning combinations,50000*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B5 at N50 05 AGAINST B2at N50 55 10 80 90 01 (combinations 05 55 10 80 90, 05 55 10 80 01, 05 55 10 90 01, 05 55 80 90 01, 05 10 80 90 01) Here, 1 winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 50000 TIMES N50 multiplied with number of winning combinations,50000*N50*1</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
							</div>
						</section>
						<section id="cTab19" className="tabCont" style={{display: "none"}}>
							<div className="privacyCont addContforPadd">
								<h1>AFFILIATE NETWORK REWARDS</h1>
									<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p>
								<h2>EXAMPLE:</h2>
								<div className="contntPrizeTble">										
										<div className="contntInrPrizeTble">											
											<table>
												<tbody>
													<tr>
														<th>PRINCIPAL PLAYER (YOU)</th>
														<th>REFERENCE</th>
														<th>PRINCIPAL AGENT</th>
														<th>TIER</th>
														<th>COMMISSION</th>														
													</tr>
													<tr>
														<td>1st Referral</td>
														<td>Referred by YOU</td>
														<td>AGENT</td>
														<td>Tier 1</td>
														<td>6%</td>												
													</tr>
													<tr>
														<td>2nd Referral</td>
														<td>Referred by your Tier 1</td>
														<td>SUB AGENT</td>
														<td>Tier 2</td>
														<td>3%</td>														
													</tr>
													<tr>
														<td>3rd Referral</td>
														<td>Referred by Tier 2</td>
														<td>PLAYER</td>
														<td>Tier 3</td>
														<td>1%</td>														
													</tr>													
												</tbody>
											</table>											
										</div>
										</div>
									<ul className="marleft">
										<li><p className="text-bold">•	PRINCIPAL PLAYER (YOU) - Principal Agent</p></li>
										<li><p className="text-bold">•	1ST REFERRAL - Agent (6%) First Tier</p></li>
										<li><p className="text-bold">•	2ND REFERRAL - Sub Agent (3%) Second Tier</p></li>
										<li><p className="text-bold">•	3RD REFERRAL - Bottom Player (1%) Third Tier</p></li>
									</ul>
									<ul className="marleft">
										<li><p>1.The first column shows your relationships to the players you have referred and players that have been referred by the other tiers, e.g., your 1st Tier Refers the 2ndTier, and the 2nd tier refers the 3rdTier.</p></li>
										<li><p>2.The thirdcolumn illustrates the same order, by relating them to a laid-out agency structure.</p></li>
										<li><p>3.The fifthcolumn illustrates the percentage commissions due to the Principal Player from each tier of referrals.</p></li>
										<li><p>4.Obviously the more actively you recruit referees to play BazookaLotto, the higher your potential commissions.</p></li>
										<li><p>5.If we use 30 as an average number of people referred by the principal agent, it means that you as the Principal Player will have 30 people referred by you and under your 1st Referral tier. You will make 6% commissions from your 1st Tierfrom the money they spend playing the games.</p></li>
										<li><p>6.As we’re using 30, let us assume that each of your 1st Tier also refers 30 people to sign up and play the game, thereby creating your 2nd Referral tier. That means your 2nd Referral tier will have 900 people, from which you earn 3% on all their total spend playing the game.</p></li>
										<li><p>7.Likewise your 2nd Referral tier each recruiting 30 people (900 x 30) will mean you will have 27,000 players in your 3rd Referral tier. You will make 1% from all monies spent playing the game by each of your 3rd Referrals.</p></li>
										<li><p>8.BazookaLotto, therefore, rewards players who recruit other players and encourage them to play regularly.</p></li>
										<li><p>9.BazookaLotto also ensures that only active players are eligible for commissions, which ensures you benefit from your various tiers. There is a minimum required spend of N1,500 by the 17th of each month to remain within the agency network and qualify to collect commissions.</p></li>
										<li><p>10.This is why everybody is a winner on BazookaLotto because you win in multiple ways : </p>
										<p>i.	One, by being lucky and picking winning numbers as you play the game.</p>
										<p>ii.	Two, by recruiting others to play and benefitting from our generous commission structure.</p>
										<p>iii.	By buying bulk discounted game playing credit, which you can either use to play or sell to players in your network and other networks.</p>
										</li>

									</ul>
							</div>
						</section>
					</div>
				</div>
							
			</div>
		</div>	
	}

{appsts	== 2 && 
		<div className="row">
			<div className="container forPadd1">
				<div className="privacyCont">					
					<div className="privacyRhtCont">
						<section id="cTab17" className="tabCont" style={{display: "block"}}>
						<h2 id="bazoko_lotto">THE BAZOOKA LOTTO MANUAL</h2>
							<p>The following is intended as a guide for both new players and player coaches for the Bazooka lotto website. Please study the manual carefully and master all aspects of the game. Each reader is expected to both benefit from the knowledge earned as well as being able to pass on the knowledge to other players in a detailed and easy to follow manner.</p>
							
						<h2 id="player_log">PLAYER LOGIN</h2>
							<p>The first screen that appears on the application will be the Login Screen. Already registered users can enter their registered mobile number and their chosen password to login into the application.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/login.png"/>
							</div>
							<p>After entering the mobile number and password, Press the "LOGIN" button to log into the application.</p>
							
						<h2 id="newuser_signup">CREATE YOUR ACCOUNT</h2>
							<p>New Users can Register with Bazooka Lotto using the "REGISTER" link available on the "Home" Screen shown above. Once the new player presses "REGISTER", you will be taken to the screen.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/signup.png"/>
							</div>
							<p>User must enter his mobile number, First Name, Last Name, Password, Confirm Password, Email address and Referral Code (Mobile Number) and press the "REGISTER" button. The user account will be created on the Bazooka server and confirmation pop up upon successful validation.</p>
							<p>Next, After Success Pop up message automatically redirect to Login screen page and enter your mobile number and password and start enjoying the games.</p>
							
						<h2 id="forgot_passwrd">FORGOT PASSWORD</h2>
							<p>Press the Forgot Password on the Login Screen. Enter you registered Mobile number with starting 0 and retype the Same Mobile Number in "Confirm Mobile No" for Confirmation. Click submits to forgot password. system will send the new OTP to you registered E-mail Address & SMS to your registered Mobile number.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/forgot-pass.png"/>
							</div>
							
						<h2 id="home">HOME</h2>
							<p>Upon successful login, the application displays the Home Screen. The Home Screen displays the menu options and sub options under each menu when selected.</p>
							<p>The lists of menus available in the home screen are:</p>
							<h2 style={{fontstyle:"italic",color:"#666464"}}>Home / Play / Winning Nos / My Network / How To Play / Rewards and Another Menu show in left side of Screen menu as Check Balance / Fund Account / Cash Out / Ticket Play History / Commission Details / Account Summary / Change Password / Profile / Logout</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/home.png"/>								
							</div>														
							<p>By default, the "Home" menu that shows Username, Latest Winning Nos in Scrolling, Next Draw Close Time, News. Other Menus will be shown at the top next to "HOME" and at the left side of screen and respective submenus will be displayed after clicking on those Menus.</p>
							<div className="text-ImageCont">								
								<img src="./assets/img/how_to_play_images/Home2.png"/>
							</div>
							
						<h2 id="play">PLAY</h2>
							<p>As noted before the "PLAY" screen has a list of games available for playing, with Game Name, Draw Date and Time. User can select from the list which of the games the user wishes to play.
							Example of available draws are shown above, and they are Cash Cash; Hammer; Awoof; Jackpot; and Billionaire / Ghana Games.
							Once a Game has been selected the "GAME LIST" and "Stake Amount" and "Option" and "class" and Betting Balls screen below in superimposed on the Games page</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/lotto.png"/>
							</div>
							<p>On selecting a game, the application will Clear already Selected Betting Balls Selection (Lucky Number, Banker, Stand, Against) and bet options available for play. Currently we have four general betting options as listed below:</p>
							<ul className="marleft">
								<li><p>1.Direct</p></li>
								<li><p>2.Permutation</p></li>
								<li><p>3.Banker</p></li>
								<li><p>4.Against options.</p></li>
							</ul>
							<p>Each option will be explained in the next section, "GAMES EXPLAINED"</p>
						
						<h2 id="game_explan">GAMES EXPLAINED:</h2>
							<p>It is important to note that there are general principles that guide the classNamees and rewards in all the types of sub options of games. 5/90 is a fixed odds betting game. The payout is derived from a multiplication of the amount staked. The following are the general payouts based on the odds in each className:</p>
							<ul className="marleft">
								{/* <li><p> • className1 (1 number) = 40 x staked amount</p></li> */}
								<li><p> • className2 (2 numbers) = 240 x staked amount</p></li>
								<li><p> • className3 (3 numbers) = 2,100 x staked amount</p></li>
								<li><p> • className4 (4 numbers) = 6,000 x staked amount</p></li>
								<li><p> • className5 (5 numbers) = 50,000 x staked amount</p></li>
							</ul>
							<p>The classNamees are determined by the amount of numbers picked per game. For example, in className 2 the player picks his two lucky numbers. When the numbers picked per className are drawn in the draw for that specific game, the player wins 240x the amount they’ve staked. i.e., if the player has staked N1,000 and his numbers are drawn together, then he wins N240,000.</p>
						<h2 id="direct">DIRECT</h2>
							<p>The Direct option also referred to as Simple, has four classNamees S2 / S3 / S4 / S5. For S2, the user must select 2 numbers, for S3 the user must select 3 numbers, for S4 the user must select 4 numbers, for S5 the user must select 5 numbers. After selecting the numbers, the user should Select the stake amount the user wishes to bet on the selected numbers. The prize payout depends on the option selected. For example, for S2 if the number the user has selected matches with the draw result, the user will get a payout of 240 times the stake amount he has bet on that number. Similarly, each bet type has different payout schemes.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/Play_Direct.png"/>
							</div>
							<p>User must select the className S2/S3/S4/S5 in the Direct Option on the top of the screen. For S2 Press the number in the rectangular box on which the user wishes to place his bets. Select the Stake amount in the top of screen. The application will display the Total amount based on the className selected and the expected payout (if the player wins) will be displayed to the left.</p>
							<p>User can select multiple combinations of bets by using the “ADD TO CART” button. After selecting required bets, the Application Will Redirect to Ticket Preview Screen. user can use the “PURCHASE” button to send the bet information to server for confirmation.</p>
						<h2 id="permutation">PERMUTATION</h2>
							<p>The Permutation option has four classNamees P2 / P3 / P4 / P5. The Permutation option gives a player higher chance of winning by creating different combination based on the className selection. For P2 user must select minimum 2 numbers, for P3 user must select minimum 3 numbers, for P4 user must select minimum 4 times and for P5 user must select minimum 5 numbers, with a maximum of up to 15 numbers to be selected in all “P” classNamees. After selecting the numbers user must Select the stake amount the user wishes to bet on the selected numbers. The prize payout depends on the className selected. For example, for P2 if the number user has selected matches with result, user will get a payout of 240 times of the stake amount he has bet on that number. Similarly, each className has different payout schemes (see above). The amount of numbers selected will correlate with the number of possible combinations, depending on the exact className. The system will calculate the number of possible number combinations, while the amount staked will be multiplied by the number of combinations. For example, user selects P2 and selects 3 numbers (12, 13, 15) with stake of 50, then the permutation outcome is three combinations (12, 13 / 12, 15 and 13, 15) with a total stake of 150, (i.e., 50 stake for each number combination).</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/Play_Per.png"/>
							</div>
						<h2 id="banker">BANKER</h2>
							<p>The Banker option has four classNamees B2 / B3 / B4 / B5. The Banker option gives player higher chances of winning by creating different combination based on the className selection. This is a little different from Permutation, in that the user chooses a minimum of one “Banker” number, (with a maximum of 5 numbers) and the user will choose “Against” numbers, with a minimum of 1 and maximum of 10 based on the selected className. Combination is generated between Banker Numbers Against, (all numbers must be different from each other). For B2 user must select minimum 1 Banker number and 1 Against number, for B3 user must select minimum 3 numbers both combined (Banker + Against), for B4 user must select minimum 4 numbers both combined and for B5 user must select minimum 5 both combined. After selecting the numbers user must Select the stake amount user wishes to bet on the selected numbers. The prize payout depends on the className selected. For example, for B2 if the number user has selected matches with result, user will get payout of 240 times of the stake amount he has bet on that number combination. Similarly, each className has different payout schemes. For example, user selects B2 and selects One Banker number and two Variable Number (Banker-12, Variable/Against Number 15, 18, 19) with stake of 50, and then the outcome is three combinations (12, 15, 18 / 12, 18, 19 / 12, 15, 19) with a total stake of 150, 50 stake for each combination.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/Play_Bank.png"/>
							</div>
						<h2 id="against">AGAINST</h2>
							<p>The Against option allows user can choose minimum 1 number in the fixed number and 1 number in the "Against" number. Combination is generated for each fixed number against each number in the "Against" numbers. For example, if player chooses 12, 14 in fixed row and 13, 15 in "Against" number with stake amount of 50, then the outcome is four combinations (12,13 / 12,15 / 14,13 / 14,15) with a total stake of 200, 50 stake for each combination. All other rules regarding payout apply as before.</p>
							<div className="text-ImageCont">
								<img src="./assets/img//how_to_play_images/Play_Agan.png"/>
							</div>
						<h2 id="howto_buy">HOW TO BUY?</h2>
							<p>After selecting the required bet lines, user must press the "Add to Slip" button to buy the selected bet lines. The application will display the selected bet lines with the total amount to pay.</p>
							<div className="text-ImageCont" style={{width: "300px"}}>
								<img src="./assets/img/how_to_play_images/buy.png"/>
							</div>
							<p>Press "Buy" to proceed and buy. Upon validation on the system appropriate message will be displayed on the application. The "BUY" page is a confirmation page for purchased ticket(s).</p>
							<div className="text-ImageCont" style={{width: "300px"}}>
								<img src="./assets/img/how_to_play_images/Buyslip.png"/>
							</div>
						<h2 id="fund_account">FUND ACCOUNT</h2>
							<p>The Fund account Menu allows user to add fund to wallet or transfer funds out of wallet. Currently the following modes of funding wallet are supported by Bazooka Lotto.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/fund.png"/>
								<img src="./assets/img/how_to_play_images/Deposit1.png"/>
								<img src="./assets/img/how_to_play_images/Deposit2.png"/>

							</div>
							
						<h2 id="depit_credit_card">DEBIT/CREDIT CARD</h2>
							<p>Allows user to fund player wallet using his Credit or Debit Card.</p>
							
						<h2 id="fund_wallet">FUND WALLET FROM WINNINGS</h2>
							<p>Allows user to fund player wallet from the prize-winning amount in previous transactions.</p>
						
						<h2 id="transfer_credit">TRANSFER CREDIT TO OTHER PLAYER</h2>
							<p>Allows user to transfer credits from his wallet to other players account.</p>
							
						<h2 id="load_credit">LOAD CREDIT PIN</h2>
							<p>Allows user to load his wallet from prepaid Credit PINs obtained from Bazooka.</p>
							
						<h2 id="credit_forwin">TRANSFER CREDIT FROM WINNINGS</h2>
							<p>Allows user to transfer his winnings credit to other player account.</p>
							
						<h2 id="credit_forcommision">TRANSFER CREDIT FROM COMMISSION</h2>
							<p>Allows user to transfer his commission credit to other player account.</p>
							
						<h2 id="my_account">CHECK BALANCE</h2>
							<p>Allows user to view his earning from Winnings and Commissions from the Bazooka game. It also allows the user to view his wallet details. Press the “Check Balance” to view the total earnings accrued so far.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/balance.png"/>
							</div>
								
						<h2 id="cash_out">CASH OUT</h2>
							<p>Allows user to cash out his winnings and commission through his bank account or other withdrawal methods provided.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/cash_out.png"/>								
							</div>
							<p>then KYC Status screen, withdrawal screen</p>
							<div className="text-ImageCont">								
								<img src="./assets/img/how_to_play_images/Cashout1.png"/>
								<img src="./assets/img/how_to_play_images/Cashout2.png"/>
							</div>
							
						<h2 id="my_network">MY NETWORK</h2>
							<p>My Network allows users to view the sales and commission details from players in their network who have registered with his/her referrer mobile number in the first Tier, and under linked users in the two subsequent Tiers. To view over all three tier sales and commissions from network press the "Show My Network" button.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/my-account.png"/>
							</div>
							<p>To view individual sales and commission earned from a specific player, enter the specific player’s mobile number, and press the "Show My Network" button.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/my-networks.png"/>
							</div>
							
						<h2 id="winning_nos">WINNINGS NOS</h2>
							<p>The Winnings allows user to view the winning numbers for past draws. The user can view a specific day or range of selected days by selecting the dates in the "From" and "To" Calendar.
							After selecting the date press the "OK" button. The Game name, Draw No, Result No, Machine No will be displayed as below. </p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/winning-nos.png"/>
							</div>
							
						<h2 id="account_summary">ACCOUNT SUMMARY</h2>
							<p>Account Summary allows user to view the transaction summary and Account Summary for the last 'N' transactions.
							The list of 'N' transactions will be displayed. User can select the From Date and To Date and view additional Details such as stake and bet line information.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/ac-summary.png"/>
							</div>
							
						<h2 id="myprofile_update">PROFILE</h2>
							<p>Here the user is allowed to change his/her password or Update Profile. (See below)
							The Change Password allows user to change password. User must enter Old Password, New Password and Confirm New password in the fields and press the "OK" button. A confirmation popup will appear after the password is changed successfully.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/my-profile.png"/>
							</div>
							
						<h2 id="myprofile_changepasswrd">CHANGE PASSWORD</h2>
							<p>The Change Password allows user to change password. User must enter Old Password, New Password and Confirm New password in the fields and press the "OK" button. A confirmation popup will appear after the password is changed successfully.</p>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/change-pass.png"/>
							</div>
						
						<h2 id="log_out">LOGOUT</h2>
							<p>The Logout option allows user to exit from the application.</p>
							
						<h2 id="others">OTHERS</h2>
							<p>The Others option displays information related to the Bazooka application, such as links to "How to Play", "Prize Structure", “Affiliate Network Rewards”, and other useful information.</p>
							
						<h2 id="prize_structure">PRIZE STRUCTURE</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/prize_structure.png"/>
							</div>
							
						<h2 id="network_reward">AFFILIATE NETWORK REWARDS</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/network-reward.png"/>
							</div>
							<p>The Bottom of screen Menu option displays information related to the Bazooka application, such as links to "Contact Us", "Terms of Use", “Privacy Policy”, "FAQs", "Disclaimer" and other useful information.</p>
							
						<h2 id="terms_ofuse">TERMS OF USE</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/terms.png"/>
							</div>
							
						<h2 id="pricacy_policy">PRIVACY POLICY</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/privacy.png"/>
							</div>
							
						<h2 id="faq">FAQS</h2>
							<div className="text-ImageCont">
								<img src="./assets/img/how_to_play_images/faq.png"/>
							</div>
						</section>
						<section id="cTab18" className="tabCont" style={{display: "none"}}>
							<div className="privacyCont">
								<span className="contentForHead">The following is the scheme, common for all draws in a day and also for all days in a week.</span>
								<h2>SCHEME</h2>
									<p>Per Stake Value Winning Multiples</p>
									
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<p className="text-right">Selected Balls</p>
										<table>
											<tbody>
												<tr>
													<th>Matching Balls</th>
													<th>1</th>
													<th>2</th>
													<th>3</th>
													<th>4</th>
													<th>5</th>
												</tr>
												<tr>
													<td>1</td>
													<td>40</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>2</td>
													<td></td>
													<td>240</td>
													<td>0</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>3</td>
													<td></td>
													<td></td>
													<td>2100</td>
													<td>0</td>
													<td>0</td>
												</tr>
												<tr>
													<td>4</td>
													<td></td>
													<td></td>
													<td></td>
													<td>6000</td>
													<td>0</td>
												</tr>
												<tr>
													<td>5</td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
													<td>50000</td>
												</tr>
											</tbody>
										</table>
										<span className="contentForHead">The winning steam is powered by different types of the bet types(className 1,className 2,className 3,className 4,className 5) this Bazooka Lotto has,this is elaborated as below.</span>
									</div>
									</div>
								<h2>a. className 1:</h2>
									<p>Here the player has to select minimum 1 number for getting qu alified for the ticket. If the selected any one number matches with the first digit of the 5 numbers drawn, then the player is entitled to prize winning of 40 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
									
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>COMBINATION</td>
													<td>P1 at N50 10 80 55</td>
													<td>55 10 80 90 05 (combinations 10 80 55)</td>
													<td>WIN 40 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>BANKER</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
												<tr>
													<td>3</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>b. className 2:</h2>
									<p>Here the player has to select minimum 2 numbers for getting qualified for the ticket. If the selected any two numbers matches with any two digits of the 5 numbers drawn in any order, then the player is entitled to prize winning of 240 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div classNames="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S2 at N50 05 55</td>
													<td>55 10 80 90 05</td>
													<td>WIN 240 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P2 at N50 10 80 20 40 (combinations 10 80, 10 20, 10 40, 80 20, 80 40, 20 40) Here only one winning combinations</td>
													<td>55 10 80 90 05	</td>
													<td>IN 240 TIMES N50 multiplied with number of winning combinations, 240*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B2 at N50 10 AGAINST B2 at N50 80 90 55 (combinations 10 80, 10 90, 10 55) Here 3 winning combinations</td>
													<td>55 10 80 90 05</td>
													<td>WIN 240 TIMES N50 multiplied with number of winning combin ations, 240*N50*3</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>AGAINST A2 at N50 11 05 80 (combinations 01 11, 01 05, 01 80, 10 11, 10 05, 10 80, 90 11, 90 05, 90 80) Here 2 winning combinations</td>
													<td>55 10 80 90 05</td>
													<td>WIN 240 TIMES N50 multiplied with number of winning combinations, 240*N50*2</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>c. className 3:</h2>
									<p>Here the player has to select minimum 3 numbers for getting qualified for the ticket. If the selected any three numbers matches with any three digits of the 5 numbers drawn in any order, then the player is entitled to prize winn ing of 2100 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S3 at N50 90 05 55</td>
													<td>55 10 80 90 05</td>
													<td>WIN 2100 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P3 at N50 10 80 20 90 (combinations 10 80 20, 10 80 90, 10 20 90, 80 20 90) Here only one winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 2100 TIMES N50 multiplied with number of w inning combinations,2100*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B3 at N50 10 AGAINST B2 at N50 80 90 55 (combinations 10 80 90, 10 80 55, 10 90 55) Here 3 winning combinations</td>
													<td>55 10 80 90 05</td>
													<td>WIN 2100 TIMES N50 multiplied with number of winn ing combinations,2100*N50*3</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>d. className 4:</h2>
									<p>Here the player has to select minimum 4 numbers for getting qualified for the ticket. If the selected any four numbers matches with any four digits of the 5 numbers drawn in any order, then the player is entitled to prize winnin g of 6000 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S4 at N50 55 10 80 05</td>
													<td>55 10 80 90 05</td>
													<td>WIN 6000 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P4 at N50 55 10 80 90 20 (combinations 55 10 80 90, 55 10 80 20, 55 80 90 20, 10 80 90 20, 55 10 90 20) Here only one winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 6000 TIMES N50 multiplied with number of winning combinations,6000*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B4 at N50 05 AGAINST B2at N50 55 10 80 02 20 (combinations 05 55 10 80, 05 55 10 02, 05 55 10 20, 05 55 80 02, 05 55 80 20, 05 55 02 20, 05 10 80 02, 05 10 80 20, 05 10 02 20, 05 80 02 20) Here 1 winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 6000 TIMES N50 multiplied with number of winning combinations,6000*N50*1</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
								<h2>e. className 5:</h2>
									<p>Here the player has to select minimum 5 numbers for getting qualified for the ticket. If the selected any five numbers matches with all digits of the 5 numbers drawn in any order, then the player is entitled to prize winning of 50000 times of the selected stake Amount.</p>
									<div className="contntPrizeTble">
										
									<div className="contntInrPrizeTble">
										<table>
											<tbody>
												<tr>
													<th>S.NO</th>
													<th>Options</th>
													<th>SELECTED NUMBERS</th>
													<th>DRAWN 5 NUMBERS</th>
													<th>WINNING STATUS</th>
												</tr>
												<tr>
													<td>1</td>
													<td>DIRECT</td>
													<td>S5 at N50 55 10 80 05</td>
													<td>55 10 80 90 05</td>
													<td>WIN 50000 TIMES N50</td>
												</tr>
												<tr>
													<td>2</td>
													<td>COMBINATION</td>
													<td>P5 at N50 55 10 80 90 01 05 (combinations 55 10 80 90 01, 55 10 80 90 05, 55 10 80 01 05, 55 10 90 01 05, 55 80 90 01 05, 10 80 90 01 05) Here, only one winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 50000 TIMES N50 multiplied with number of winning combinations,50000*N50*1</td>
												</tr>
												<tr>
													<td>3</td>
													<td>BANKER</td>
													<td>B5 at N50 05 AGAINST B2at N50 55 10 80 90 01 (combinations 05 55 10 80 90, 05 55 10 80 01, 05 55 10 90 01, 05 55 80 90 01, 05 10 80 90 01) Here, 1 winning combination</td>
													<td>55 10 80 90 05</td>
													<td>WIN 50000 TIMES N50 multiplied with number of winning combinations,50000*N50*1</td>
												</tr>
												<tr>
													<td>4</td>
													<td>AGAINST</td>
													<td>NA</td>
													<td>NA</td>
													<td>NA</td>
												</tr>
											</tbody>
										</table>
									</div>
									</div>
							</div>
						</section>
						<section id="cTab19" className="tabCont" style={{display: "none"}}>
							<div className="privacyCont addContforPadd">
								<h1>AFFILIATE NETWORK REWARDS</h1>
									<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p>
								<h2>EXAMPLE:</h2>
								<div className="contntPrizeTble">										
										<div className="contntInrPrizeTble">											
											<table>
												<tbody>
													<tr>
														<th>PRINCIPAL PLAYER (YOU)</th>
														<th>REFERENCE</th>
														<th>PRINCIPAL AGENT</th>
														<th>TIER</th>
														<th>COMMISSION</th>														
													</tr>
													<tr>
														<td>1st Referral</td>
														<td>Referred by YOU</td>
														<td>AGENT</td>
														<td>Tier 1</td>
														<td>6%</td>												
													</tr>
													<tr>
														<td>2nd Referral</td>
														<td>Referred by your Tier 1</td>
														<td>SUB AGENT</td>
														<td>Tier 2</td>
														<td>3%</td>														
													</tr>
													<tr>
														<td>3rd Referral</td>
														<td>Referred by Tier 2</td>
														<td>PLAYER</td>
														<td>Tier 3</td>
														<td>1%</td>														
													</tr>													
												</tbody>
											</table>											
										</div>
										</div>
									<ul className="marleft">
										<li><p className="text-bold">•	PRINCIPAL PLAYER (YOU) - Principal Agent</p></li>
										<li><p className="text-bold">•	1ST REFERRAL - Agent (6%) First Tier</p></li>
										<li><p className="text-bold">•	2ND REFERRAL - Sub Agent (3%) Second Tier</p></li>
										<li><p className="text-bold">•	3RD REFERRAL - Bottom Player (1%) Third Tier</p></li>
									</ul>
									<ul className="marleft">
										<li><p>1.The first column shows your relationships to the players you have referred and players that have been referred by the other tiers, e.g., your 1st Tier Refers the 2ndTier, and the 2nd tier refers the 3rdTier.</p></li>
										<li><p>2.The thirdcolumn illustrates the same order, by relating them to a laid-out agency structure.</p></li>
										<li><p>3.The fifthcolumn illustrates the percentage commissions due to the Principal Player from each tier of referrals.</p></li>
										<li><p>4.Obviously the more actively you recruit referees to play BazookaLotto, the higher your potential commissions.</p></li>
										<li><p>5.If we use 30 as an average number of people referred by the principal agent, it means that you as the Principal Player will have 30 people referred by you and under your 1st Referral tier. You will make 6% commissions from your 1st Tierfrom the money they spend playing the games.</p></li>
										<li><p>6.As we’re using 30, let us assume that each of your 1st Tier also refers 30 people to sign up and play the game, thereby creating your 2nd Referral tier. That means your 2nd Referral tier will have 900 people, from which you earn 3% on all their total spend playing the game.</p></li>
										<li><p>7.Likewise your 2nd Referral tier each recruiting 30 people (900 x 30) will mean you will have 27,000 players in your 3rd Referral tier. You will make 1% from all monies spent playing the game by each of your 3rd Referrals.</p></li>
										<li><p>8.BazookaLotto, therefore, rewards players who recruit other players and encourage them to play regularly.</p></li>
										<li><p>9.BazookaLotto also ensures that only active players are eligible for commissions, which ensures you benefit from your various tiers. There is a minimum required spend of N1,500 by the 17th of each month to remain within the agency network and qualify to collect commissions.</p></li>
										<li><p>10.This is why everybody is a winner on BazookaLotto because you win in multiple ways : </p>
										<p>i.	One, by being lucky and picking winning numbers as you play the game.</p>
										<p>ii.	Two, by recruiting others to play and benefitting from our generous commission structure.</p>
										<p>iii.	By buying bulk discounted game playing credit, which you can either use to play or sell to players in your network and other networks.</p>
										</li>

									</ul>
							</div>
						</section>
					</div>
				</div>
							
			</div>
		</div>	
	}

	</div>
	{appsts	== 1 && <Footer /> }
	</div>
        )
    };
}

function mapStateToProps(state) {
    const { games } = state.gameReducer;
    return { games };
}
const mapActionToProps = {
    SelectGameFamily: gameActions.SetGameFamily,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(HowToPlay))