import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class BetHistory extends Component {

    constructor(props) {
        super(props);
    }

    getRecord = async (flg) => {       
        let getIPad = "";
        if (flg != 1) {
            this.state.startDate = document.getElementById("date-time1").value;
            this.state.endDate = document.getElementById("date-time2").value;
        }

        if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
            getIPad = this.props.ClntIPAddr;
        }
        else{
            getIPad = await commonClass.GetIPAddress();					
        }

      let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

      let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate);
      if(_flog)      
        this.props.GetBetHistory(this.props.user.mobileNo, this.state.startDate, this.state.endDate,intlangflag, getIPad);
    };
    reSetFilter() {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            BetHistory: props.BetHistory,
        }
    };

    componentDidMount() {
        window.timecalender();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };
    rePrintTicker(TransId, gameGroupId) {
        this.props.ReprintTicket(TransId, this.props.user.mobileNo, gameGroupId,this.props.i18n.language);
    }
    render() {
        const { t } = this.props;
        return (
            <section id="cTab3" class="tabCont">
                <div className="accountDetls forpadd1">
                <div className="detailsHead">
                    <h2>{t('lblGameDetails')}</h2>
                    </div>
                    <div class="HstryDetls">
                        <ul>
                            <li class="dateCont">
                                <div class="HstryDetlsCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate white hasDatepicker" type="text" value={this.state.startDate} id="date-time1" name="startDate" placeholder="2017-06-10" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="HstryDetlsCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="endDate white hasDatepicker" type="text" value={this.state.endDate} id="date-time2" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="btn1" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                                <a href="#" class="btn1" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>                   
                    
                            <div class="trstractionHstryDetls">
                                <div class="trstractionHstryInrCont">
                                <div className="booking scrollbar">
                                    <table>
                                    <tbody>
                                        <tr>
                                            <th className="Col10">{t('lblTransID')}</th>
                                            <th className="Col15">{t('lblgamename')}</th>                                            
                                            <th className="Col16">{t('lbldrawdatetime')}</th> 
                                            {/* <th className="Col15">{t('lblBetoption')}</th>  */}
                                            <th className="Col22">{t('lblBetinfo')}</th> 
                                            <th className="Col15">{t('lblTransDate')}</th>
                                            {/* <th className="Col10">{t('lblstakeval')}</th> */}
                                            <th className="Col10">{t('lblstakeval')} ({this.props.appvariables.currency})</th>
                                            <th className="Col10">{t('lblResults')}</th>
                                            <th className="Col10">{t('lblwinval')} ({this.props.appvariables.currency})</th>
                                            <th className="Col10">{t('lblBetsts')}</th>
                                        </tr>
                                        {this.state.BetHistory && this.state.BetHistory.status == 0 && this.state.BetHistory.data.map((x, idx) =>
                                            <tr id={'bethis_' + idx}>
                                                {/*<td style={{cursor:"pointer"}} onClick={() => this.rePrintTicker(x.Id, x.GameGroupId)}><u>{x.Id}</u></td>*/}
                                                <td>{x.GameGroupId}</td>
                                                <td>{x.GameName}</td>                                                        
                                                <td>{x.DrawDateTime}</td>
                                                {/* <td>{x.Status}</td> */}
                                                <td>{x.Ball}</td>
                                                <td>{x.TransDateTime}</td>
                                                {/* <td>{x.TotalStakeAmount}</td> */}
                                                <td>{x.TotalBet}</td>
                                                <td>{x.Reslt}</td>                                
                                                <td>{x.WinAmount}</td>                    
                                                {x.Betstatus == 'WON' && <td><div className='betstatus2'>{x.Betstatus}</div></td>}
                                                {x.Betstatus == 'LOST' && <td><div className='betstatus1'>{x.Betstatus}</div></td>}
                                                {x.Betstatus == 'PENDING' && <td><div className='betstatus'>{x.Betstatus}</div></td>}
                                            </tr>
                                        )}
                                        {this.props.BetHistory && this.props.BetHistory.status != 0 &&
                                            <tr className="chngeTblBg" id="bethis_0">
                                                <td colspan="9" style={{fontsize:"14px;", textalign:"center;"}}>{this.props.BetHistory.msg}</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>       

                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { BetHistory } = state.accountReducer;
    const { user, appvariables } = state.loginReducer;
    return { BetHistory, user, appvariables };
}

const mapActionToProps = {
    GetBetHistory: accountActions.GetBetHistory,
    ReprintTicket: accountActions.ReprintTicket,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(BetHistory))