import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loginActions } from '../../../action/loginAction';
import { alertActions } from '../../../action/alertAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation } from 'react-i18next';
class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            newConfirmPassword: '',
            changeSubmitted: false,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitChangePassword = this.submitChangePassword.bind(this);
    } 
    
    showMsg = (msg) => {
		this.props.alertError(this.props.t(msg));
	}

    //submitChangePassword(e) {
    submitChangePassword = async (e) => {
        let getIPad = "";
        try {
            e.preventDefault();
            this.setState({ changeSubmitted: true });
            let { oldPassword, newPassword, newConfirmPassword } = this.state;
            if (oldPassword && newPassword && newConfirmPassword && newPassword == newConfirmPassword) {
                oldPassword = oldPassword.replace(/'/g, "''");
                newPassword = newPassword.replace(/'/g, "''");

                if(newPassword != newConfirmPassword){
                    this.showMsg("lblPasswordMismatch");
                return;
                }

                if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
					getIPad = this.props.ClntIPAddr;
				}
				else{
					getIPad = await commonClass.GetIPAddress();					
				}

                let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	

                this.props.changePassword(this.props.user.mobileNo,this.props.user.emailID, oldPassword, newPassword,intlangflag, getIPad);
            }
        }
        catch (ex) {
            commonClass.writeLog(ex, "ChangePassword-submitChangePassword", getIPad);
            return false;
        }
    };


    clearChangePwd = () => {
        try {
            this.setState({
                oldPassword: '',
                newPassword: '',
                newConfirmPassword: '',
                changeSubmitted: false,
            });
            this.state.oldPassword = "";
            this.state.newPassword = "";
            this.state.newConfirmPassword = "";
            this.state.changeSubmitted = false;
        }
        catch (ex) {
            commonClass.writeLog(ex, "ChangePassword-clearChangePwd");
            return false;
        }
    };

    handleInputChange(e) {

        this.setState({ changeSubmitted: false });

        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    };

    componentDidMount() {
		//window.docMapVisiblePwd();
    };

    render() {
        const {ClntIPAddr, t } = this.props;
        const { changeSubmitted, oldPassword, newPassword, newConfirmPassword } = this.state;
        return (
            <section class="tabCont">
                <div class="cnfrmDetls">
                <div className="detailsHead">
                    <h2>{t('lblChangepwd')}</h2>
                    </div>
                            <form method="post" name="enqForm" class="cnfrmpasSWd-form">
                                <div class="formField">                                    
                                <label>Old Password*</label>
                                        <input maxLength="15" minLength="8" type="password" placeholder={`${t('lblOldPassword')} *`} value={this.state.oldPassword} onChange={this.handleInputChange} name="oldPassword" />                                    
										{/* <span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span> */}
                                    {changeSubmitted && !oldPassword && <small>{t('lblRequired')}</small>}
                                </div>
                                <div class="formField">        
                                <label>New Password*</label>                            
                                        <input maxLength="15" minLength="8" type="password" placeholder={`${t('lblnewPassword')} *`} value={this.state.newPassword} onChange={this.handleInputChange} name="newPassword" />                                    
										{/* <span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span> */}
                                    {changeSubmitted && !newPassword && <small>{t('lblRequired')}</small>}
                                </div>
                                <div class="formField">
                                <label>Confirm Password*</label>                             
                                        <input maxLength="15" minLength="8" type="password" placeholder={`${t('lblConfirmpwd')} *`} value={this.state.newConfirmPassword} onChange={this.handleInputChange} name="newConfirmPassword" />                                    
										{/* <span class="eyeIconPwd eyeOpen eyeClosePwd" onClick={(e) => window.docMapVisiblePwd(e)}></span> */}
                                    {changeSubmitted && !newConfirmPassword && <small>{t('lblRequired')}</small>}
                                    {changeSubmitted && newPassword && newConfirmPassword && newPassword != newConfirmPassword && <small>{t('lblPasswordMismatch')}</small>}
                                </div>
                                <div class="formField">                                    
                                    <a class="confrmBtn" onClick={this.submitChangePassword}><button>{t('lblSubmit')}</button></a>
                                </div>
                            </form>                       
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { ClntIPAddr } = state.commonReducer;
    return { user, ClntIPAddr };
}

const mapActionToProps = {
    changePassword: loginActions.changePassword,
    error: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ChangePassword))