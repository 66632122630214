import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { accountActions } from '../../action/accountAction';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withTranslation } from 'react-i18next';
//import { loginActions } from '../../action/loginAction';

class Disclaimer extends Component {
    constructor(props) {
        super(props);
		this.state = {         
            appsts: 1
        }

		const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

		//if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;

		//let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.Gethowtoplay(langflag,6);
    }

    componentDidMount() {
        //window.scrollTo(0,0);
    };

	static getDerivedStateFromProps(props, state) {
        return {            
            gethowtoplay: props.gethowtoplay != undefined ? props.gethowtoplay : {}
        }
    };

	tet=()=> {
		//let ddd='<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p><h2>EXAMPLE:</h2><div className="contntPrizeTble"><div className="contntInrPrizeTble"></div></div>';
		let ddd=this.state.gethowtoplay;
		 let dd= <div className="privacyCont" dangerouslySetInnerHTML={{__html:ddd }}></div>			
		  return dd;
	  }

    render() {
        const { i18n } = this.props;
		const { appsts } = this.state;
        return (
			<div>
            <div className="mainContainer">
		{appsts	== 1 && <Header /> }
		<div className="row">
			<div className="container forPadd1">	
			{this.tet()}			
					{/* <div className="privacyRhtCont">						
					<h2>Disclaimer</h2>
							<p>Bazooka is a licensed trademark of Sagenic Technologies and operates under the National lottery License of Gab Lotto Nigeria Limited.</p>
							
							<p>Bazooka makes no representation regarding guaranteeing any individual of winning any specified amount as all lotteries are games of chance.</p>						
							
							<p>Bazooka also makes no definite representation of the amount of commission made relative to the referral network as agent (referrer) income is based on the viability and activity of their network.</p>
					</div>	 */}				
			</div>
		</div>		
	</div>
	{appsts	== 1 && <Footer /> }
	</div>
        )
    };
}

function mapStateToProps(state) {
    const { gethowtoplay } = state.accountReducer;    
    return { gethowtoplay }
}
const mapActionToProps = {
	Gethowtoplay: accountActions.Gethowtoplay
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Disclaimer))