import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../action/accountAction';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withTranslation } from 'react-i18next';

class Faq extends Component {
	constructor(props) {
		super(props);
    this.ref = React.createRef();

    this.state = {		
			appsts: 1
		}
		const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

    //if(intflsts == "1"){ //web
      //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
    //}
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;
    
    //let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.Gethowtoplay(langflag,4); 
    
    setTimeout(() => {
      window.acc()  
    }, 4000);
    
	}
	componentDidMount() {
    ////let ddd='<div class="faqAccrdn"><ul id="teamList1"><li><a href="#">HOW DO I DOWNLOAD BAZOOKA LOTTO MOBILE APP?  </a><ul style="display: none;"><li><p>Visit <a class="linktext" href="#">www.bazookalotto.com </a>to automatically download the app onto your smartphone. Go to your Apps page and click on the Bazooka lotto app icon to register.</p></li></ul></li><li><a href="#">HOW DO I DEPOSIT MONEY TO PLAY?<span><i class="fa fa-angle-down"></i></span></a><ul style="display: none;"><li><p>You will need to deposit money into your player wallet before you can play 5/90 Mobile. Simply select fund wallet Option Under Fund Account Menu, enter and confirm the amount you want to deposit.</p></li></ul></li></ul></div>';     
    //let ddds=this.state.gethowtoplay;

    //let ddd='<div class="faqAccrdn">'+ddds+'</div>';
    //const node = document.createRange().createContextualFragment(ddd);
    //this.ref.current.appendChild(node);
    //window.acc();    
	};

  static getDerivedStateFromProps(props, state) {
    return {            
        gethowtoplay: props.gethowtoplay != undefined ? props.gethowtoplay : {}
    }
};

tet=()=> {
  //let ddd='<ul id="teamList1"><li><a href="#">HOW DO I DOWNLOAD BAZOOKA LOTTO MOBILE APP?</a><ul style="display: none;"><li><p>Visit <a class="linktext" href="#">www.bazookalotto.com </a>to automatically download the app onto your smartphone. Go to your Apps page and click on the Bazooka lotto app icon to register.</p></li></ul></li><li><a href="#">HOW DO I DEPOSIT MONEY TO PLAY?</a><ul style="display: none;"><li><p>You will need to deposit money into your player wallet before you can play 5/90 Mobile. Simply select fund wallet Option Under Fund Account Menu, enter and confirm the amount you want to deposit.</p></li></ul></li></ul>';
  let ddd=this.state.gethowtoplay;
   let dd= <div className='faqAccrdn' dangerouslySetInnerHTML={{__html:ddd }}></div>		
    return dd;
}

	render() {
		const { i18n } = this.props;
    const { appsts } = this.state;
		return (
      <div>
			<div className="mainContainer">
		{appsts	== 1 && <Header /> }
    <div className="row">
        <div className="container forPadd1">
          <div className="privacyCont">
            <div className="privacyLftCont">
              <ul className="contentFlowLinks">
                <li><a className="activeBrdrLft" href="#faqs_link">FAQs</a></li>
              </ul>
            </div>            
            <div className="privacyRhtCont">
              <h1 id="faqs_link">Frequently asked questions</h1>
              <p className="text-color1">Need answer? Find them here</p>
              {/* <div ref={this.ref}></div> */}
              {this.tet()}
              {/* <div className="faqAccrdn">              
                <ul id="teamList1">                
                  <li>
                    <a href="#">HOW DO I DOWNLOAD BAZOOKA LOTTO MOBILE APP?</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <p>Visit <a className="linktext" href="#">www.bazookalotto.com </a>to automatically download the app onto your smartphone. Go to your Apps page and click on the Bazooka lotto app icon to register.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">HOW DO I REGISTER?</a>
                    <ul style={{display: "none"}}>
                      <li>                        
                        <p>Click the register button on the home page, fill the form with the required details and use your referral mobile number as referral code. You then agree to the terms and conditions and submit.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">WHAT IF I FORGET MY PASSCODE?</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <p>Select the Forgot Passcode in Login screen and change the Passcode.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">HOW DO I DEPOSIT MONEY TO PLAY?</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <p>You will need to deposit money into your player wallet before you can play 5/90 Mobile. Simply select fund wallet Option Under Fund Account Menu, enter and confirm the amount you want to deposit.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">HOW DO I CHECK MY PLAYED TRANSACTIONS?</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <p>Select Account Summary under My Account Menu on left of the screen and select From Date, To Date then Press the Search button.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">HOW CAN I CLAIM MY PRIZE OF WINNING TICKETS?</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <p>Your winnings will be automatically credited to your Winnings account in My Account.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">HOW CAN I CALCULATE MY WINNINGS AND COMMISSIONS?</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <p>Allows user to view his earning from Winnings and Commissions from the Bazooka game. It also allows the user to view his wallet balance. Press the “Calculate Earnings” to view the total earnings accrued so far.</p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">EXPLANATION OF THE ABBREVIATIONS FOR CALCULATE EARNINGS</a>
                    <ul style={{display: "none"}}>
                      <li>
                        <ul className="marleft">
                          <li><p><span className="bold-textcolor text-bold">Winnings(WI) :</span>Prize Amount earned from Winning Tickets.</p></li>
                          <li><p><span className="bold-textcolor text-bold">Current Commissions(CM):</span> Commissions earned for the Current Month (CM) are earned from played games (Buy) by players in their network who have registered with his/her referrer mobile number in the First Tier, and under-linked users in the two subsequent Tiers. Calculate Overall three-tier commissions separate from your network for the Current Month Only.</p></li>
                          <li><p><span className="bold-textcolor text-bold">Available Commissions(AC):</span> Means Commissions thatare Total Redeemable (TR). Commissions will also automatically transfer from "Commission (Current Month)" to "Commissions (Total Redeemable)" at the end of each month. Only commissions in the "Total Redeemable" account can be cashed out or transferred.</p></li>
                          <li><p><span className="bold-textcolor text-bold">Cashout Wallet Total(WI+AC):</span> The "cashout wallet total" is the total of Winnings(WI) + Available Commissions</p></li>
                          <li><p><span className="bold-textcolor text-bold">Play Wallet:</span> Your balance amount in your wallet (Deposited amount in your wallet to play the game - Amount spent to play games).</p></li>
                        </ul>
                        <p className="text-bold">Wallet amount includes from the following modes to Play Wallet.</p>
                        <ul className="marleft">
                          <li><p>1.using his Credit or Debit Card.</p></li>
                          <li><p>2.Transfered to fund player wallet from the prize-winning amount.</p></li>
                          <li><p>3.Funds load to his wallet from prepaid Credit PINs obtained from Bazooka.</p></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div> */}
            </div>

          </div>		
        </div>
      </div>      
    </div> 
    {appsts	== 1 && <Footer /> }
    </div>   
		)    
	};
}

function mapStateToProps(state) {
  const { gethowtoplay } = state.accountReducer;    
  return { gethowtoplay };
}

const mapActionToProps = {
	Gethowtoplay: accountActions.Gethowtoplay
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Faq))