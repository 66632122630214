import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';
class AcccountInfo extends Component {

    constructor(props) {
        super(props);
    }
    render() {        
		const { t } = this.props;
        return (
            <section class="tabCont">
<div className="containerDetail forpadd1">
                <div class="detailsHead">
                    <h2>{t('lblWalletDetails')}</h2>
                </div>  
                <div className="walletCont">
								<div className="waltDetlli">
									<div className="waltDetlHead">
										<p>{t('lblCreditbal')}</p>
									</div>
									<span><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.balance}</span>
								</div>
								<div className="waltDetlli">
									<div className="waltDetlHead">
										<p>{t('lblCurrComm')}</p>
									</div>
									<span><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.currentcommissions}</span>
								</div>
								<div className="waltDetlli">
									<div className="waltDetlHead">
										<p>{t('lblWinnAmt')}</p>
									</div>
									<span><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.winningAmt}</span>
								</div>
								<div className="waltDetlli">
									<div className="waltDetlHead">
										<p>{t('lblAvailComm')}</p>
									</div>
									<span><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.availabeCommissions}</span>
								</div>
								<div className="waltDetlli">
									<div className="waltDetlHead">
										<p>{t('lblWinningbal')}</p>
									</div>
									<span className="chngeBg"><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.winbalance}</span>
								</div>
							</div>
                </div>
            </section>
        )
    }
}

function mapStateToProps(state) {
    const {user, appvariables } = state.loginReducer;
    return {user, appvariables }
}

const mapActionToProps = {
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AcccountInfo))