
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation  } from 'react-i18next';
class MobileApp extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    };

    render() {
		const { t } = this.props;
        return (
            <div class="mainContainer">		
		
            <div class="container-fluid">
            <div class="GameSection2">
                <div class="headerMobile">
                    <div class="hmLogo">
                        <img src="./assets/img/logo.jpg" alt="Bazooka Logo" />
                    </div>
                </div>
                <div class="InnerContainer">
                <h1>WELCOME TO Bazooka MOBILE APP</h1>
                    <div class="hmApp">
                        <a href="./assets/Mobile/MobApp/Bazooka_V100.apk"><img src="./assets/img/Appstore.png" alt="" /></a>                
                    </div>
                </div>
                <div class="HowToPlayCont">
                <h2>How to install</h2>
                <span>Step 1 :</span>
                <ul>
                    <li>1.Enable 'Unknown Sources</li>
                    <li><img src="./assets/img/step1-security.png" /></li>
                    <li>2.After you've downloaded the App, go to your phone's Settings page and tap Security or Applications (depending on your device)</li>
                    <li>3.Select the box for 'Unknown Sources' and confirm with OK.</li>
                </ul>
                <span>Step 2: Install the App</span>
                <ul>
                    <li>1.Find the download in your Notifications OR</li>
                    <li><img src="./assets/img/apkimg.jpg" /></li>
                    <li>2.Open Downloads on your device by going to My Files or Files</li>
                    <li>3.Tap on the Bazooka app file (Bazooka_V100.apk)</li>
                    <li>4.Select Install when prompted</li>
                </ul>
            </div>
            </div>
            </div>
        </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(MobileApp))