import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { alertActions } from '../../../action/alertAction';
import { withTranslation  } from 'react-i18next';
import { commonClass } from "../../../helpers/commonClass"
class KYCStatus extends Component {

	constructor(props) {
		super(props);	
		this.ChekKYC_data();
	}

	ChekKYC_data = () => {
		let intlangflag = "en-us"; //this.props.i18n.language == "en" ? 1 : 2;	
		//let _Withdrawdata = "";
		//_Withdrawdata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1~0`);
		
		let _wdwlUrl = ``;
		let _edata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~${intlangflag}~1~0$${_wdwlUrl}`);		

		let _addUrl = `${process.env.REACT_APP_PG_URL}/KYCForm/Save?KYCInfo=${_edata}`;

		if(this.props.user.kycstatus == "0"){ // 0-Not Kyc update, 1-Kyc already updated
			window.location.href = _addUrl;
		}
		else{
			if(this.props.user.kycapprvalstatus.split('-')[0] == "0")
			{
				//alert(this.props.user.kycapprvalstatus.split('-')[1]);

				window.location.href = _addUrl;				
			}
			else{				
				window.location.href = _addUrl;
			}
		}
	};
	
	render() {
        const { t } = this.props;	

        return (
			<section class="tabCont">
				<div className="accountDetls forpadd1">
				<div className="detailsHead">
				<h2>{t('lblKycstatus')}								
				</h2>
				</div>
				<div className="accountDetlsInr moneyWthDrwDtls">						
					<span>Loading...</span>						
				</div>				
				</div>

			</section>
		)
	}
}

function mapStateToProps(state) {	
	const { user , appvariables} = state.loginReducer;	
	const {serverTime } = state.commonReducer;
	return { user, serverTime, appvariables };
}

const mapActionToProps = {	
	error: alertActions.error,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(KYCStatus))