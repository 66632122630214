import { appConstants } from '../helpers/actionType';

export function alertReducer(state = { }, action) {
  switch (action.type) {
    case appConstants.SUCCESS:
      return {
        appvariables: state.appvariables,
        type: 'alert-success',
        alertcolor: "#44983d",
        alertcolor1: "#0001f1",
        alertcolor2: "#fff",
        alertTitle: "Info",
        message: action.message        
      };
    case appConstants.ERROR:
      return {
        appvariables: state.appvariables,
        type: 'alert-danger',
        alertcolor: "#44983d",
        alertcolor1: "#f12800",
        alertcolor2: "#fff",
        alertTitle: "Error",
        message: action.message
      };
    case appConstants.CLEAR:
      return { ...state,        
        message:""
      };
    case appConstants.LOADING:
      return {
        appvariables: state.appvariables,
        loading: true
      };
    case appConstants.LOADED:
      return {
        appvariables: state.appvariables,
        loading: false
      };
  
    default:
      return state
  }
}