import React, { Component } from 'react'
import { connect } from 'react-redux';
import { gameActions } from '../../action/gamesAction'
import Barcode from 'react-barcode';
import { withTranslation } from 'react-i18next';
class Common extends Component {
    constructor(props) {
        super(props);
        this.state = { counter: 1 }
    }
    PrintBetSlip = (id) => {
        window.PrintBetSlip(id);
        return;
        var divToPrint1 = document.getElementById(id);
        var newWin1 = window.open('', 'Print-Window');
        newWin1.document.open();
        let siteRoot1 = "";
        let dd = './assets/css/common.css'
        newWin1.document.write('<html><head><link rel="stylesheet" type="text/css" href="' + dd + '" /></head><body onload="window.print()">' + divToPrint1.innerHTML + '</body></html>');
        newWin1.document.close();
        setTimeout(function () { newWin1.close(); }, 30);
    };

    render() {
        const { t } = this.props;
        let flst = [];
        let _transID = "";
        let _gameID = "";
        let _gameTime = "";
        function dd(Id, GameTime, GameID) {
            _transID = Id;
            _gameTime = GameTime;
            _gameID = GameID;
        }
        function tfilte(betLst) {
            flst = [...new Set(betLst.map(item => item.Id))] // flst = [...new Set(betLst.map(item => item.GameID))]
        }
        return (
            <div>
                {/* for Other concept game bet slip */}
                {this.props.myBet && this.props.myBet.status == 0 && this.props.myBet.familyID == 100 && <div><div class="lightBox1" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
                    <div class="newCo">
                        <div class="newCoInner">
                            <div class="newCoInnerLft">
                                <img src="./assets/img/icons/Betslip.png" />
                                <span>{t('lblgameslip')}</span>
                            </div>

                            {/* <div class="newCoInnerCent" onClick={() => { this.PrintBetSlip('lottoprint') }}>
                                <img src="./assets/img/icons/Print.png" />
                                <span>Print</span>
                            </div>  */}
                            <div class="newCoInnerRgt" onClick={() => { this.props.closeBetSlip() }}>
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="newCoWrap scrollbar" id="lottoprint">
                            <div class="betLogo">
                                <img src="./assets/img/PrintLogo.jpg" />
                            </div>
                            <div class="dateAndTime1">
                                <h1>{this.props.myBet.GameName}</h1>
                                <span class="time">{this.props.myBet.TransDate}</span>
                                <span class="date">{this.props.myBet.TransTime}</span>
                            </div>
                            <div class="betSec">
                                {this.props.myBet.Ball.map((x, idx) =>
                                    <div key={'bslp_' + idx} style={{ backgroundColor: (idx % 2 == 0) ? "#55e952" : "", width: "100%", float: "left" }}>
                                        <div class="betValCont">
                                            <div class="playAmount">
                                                <span class="plyAmntTxt">{t('lblGameSelection')}</span>
                                                <span class="plyAmount">{x.no}</span>
                                            </div>
                                        </div>
                                        <div class="betValCont">
                                            <div class="playAmount">
                                                <span class="plyAmntTxt">{t('lblstakeval')}</span>
                                                <span class="plyAmount">{this.props.appvariables.currency} {x.amt} </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div class="qr">
                                    <div class="qrValCont">
                                        <span class="qrVal">{t('lbldrawno')} :</span>
                                        <span class="qrVal" style={{ textAlign: "right" }}>{this.props.myBet.DrawNo}</span>
                                        <span class="qrVal">{t('lblDrawTime')} :</span>
                                        <span class="qrVal" style={{ textAlign: "right" }}>{this.props.myBet.DrawDate} {this.props.myBet.DrawTime}</span>
                                    </div>
                                </div>
                                <div class="barCode" style={{ textAlign: "center" }}>
                                    {/* <img src="./assets/img/barCode.png" /> */}
                                    <Barcode displayValue="true" height="60px" format="CODE128" value={this.props.myBet.Id} />
                                    {/* <span class="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "16px", fontFamily: "'helvetica-condensed-bold'" }}>{this.props.myBet.Id}</span> */}
                                </div>
                                <div class="playAmount newCoGameTime">
                                    <span class="plyAmntTxt">{t('lbltotstak')}</span>
                                    <span class="plyAmount">{this.props.appvariables.currency} {this.props.myBet.TotalStakeAmount}</span>
                                </div>
                                {this.props.myBet.ticketStatus && <div class="playAmount newCoGameTime">
                                    <span class="plyAmntTxt">{t('lblStatus')}</span>
                                    <span class="plyAmount">{this.props.myBet.ticketStatus}</span>
                                </div>}
                                {this.props.myBet.buttomMsg && this.props.myBet.buttomMsg != "" && <div class="betValCont">
                                    <div class="playAmount">
                                        <span class="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "12px", fontFamily: "'helvetica-condensed-bold'" }}>{this.props.myBet.buttomMsg}</span>
                                    </div>
                                </div>}
                            </div>
                            {/* {this.props.myBet.reprint && <div class="eticketOverlay">
                                <div>{t('lblReprint')}</div>
                            </div>} */}
                        </div>
                    </div>
                </div>
                    <div class="overlayPop" style={{ display: "block" }}></div>
                </div>}

                {/* this if for QuickWin & Lotto5/90 bet slip */}
                {this.props.myBet && this.props.myBet.status == 0 && this.props.myBet.familyID == 4 && <div><div id="buyTicket" class="lightBox" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
                    <div class="newCo">
                        <div class="newCoInner">
                            <div class="newCoInnerLft">
                                {/* <img src="./assets/img/icons/Betslip.png" /> */}
                                <span>{t('lblgameslip')}</span>
                            </div>
                            {/* <div class="newCoInnerCent" onClick={() => { this.PrintBetSlip('qwinprint') }}>
                                <img src="./assets/img/icons/Print.png" />
                                <span>Print</span>
                            </div>  */}
                            <div class="newCoInnerRgt" onClick={() => { this.props.closeBetSlip() }}>
                                <i class="fa fa-times" id="ticketClose" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="newCoWrap scrollbar" id='qwinprint'>
                            <div class="betLogo">
                                <img src="./assets/img/PrintLogo.jpg" />
                            </div>
                            {/* {flst = [...new Set(this.props.myBet.betLst.map(item => item.GameID))] } */}
                            {tfilte(this.props.myBet.betLst)}
                            {/* {this.props.myBet.betLst.map(img => {if (flst.indexOf(img.GameID) === -1) {flst.push(img.GameID)}})} */}
                            {/* <div class="betSec scrollbar" style={{maxHeight:"300px",overflowY:"auto"}}> */}
                            <div class="betSec">
                                {flst.map((x1, idx1) => <div key={'bslp1_' + idx1} >
                                    {/* {this.props.myBet.betLst.filter(a => a.GameID == x1).map((x, idx) => */}
                                    {this.props.myBet.betLst.filter(a => a.Id == x1).map((x, idx) =>
                                        <div>
                                            {idx==0 &&
                                                <div class="dateAndTime1">
                                                    <h1>{x.GameName}</h1>
                                                    <span class="time">{x.GameTime}</span>
                                                </div>
                                            }
                                            <div key={'bslp_' + idx} style={{ backgroundColor: (idx % 2 == 0) ? "#55e952" : "#def0bc", width: "100%", float: "left" }}>
                                                <div class="betValCont">
                                                    <div class="playAmount">
                                                        <span class="plyAmntTxt">{t('lblGameSelection')}</span>
                                                        <span class="plyAmount">{x.BallNo}</span>
                                                    </div>
                                                </div>
                                                <div class="betValCont">
                                                    <div class="playAmount">
                                                        <span class="plyAmntTxt">{t('lblAmount')}</span>
                                                        <span class="plyAmount">{this.props.appvariables.currency} {x.StakeValue} </span>
                                                    </div>
                                                </div>
                                                {/*<div class="betValCont">
                                                <div class="playAmount">
                                                    <span class="plyAmntTxt">{t('lblEstimateWin')} (X{x.OddsValue})</span>
                                                    <span class="plyAmount">{this.props.appvariables.currency} {x.SubTotal} </span>
                                                </div>
                                    </div>*/}
                                                {/* {_transID=x.Id}
                                        {_gameTime=x.GameTime}
                                        {_gameID=x.GameID} */}
                                                {dd(x.Id, x.GameTime, x.GameID)}
                                            </div>
                                        </div>
                                    )}
                                    <div class="qr">
                                        <div class="qrValCont">
                                            <span class="qrVal">{t('lbldrawno')} :</span>
                                            <span class="qrVal" style={{ textAlign: "right" }}>{"#" + _gameID}</span>
                                            <span class="qrVal">{t('lbltktValidity')} :</span>
                                            <span class="qrVal" style={{ textAlign: "right" }}>{this.props.myBet.betLst[idx1].TktValidity}</span>
                                            <span class="qrVal">{t('lblTransDate')} :</span>
                                            <span class="qrVal" style={{ textAlign: "right" }}>{this.props.myBet.betLst[idx1].TransDateTime}</span>
                                        </div>
                                    </div>
                                    <div class="barCode" style={{ textAlign: "center" }}>
                                        {/* <img src="./assets/img/barCode.png" /> */}
                                        <Barcode displayValue="true" height="30px" format="CODE128" value={_transID} />
                                        {/* <span class="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "16px", fontFamily: "'helvetica-condensed-bold'" }}>{_transID}</span> */}
                                    </div>
                                </div>
                                )}
                            </div>
                            <div class="playAmount newCoGameTime">
                                <span class="plyAmntTxt">{t('lbltotamt')}</span>
                                <span class="plyAmount">{this.props.appvariables.currency} {this.props.myBet.betLst.reduce((a, b) => a = a + b.StakeValue, 0)}</span>
                            </div>
                            {this.props.myBet.ticketStatus && <div class="playAmount newCoGameTime">
                                <span class="plyAmntTxt">{t('lblStatus')}</span>
                                <span class="plyAmount">{this.props.myBet.ticketStatus}</span>
                            </div>}
                            {this.props.myBet.buttomMsg && this.props.myBet.buttomMsg != "" && <div class="betValCont">
                                <div class="playAmount">
                                    <span class="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "12px", fontFamily: "'helvetica-condensed-bold'" }}>{this.props.myBet.buttomMsg}</span>
                                </div>
                            </div>}
                            {/* {this.props.myBet.reprint && <div class="eticketOverlay">
                                <div>{t('lblReprint')}</div>
                            </div>} */}
                        </div>
                    </div>
                </div>
                    <div class="ticketOverlay" style={{ display: "block" }}></div>
                </div>}

                {this.props.myBet && this.props.myBet.status != 0 && <div><div class="lightBox1" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
                    <div class="newCo">
                        <div class="newCoInner">
                            <div class="newCoInnerLft">
                                <img src="./assets/img/icons/Betslip.png" />
                                <span>{t('lblgameslip')}</span>
                            </div>
                            <div class="newCoInnerRgt" onClick={() => { this.props.closeBetSlip() }}>
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="newCoWrap">
                            <div class="betSec">
                                <div class="betValCont">
                                    <div class="playAmount">
                                        <span class="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "16px", fontFamily: "'helvetica-condensed-bold'" }}>{this.props.myBet.msg}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="overlayPop" style={{ display: "block" }}></div>
                </div>
                }

            </div>
        )
    };
}

function mapStateToProps(state) {
    const { myBet } = state.gameReducer;
    const { appvariables } = state.loginReducer;
    return { myBet, appvariables };
}
const mapActionToProps = {
    closeBetSlip: gameActions.closeBetSlip
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Common))