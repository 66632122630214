import React, { Component } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux';
import Left from "./lottoLeft";
import Right from "./lottoRight";
import { gameActions } from '../../../action/gamesAction';
import { lottoActions } from '../../../action/lottoAction';
import Countdown, { zeroPad } from 'react-countdown';
import Header from "../../Header/Header";
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';

class Lotto extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cartItems: [],
			currentRunningDraw: {},
			currentFinishingDraw: {},
			coundownStart: 0,
			coundTime: 18900000,
			currentDraw: {},
			currentAvailableDraws: [],
			currentCartItem: { id: 0, balls: [], A_balls: [], OptionID:0, ClassID:"", StakeValue:0,SubTotal:0, amount: 0, ballCount: 0, A_ballCount: 0, combination: 0, total: 0, gameName: "", drawDateTime: "", GameGroupID:0, drawID: 0, gameID: 0 },

			ballStartNumber: 1,
			ballEndNumber: 0,
			QPBallCount: 0,
			random: [],
			showSingleMsg: false,
			_serverTimeDiff: 0,
			allGameClosed: 0,
			isViewAnimation: false,
			vtIndex : 0,
			minChooseNum : 1, //this.state.currentGamesFamily.minSelect,
			maxChooseNum : 15, //this.state.currentGamesFamily.maxSelect,
			maxChooseNum1 : 10,
			ntIndex : 0,
			lIntSWinAmnt : "",
			betAmount : 0,
			Target : 0,
			nChoseClasses : "",
			nClassesOption : "",
		};
		this.my_componentWillUnmount();
		let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	
		props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id,intlangflag);
		//this.props.GetLatestResult(this.props.currentGamesFamily.id,this.props.i18n.language);
	
	}
	
setBetAmountNew = (dfNum, againstNum,GameIdenfy) => {

	var betAmount = 0;
	var totalTickets = 0;	
	var standNum = dfNum;
	dfNum = dfNum + againstNum;
	var nxT = 0;
	 
	var ticketRate = parseInt(document.getElementById("stakeNum").value);
	if(0 == GameIdenfy) {betAmount = ticketRate; this.state.Target = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));}
	else if(1 == GameIdenfy) {
		nxT = +this.state.minChooseNum;
		totalTickets = this.factorial(+dfNum) / (this.factorial(nxT) * this.factorial(+(dfNum - nxT)));
		betAmount = totalTickets * ticketRate;

		if (parseInt(totalTickets) != 0) {

            var WinnCalcAmt1 = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));            

            if (this.state.minChooseNum == "2" || this.state.minChooseNum == "3") { // P2, P3
                this.state.Target = (parseInt(WinnCalcAmt1) * parseInt(10));
            }
            else if (this.state.minChooseNum == "4") { //P4
                this.state.Target = (parseInt(WinnCalcAmt1) * parseInt(5));
            }
            else if (this.state.minChooseNum == "5") { //P5
                this.state.Target = (parseInt(WinnCalcAmt1) * parseInt(1));
            }            
        }
        else {
            this.state.Target = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));            
        }
	}
	 else if(2 == GameIdenfy) { 
		dfNum = againstNum;
	    0 == dfNum && (dfNum = 90 - standNum);
	    nxT = +(this.state.minChooseNum - standNum);
	    totalTickets = this.factorial(+dfNum) / (this.factorial(nxT) * this.factorial(+(dfNum - nxT)));
		betAmount = Math.round(totalTickets * ticketRate);

		if (parseInt(totalTickets) != 0) {

            var WinnCalcAmt1 = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));            
			this.state.Target = (parseInt(WinnCalcAmt1) * parseInt(totalTickets));
		}
		else {
            this.state.Target = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));            
        }
	}
else if(3 == GameIdenfy) {totalTickets = standNum * againstNum;
		 betAmount = totalTickets * ticketRate;
		 if (parseInt(totalTickets) != 0) {
			var WinnCalcAmt1 = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));
			this.state.Target = (parseInt(WinnCalcAmt1) * parseInt(totalTickets));
		 }
		 else{
			this.state.Target = (parseInt(this.state.lIntSWinAmnt) * parseInt(ticketRate));            
		 }
		}

		if(0 > betAmount)
		{betAmount = 0}		 
	return betAmount; 
};

	//calculateLottoPermCombinations = (classType, ballscnt) => {
	//	try {
	//		var i;
	//		var Numerator = 1;
	//		var Denominator = 1;
	//		var NetResult, Perm;
	//		Perm = classType;
	//		for (i = 0; i < Perm; i++) {
	//			Numerator = Numerator * ballscnt;
	//			ballscnt = ballscnt - 1;
	//		}
	//		do {
	//			Denominator = Denominator * Perm;
	//			Perm = Perm - 1;
	//		} while (Perm != 0);
	//		NetResult = Numerator / Denominator;
	//		return NetResult;
	//	} catch (ex) {
	//	}
	//	return 0;
	//};

	factorial = (num) => {
		if (0 > num) return -1
		if (0 == num) return 1
		for (var tmp = num; num-- > 2;) tmp *= num
		return tmp
	};

	checkBallsSelct = () => {
		let cho_Min_val = this.state.minChooseNum;
		let cho_max_val = this.state.maxChooseNum;

		if (this.state.ntIndex == 0) {
			alert("Please ensure you select " + cho_max_val + " numbers");			
		}
		else if (this.state.ntIndex == 1) {
			alert("Please select minimum of " + cho_Min_val + " numbers and maximum of " + cho_max_val);			
		}
		else if (this.state.ntIndex == 2) {
			alert("Please select a minimum of " + cho_Min_val + " number in each card");						
		}
		else if (this.state.ntIndex == 3) {
			alert("Please select a minimum of " + cho_Min_val + " numbers in each card");						
		}
	};

	checkBallCountAndCalculateCombination = (Snum, selectedCount, cntAginst, b_id) => {
		let default_Min_val = this.state.minChooseNum; //this.state.currentGamesFamily.minSelect;
		let default_max_val = this.state.maxChooseNum; //this.state.currentGamesFamily.maxSelect;
		let default_val = default_Min_val;
		let resultStr = "";

		if (this.state.ntIndex == 2) {	
			if (Snum == 1) {      
            if (selectedCount < (this.state.minChooseNum)) {
			if(selectedCount == 0){
				
				this.state.currentCartItem.balls = [];				
				this.state.currentCartItem.ballCount = 0;								
			}	
				this.state.currentCartItem.amount = 0;
				this.state.currentCartItem.A_balls = [];				
				this.state.currentCartItem.A_ballCount = 0;								
				this.state.currentCartItem.total = 0;
				this.state.currentCartItem.combination = 0;
				this.state.betAmount=0;
				this.state.Target=0;
				document.getElementById("chkAllLottery_2").checked = false;			    

				return resultStr ="3~0";
            } else {                
                   return resultStr = "0~Select " + (this.state.minChooseNum - 1) + " balls in Banker";                
            }
		}

		if (Snum == 2) {
			//var isall = document.getElementById("chkAllLottery_2").checked;
			//if(isall == false)
			//{
            	if (cntAginst <= (this.state.maxChooseNum)) {
            	} else {                
					return resultStr = "0~Maximum balls you reached";                
            	}
			//}
		}
        } else if (this.state.ntIndex == 3) { 
			if (Snum == 1) {           
            if (selectedCount <= (this.state.maxChooseNum1)) {	
				if(selectedCount == 0){
					this.state.currentCartItem.balls = [];				
					this.state.currentCartItem.ballCount = 0;
					this.state.currentCartItem.amount = 0;				
				this.state.currentCartItem.A_balls = [];				
				this.state.currentCartItem.A_ballCount = 0;
				this.state.currentCartItem.total = 0;
				this.state.currentCartItem.combination = 0;
				this.state.betAmount=0;
				this.state.Target=0;
				}
				else{
					this.state.currentCartItem.amount = 0;									
					this.state.currentCartItem.total = 0;
					this.state.currentCartItem.combination = 0;
					this.state.betAmount=0;
					this.state.Target=0;
				}				
	
					return resultStr ="3~0";			
            } else {                
					return resultStr = "0~Select Maximum " + this.state.maxChooseNum1 + " balls in Stand";                
            }
		}

		if (Snum == 2) {
            if (cntAginst <= (this.state.maxChooseNum1)) {
            } else {               
					return resultStr = "0~Maximum balls you reached";                
            }
		}
        }

		if(this.state.ntIndex == 2 || this.state.ntIndex == 3)
		{
			if (Snum == 2) {
				for (var i in this.state.currentCartItem.balls) {
					if (this.state.currentCartItem.balls[i].id == b_id) { return "2~0"; }
				}
			}
			else{
				for (var j in this.state.currentCartItem.A_balls) {
					if (this.state.currentCartItem.A_balls[j].id == b_id) { return "2~0"; }
				}
			}			
		}
		
		if(this.state.ntIndex == 2 || this.state.ntIndex == 3){

			if(selectedCount == 0 || cntAginst == 0){
				this.state.betAmount = 0;
				this.state.Target = 0;
				resultStr = "3~0";
			}
			else{
			resultStr = "1~" + this.setBetAmountNew(selectedCount, cntAginst, this.state.ntIndex);
			}

			//resultStr = "1~" + this.setBetAmountNew(selectedCount, cntAginst, this.state.ntIndex);
		}
		else{
			if(selectedCount == 0){
				this.state.betAmount = 0;
				this.state.Target = 0;
				resultStr = "3~0";
			}
			else{			

			if (default_Min_val <= selectedCount && default_max_val >= selectedCount) {
				resultStr = "1~" + this.setBetAmountNew(selectedCount, 0, this.state.ntIndex); //resultStr = "1~" + this.calculateLottoPermCombinations(default_val, selectedCount);
			}
			else {
				if (default_max_val < selectedCount) {
					resultStr = "0~"+this.props.t('lblMaximumselection')+" : " + default_max_val;
				}
				else {
					resultStr = "1~0";
				}
			}
			}
			
		//if (default_Min_val <= selectedCount && default_max_val >= selectedCount) {
		//	resultStr = "1~" + this.calculateLottoPermCombinations(default_val, selectedCount);
		//}
		//else {
		//	if (default_max_val < selectedCount) {
		//		resultStr = "0~"+this.props.t('lblMaximumselection')+" : " + default_max_val;
		///	}
		//	else {
		//		resultStr = "1~0";
		//	}
		//}
	}
		return resultStr;
	};

	static getDerivedStateFromProps(props, state) {
		return {
			currentAvailableDraws: props.currentAvailableDraws,
			cartItems: props.cartItems,
			currentDraw: props.currentDraw,
			currentGamesFamily: props.currentGamesFamily,
			latestResult:props.latestResult
		};
	};

	call_CalBetAmt = () => {
		let betamtval = "";
		let cnt_Std = this.state.currentCartItem.ballCount;		
			if(cnt_Std != 0){ 		
		if(this.state.ntIndex == 2 || this.state.ntIndex == 3){
		let cnt_A = this.state.currentCartItem.A_ballCount
		
		if(cnt_A != 0){ 		
			betamtval = this.setBetAmountNew(cnt_Std, cnt_A, this.state.ntIndex);
		}		
		}
		else{
			betamtval = this.setBetAmountNew(cnt_Std, 0, this.state.ntIndex);
		}
		}

		let objLst1 = this.state.currentCartItem;

objLst1.amount = parseInt(document.getElementById("stakeNum").value);
objLst1.total = parseInt(betamtval);

this.state.betAmount = objLst1.total;

		this.setState({
			currentCartItem: objLst1
		});		

		};

	Load_stakeamt = () => {
		
		let stkamtselt = [];
		let stkamtopt = [];
		if (this.state.currentGamesFamily.StakeEnter != undefined) {							

			for (let i = 0; i < this.state.currentGamesFamily.StakeEnter.split('-').length; i++) {
				if(i == 0){
					stkamtopt.push(<option selected={`selected`} Value={this.state.currentGamesFamily.StakeEnter.split('-')[i]}>{this.state.currentGamesFamily.StakeEnter.split('-')[i]}</option>);
				}
				else{
					stkamtopt.push(<option Value={this.state.currentGamesFamily.StakeEnter.split('-')[i]}>{this.state.currentGamesFamily.StakeEnter.split('-')[i]}</option>);
				}				
		    }			

			stkamtselt.push(<select name={`stakeNum`} id={`stakeNum`} onChange={()=>{this.call_CalBetAmt() }}>{stkamtopt}</select>);
			stkamtopt = [];
		}
		else {
			stkamtselt.push(<select>No stake</select>);
		}
		return stkamtselt;
	};

	OptionEnable = () => {
		//S2,50000,NAP 2,50^S3,50000,NAP 3,50^S4,50000,NAP 4,50^S5,50000,NAP 5,50$P2,50000,PEM 2,50^P3,50000,PEM 3,50^P4,50000,PEM 4,50^P5,50000,PEM 5,50$B2,50000,Bank 2,50^B3,50000,Bank 3,50^B4,50000,Bank 4,50^B5,50000,Bank 5,50$A2,50000,Against,50$
		let ballLst = [];
		let ballLst1 = [];
		if (this.state.currentGamesFamily.Option_enable != undefined) {							
			for (let i = 0; i < this.state.currentGamesFamily.Option_enable.split('$').length - 1; i++) {

				for (let m = 0; m < this.state.currentGamesFamily.Option_enable.split('$')[i].split('^').length; m++) {
					ballLst1.push(<option Value={this.state.currentGamesFamily.Option_enable.split('$')[i].split('^')[m].split(',')[0]}>{this.state.currentGamesFamily.Option_enable.split('$')[i].split('^')[m].split(',')[2]}</option>);				
				}	

				if(i == 0){
					ballLst.push(<select id={`SelectOption${i+1}`} onChange={()=>{this.SelectIndexBox() }}>{ballLst1}</select>);
				}
				else{
					ballLst.push(<select id={`SelectOption${i+1}`} onChange={()=>{this.SelectIndexBox() }} style={{ display: 'none'}}>{ballLst1}</select>);
				}
				
				ballLst1 = [];
		    }			
		}
		else {
			ballLst.push(<select>No Option</select>);
		}
		return ballLst;
	};

	createBall = (Snum) => {
		let ballLst = [];
		if (this.state.currentGamesFamily != undefined) {
			for (let i = this.state.ballStartNumber; i < this.state.currentGamesFamily.ball + this.state.ballStartNumber; i++) {
				let b_i = ("0" + i).slice(-2);
				if(Snum == 1){
					ballLst.push(<div id={`mc_${Snum}_${b_i}`} onClick={() => { this.selectBall(Snum, b_i) }} className={`regularBalls ${this.state.currentCartItem.balls.filter(x => x.id == b_i).length > 0 ? "ActiveBalls" : ""}`}>{b_i}</div>);
				}
				else if(Snum == 2){
					ballLst.push(<div id={`mc_${Snum}_${b_i}`} onClick={() => { this.selectBall(Snum, b_i) }} className={`regularBalls ${this.state.currentCartItem.A_balls.filter(x => x.id == b_i).length > 0 ? "ActiveBalls" : ""}`}>{b_i}</div>);					
				}
			}
			this.state.ballEndNumber = this.state.currentGamesFamily.ball + (-1 + this.state.ballStartNumber);			
		}
		else {
			return <div style={{ color: "#000" }}>{this.props.t('lblDrawClosed')}</div>;
		}
		return ballLst;
	};

	showSingleMsg = false;
	selectBall = (Snum, b_id) => {
		if (this.state.currentDraw == null || Object.keys(this.state.currentDraw).length == 0) {
			 this.state.showSingleMsg = true; alert(this.props.t('lblSelectDraw')); return; 
			}
			let obj = "";
			let cnt = 0; let cntAginst = 0;
		if(Snum == 1){
			obj = this.state.currentCartItem.balls.filter(x => x.id == b_id);

		if (obj.length <= 0) {
			cnt = this.state.currentCartItem.ballCount + 1;
		}
		else {
			cnt = this.state.currentCartItem.ballCount - 1;
		if(document.getElementById("chkAllLottery_2").checked == true){
			this.state.currentCartItem.A_balls= []; 
			this.state.currentCartItem.StakeValue = 0;
			this.state.currentCartItem.SubTotal=0;
			this.state.currentCartItem.amount= 0;
			this.state.currentCartItem.A_ballCount= 0;
			this.state.currentCartItem.combination= 0;
			this.state.currentCartItem.total= 0;			
			this.state.betAmount=0;
			this.state.Target=0;
		}
		document.getElementById("chkAllLottery_2").checked = false;
		}

		cntAginst = this.state.currentCartItem.A_ballCount
} 
else if(Snum == 2){

	cnt = this.state.currentCartItem.ballCount;

	if(cnt == 0){ 
		this.state.currentCartItem.A_balls= []; 
			this.state.currentCartItem.StakeValue = 0;
			this.state.currentCartItem.SubTotal=0;
			this.state.currentCartItem.amount= 0;
			this.state.currentCartItem.A_ballCount= 0;
			this.state.currentCartItem.combination= 0;
			this.state.currentCartItem.total= 0;			
			this.state.betAmount=0;
			this.state.Target=0;
		alert("Select Numbers first in Banker ball"); return;
	}

	obj = this.state.currentCartItem.A_balls.filter(x => x.id == b_id);

	if (obj.length <= 0) {
		cntAginst = this.state.currentCartItem.A_ballCount + 1;
	}
	else {
		cntAginst = this.state.currentCartItem.A_ballCount - 1;
	}
}
		var checkResult = this.checkBallCountAndCalculateCombination(Snum, cnt, cntAginst, b_id);
		let arrResult = checkResult.split('~');
		if (arrResult[0] == '0') { this.state.showSingleMsg = true; alert(arrResult[1]); return; } else if (arrResult[0] == '2'){return;} 

		let objLst = this.state.currentCartItem;
		if (obj.length > 0) {		
			if(Snum == 1){
				objLst.ballCount--;
			objLst.balls = this.state.currentCartItem.balls.filter(x => x.id !== b_id);
			}
			else if(Snum == 2){
				objLst.A_ballCount--;
				objLst.A_balls = this.state.currentCartItem.A_balls.filter(x => x.id !== b_id);
				}
		}
		else {		
			objLst.gameName = this.state.currentDraw.LotName;
			objLst.drawID = this.state.currentDraw.DrawID;
			objLst.GameGroupID = this.state.currentDraw.GameGroupId;
			objLst.gameID = this.state.currentDraw.GameId;
			objLst.drawDateTime = this.state.currentDraw.DrawDate + " " + this.state.currentDraw.DrawTime			
			objLst.amount = parseInt(document.getElementById("stakeNum").value); //this.state.currentDraw.RateInPs;
			objLst.ClassID =  this.state.nClassesOption;

			if(Snum == 1){
				objLst.ballCount++;
			objLst.balls.push({ id: b_id });
			}
			else if(Snum == 2){
				objLst.A_ballCount++;
				objLst.A_balls.push({ id: b_id });
				}
		}

		//objLst.combination = parseInt(arrResult[1]);
		//objLst.total = parseInt(document.getElementById("stakeNum").value) * objLst.combination; //objLst.total = this.state.currentDraw.RateInPs * objLst.combination;						

		objLst.total = parseInt(arrResult[1]);

		if(objLst.total == 0){
			this.state.Target=0;
		}

		this.state.betAmount = objLst.total;

		this.setState({
			currentCartItem: objLst
		});
	};

	selectDraw = (_draw) => {
		if($(".drawTabs").css('display') != 'none'){$('.lft').slideToggle();}
		
		$(".GameContent").parent().removeClass("actiVeBg");
		$('.ghanaGameBg' + _draw.iny + '').addClass('actiVeBg');
		$('.borColor' + _draw.iny + '').addClass('actiVeBg');

		this.props.SetCurrentDraw(_draw);
	};

	selectRunningDraw = () => {
		if (this.state.coundownStart != undefined && this.state.coundownStart == 0) {

			//if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			if (this.state.currentFinishingDraw != undefined) {
				//if (this.state.currentFinishingDraw.DrawID == undefined || this.state.currentRunningDraw.DrawID == undefined || this.state.currentFinishingDraw.DrawID !=this.state.currentRunningDraw.DrawID) {
				let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
				if (lst.length > 0 || this.state.currentAvailableDraws.length <= 0) {
					let _draw = this.state.currentAvailableDraws[0];
					//let _draw = lst[0];
					this.setState({
						currentRunningDraw: _draw,
					})
					this.state.currentRunningDraw = _draw;
					this.selectDraw(_draw);
				}
				else {
					return;
				}
			}

			// if (this.state.currentFinishingDraw != undefined && this.state.currentAvailableDraws.length > 0) {
			// 	let lst = this.state.currentAvailableDraws.filter(a => a.DrawID == this.state.currentFinishingDraw.DrawID);
			// 	if (lst.length > 0) {
			// 		return;
			// 	}
			// }

			let _draw = this.state.currentAvailableDraws[0];
			if (_draw == undefined) { this.state.coundownStart = 1; return; }
			try {
				let sTime = undefined; //new Date(Date.now() - this.state.coundTime);
				if (_draw != undefined) {
					var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
					let ss = new Date(_draw.DrawDateTime.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
					sTime = new Date(ss.setSeconds(ss.getSeconds() - 60));
				}
				let dStr = ("0" + sTime.getDate()).slice(-2) + "/" + ("0" + (sTime.getMonth() + 1)).slice(-2) + "/" + sTime.getFullYear() + " " + sTime.getHours() + ":" + sTime.getMinutes() + ":" + sTime.getSeconds();
				window.timer = null;
				this.state.coundownStart = window.startDraw(dStr, this.RefreshAfterGameClose);
			}
			catch (ex) {
				alert(ex);
			}
		}
	};

	componentDidUpdate(previousProps, previousState) {
		//if ((this.state.currentRunningDraw ==null || Object.keys(this.state.currentRunningDraw).length == 0) && this.state.currentAvailableDraws.length>0) {
		//if ((this.state.currentDraw ==null || Object.keys(this.state.currentDraw).length == 0) && (previousState.currentDraw ==null && (this.state.currentDraw==null || this.state.currentAvailableDraws.length>0))) {
		if (this.state.currentDraw != null && Object.keys(this.state.currentDraw).length != 0) {
			this.state.allGameClosed = 0;
		}
		if (this.state.currentAvailableDraws.length > 0 && ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length <= 0) || this.state.currentAvailableDraws[0].DrawNo != this.state.currentRunningDraw.DrawNo))
		 //if ((this.state.currentRunningDraw == null || Object.keys(this.state.currentRunningDraw).length == 0) && (this.state.allGameClosed == 0)) 
		 {
			if (this.state.currentAvailableDraws == undefined || this.state.currentAvailableDraws.length <= 0) {
				 this.state.allGameClosed = 1 
				}
			let _draw = this.state.currentAvailableDraws ? this.state.currentAvailableDraws[0] : null;
			this.selectDraw(_draw);
			this.state.currentRunningDraw = _draw;
			this.setState({ currentRunningDraw: _draw });
		}
	};

	componentDidMount() {
		//window.documentReadyjQuery();
		
		window.addEventListener('resize', window.documentReadyResize);
		this.SelectIndexBox();
	};
	my_componentWillUnmount=()=> {
		this.props.ClearCart();
		this.setState({
			currentRunningDraw: null
		})
		this.props.ClearAvailableGames();
	}

	RefreshAfterGameClose = () => {
		//alert("coundown finish");
		this.state.coundownStart = 0;
		this.state._serverTimeDiff = 0;
		this.state.currentFinishingDraw = this.state.currentRunningDraw;

		this.props.RemoveClosedDrawbetFromCart(this.state.currentFinishingDraw.DrawID);
		this.state.currentRunningDraw = {};
		let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.GameAvailableGames(this.props.user.mobileNo, this.props.currentGamesFamily.id,intlangflag);
		this.props.GetLatestResult(this.props.currentGamesFamily.id,this.props.i18n.language);
	};

	addToCart = () => {	
		
		if (this.props.user==undefined || this.props.user.mobileNo == "0") {
			alert(this.props.t('lblLoginValidation'));
			return;
		}

		if (this.state.betAmount == 0) { 
			this.checkBallsSelct(); //alert(this.props.t('lblCompleteGame')); 
			return; 
		}

		let totstake=this.state.betAmount;
		let minTotStake=50;
		let maxTotStake=50000;
		if (this.state.currentGamesFamily != undefined) {			 
		     maxTotStake=this.state.currentGamesFamily.maxStakePerTrans;
		}
		if(totstake<minTotStake) //50
		{
			alert(this.props.t('lblMinimumstakeAmt')+" : "+minTotStake);
			return;
		}
		else if(totstake>maxTotStake)//50000
		{
			alert(this.props.t('lblMaximumstakeAmt')+" : "+maxTotStake);
			return;
		}

		//if(this.state.ntIndex == 0 || this.state.ntIndex == 1){
		//	if (this.state.currentCartItem.combination <= 0) { alert(this.props.t('lblCompleteGame')); return; }
		//}
		let newid = 1;
		if (this.state.cartItems.length > 0) {
			newid = this.state.cartItems.reduce((max, b) => Math.max(max, b.id), this.state.cartItems[0].id);
		}
		this.state.currentCartItem.id = newid + 1;
		this.props.AddToCart(this.state.currentCartItem);
		this.clearAfterAddToCart();
	};
	viewAnimationResult = (flog) => {
		if (flog == 1 && this.state.isViewAnimation == true) {
			return;
		}
		this.setState({ isViewAnimation: !this.state.isViewAnimation });
	}
	clearAfterAddToCart = () => {
		document.getElementById("chkAllLottery_2").checked = false;
		this.setState({			
			currentCartItem: { id: 0, balls: [], A_balls: [], OptionID: 0, ClassID: "", StakeValue: 0, amount: 0, ballCount: 0, A_ballCount:0, combination: 0, total: 0, gameName: "", drawDateTime: "", GameGroupID:0, drawID: 0, gameID: 0 },
			random: [],
			QPBallCount: 0,
			betAmount:0,
			Target:0
		});
	};

	clearCart = () => {
		this.props.ClearCart();
		this.setState({
			random: [],
			QPBallCount: 0
		});
	};

	SelectAllLAgaintsLottery = () => {
		if (this.state.currentCartItem.A_balls.length == this.state.currentGamesFamily.maxSelect) {			
			this.state.currentCartItem = { A_balls: [], StakeValue:0,SubTotal:0, amount: 0, A_ballCount: 0, combination: 0, total: 0};
			this.clearAfterAddToCart();
		}

		if(document.getElementById("chkAllLottery_2").checked == false){
			this.state.currentCartItem.A_balls= []; 
			this.state.currentCartItem.StakeValue = 0;
			this.state.currentCartItem.SubTotal=0;
			this.state.currentCartItem.amount= 0;
			this.state.currentCartItem.A_ballCount= 0;
			this.state.currentCartItem.combination= 0;
			this.state.currentCartItem.total= 0;
			document.getElementById("chkAllLottery_2").checked = false;
			this.state.betAmount=0;
			this.state.Target=0;
			return;
		}

		if(this.state.currentCartItem.balls.length > 0){
		this.state.random = this.state.currentCartItem.balls.map(a => a.id);
		//this.state.QPBallCount = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		//if (this.state.QPBallCount <= 0) {
		//	this.state.QPBallCount = this.state.currentGamesFamily.maxSelect;
		//}

		let BalRemngsltcnt = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		if (BalRemngsltcnt <= 0) {
			BalRemngsltcnt = this.state.currentGamesFamily.maxSelect;
		}

		var index = -1;
		var intBnkstatus = 0;
		//for (var k = this.state.ballStartNumber; k <= this.state.currentGamesFamily.maxSelect; k++) {
		for (var k = this.state.ballStartNumber; k <= BalRemngsltcnt; k++) {			

		  //MYLOOP: do {
			index = this.generateRandomInteger();		

			 let _bal = index; //let _bal = k; 
			_bal = ("0" + _bal).slice(-2);

			for (var i in this.state.currentCartItem.balls) {
				if (this.state.currentCartItem.balls[i].id == _bal) 
				{
					intBnkstatus=1;					
					break;
				}
				else{intBnkstatus=0;}				
			}		
			
			if(intBnkstatus == 0){this.selectBall(2,_bal);}

			//if(intBnkstatus == 1){ k--; continue MYLOOP;}
			
			if (this.state.showSingleMsg == true) {
				this.state.showSingleMsg = false;
				//return;
			}

		//} while (0)
		}
	}
	else{
		document.getElementById("chkAllLottery_2").checked = false;
		alert(this.props.t('lblChkallAlert')); return;
	}
	};

	quickPick = () => {
		if (this.state.currentCartItem.balls.length == this.state.currentGamesFamily.maxSelect) {
			this.state.currentCartItem = { id: 0, balls: [], amount: 0, ballCount: 0, combination: 0, total: 0, gameName: "", drawDateTime: "", GameGroupID:0, drawID: 0, gameID: 0 };			
			this.clearAfterAddToCart();
		}
		this.state.random = this.state.currentCartItem.balls.map(a => a.id);
		this.state.QPBallCount = this.state.currentGamesFamily.maxSelect - this.state.random.length;
		if (this.state.QPBallCount <= 0) {
			this.state.QPBallCount = this.state.currentGamesFamily.maxSelect;
		}
		var index = -1;
		for (var k = this.state.ballStartNumber; k <= this.state.QPBallCount; k++) {
			index = this.generateRandomInteger();
			let _bal = index
			this.selectBall(_bal);
			if (this.state.showSingleMsg == true) {
				this.state.showSingleMsg = false;
				return;
			}
		}
	};

	generateRandomInteger = () => {
		var min = this.state.ballStartNumber;
		var max = this.state.ballEndNumber;
		//if (this.state.random.length >= this.state.QPBallCount) {
			//this.state.random = [];
		//}
		for (var i = min; i <= max; i++) {
			var temp = Math.floor(max - Math.random() * (max - min));
			temp = ("0" + temp).slice(-2);

			let skipRept = this.state.currentCartItem.balls.map(a => a.id);
			if(skipRept.indexOf(temp) == -1){
				if (this.state.random.indexOf(temp) == -1) {
					this.state.random.push(temp);
					return temp;
				}
			}
			else{
				i--;
			}			
		}
	};

	removeFromCart = (cid) => {
		this.props.RemoveFromCart(cid);
	};

	countdownCompleted = () => {
		this.RefreshAfterGameClose();
	};

	getGameTime = () => {
		let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
		if (_date != undefined && _date != "") {
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
			//console.log("getGameTime : " + sTime);
			//console.log("DateNow : " + Date.now());
			return sTime;
		}
		else {
			return 0;
		}
	};

	// Renderer callback with condition
	renderer = ({ total, days, hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state			
			return <span>Draw Closed!</span>;
		} else {
			// Render a countdown
			return <span>{zeroPad(total)}:{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
		}
	};

	getServerTime = () => {
		if (this.state._serverTimeDiff != 0 && this.state.currentRunningDraw != null && Object.keys(this.state.currentRunningDraw).length > 0) {
			//return this.state._serverTimeDiff+Date.now()+1000;
			//console.log("getServerTime : " + this.state._serverTimeDiff);
			return this.state._serverTimeDiff = (this.state._serverTimeDiff + 1000);
		}
		else {
			let _date = this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : "";
			if (_date != undefined && _date != "") {
				var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
				let ss = new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
				var sTime = (new Date(ss.setSeconds(ss.getSeconds() - 10)).getTime());
				//this.state._serverTimeDiff=Date.now()-sTime;
				this.state._serverTimeDiff = sTime;
				//console.log("getServerTime : " + sTime);
				//console.log("DateNow : " + Date.now());
				return sTime;

			}
			else {
				return 0;
			}
		}
	};

	reCount = (rundraw) => {
		//return <span class="DropCnt" data-pid={this.state.currentDraw ? this.state.currentDraw.DrawID : ""} data-countdown={this.state.currentDraw ? this.state.currentDraw.DrawDateTime : ""}></span>;
		//return <span class="DropCnt" data-pid={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawID : ""} data-countdown={this.state.currentRunningDraw ? this.state.currentRunningDraw.DrawDateTime : ""}></span>;		
		//return <span><Countdown now={Date.now} date={Date.now() + 100000} onTick={this.rendererTick()} /> </span>;

		if (this.state.currentDraw==null || rundraw== null || Object.keys(rundraw).length == 0) {

			return <span></span>;
		}
		else {
			this.state.coundownStart = 1;
			return <span  key={rundraw.DrawID}><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime()} onComplete={this.countdownCompleted} renderer={({ days, hours, minutes, seconds, completed }) => { return (completed ? <div>{this.props.t('lblDrawClosed')}</div> : <div>{this.props.t('lblnextdraw')} {zeroPad((days * 24) + hours)}h:{zeroPad(minutes)}m:{zeroPad(seconds)}s</div>) }} /> </span>;
		}
	};
	EnableLottoTabPad = (obj) => {	
		this.clearAfterAddToCart();
		//var $ = jQuery.noConflict();
		$('#SelectOption1').css('display', 'none');
		$('#SelectOption2').css('display', 'none');
		$('#SelectOption3').css('display', 'none');
		$('#SelectOption4').css('display', 'none');
	
		//var option = $(obj).find('option:selected');		
	
		//if ($(option).val() == "--Select--") {
		//	return true;
		//}
		
		var elemId = obj.target.selectedOptions[0].id;
		
		var subID = 1;
		$('#BtnGroup').hide();
		if (elemId == 1) {
			subID = 1;
		} else if (elemId == 2) {
			subID = 2;
		} else if (elemId == 3) {
			subID = 3;			
		} else if (elemId == 4) {
			subID = 4;
			//$('#BtnGroup').show();
		}
		
		$('#SelectOption' + elemId + '').css('display', 'table');	
	};

	SelectClassesBox = () => {
		//$("#MaxWinCalc").text("Target : ₦ " + 00);
		this.clearAfterAddToCart();
		$('#SelectOption1').css('display', 'none');
		$('#SelectOption2').css('display', 'none');
		$('#SelectOption3').css('display', 'none');
		$('#SelectOption4').css('display', 'none');

		var num
		num = document.getElementById("selClasses").value + "";
		this.state.nChoseClasses = num
		var nTName = document.getElementById("selClasses")
		/*for (let i = 1; i <= 4; i++) document.getElementById("SelectOption" + i).style.display = "none"
		this.state.ntIndex = nTName.selectedIndex; if(4 > this.state.ntIndex)  {document.getElementById("SelectOption" + +(+this.state.ntIndex + 1)).style.display = "table"; document.getElementById("optionsSelect").style.display = "table"} else{document.getElementById("optionsSelect").style.display = "none";} if(2 == this.state.ntIndex || 3 == this.state.ntIndex) { document.getElementById("div2").style.display = "table"} else { document.getElementById("div2").style.display = "none";} if(2 == this.state.ntIndex) {document.getElementById("divAll").style.display = "table"} else {document.getElementById("divAll").style.display = "none"} this.SelectIndexBox()		*/

		for (let i = 1; i <= 4; i++) $('#SelectOption' + i).css('display', 'none');
		this.state.ntIndex = nTName.selectedIndex; if(4 > this.state.ntIndex)  {$('#SelectOption' + (this.state.ntIndex + 1)).css('display', 'table'); $("#optionsSelect").css('display', 'table')} else{$("#optionsSelect").css('display', 'none');} if(2 == this.state.ntIndex || 3 == this.state.ntIndex) { $("#div2").css('display', 'table')} else { $("#div2").css('display', 'none');} if(2 == this.state.ntIndex) {$("#divAll").css('display', 'table')} else {$("#divAll").css('display', 'none')} this.SelectIndexBox()		
	};

	SelectIndexBox = () => {	
		var num
		//$("#MaxWinCalc").text("Target : ₦ " + 00);
		try {
			num = document.getElementById("SelectOption" + +(+ this.state.ntIndex + 1)).value + "";
			this.state.nClassesOption = num
			var nTName = document.getElementById("SelectOption" + +(+ this.state.ntIndex + 1))
			this.state.vtIndex = nTName.selectedIndex
		} catch (e) { }
	
		if (this.clearAfterAddToCart(), 0 == this.state.ntIndex) {
			for (var spantkt1name = document.getElementById("lblTkt1Name"); spantkt1name.firstChild; ) spantkt1name.removeChild(spantkt1name.firstChild)
			//This is S1 Included line //spantkt1name.appendChild(document.createTextNode("LUCKY NUMBER")), 0 == this.state.vtIndex ? (this.state.maxChooseNum = 1, this.state.minChooseNum = 1, this.state.lIntSWinAmnt = parseInt(40)) : 1 == this.state.vtIndex ? (this.state.maxChooseNum = 2, this.state.minChooseNum = 2, this.state.lIntSWinAmnt = parseInt(240)) : 2 == this.state.vtIndex ? (this.state.maxChooseNum = 3, this.state.minChooseNum = 3, this.state.lIntSWinAmnt = parseInt(2100)) : 3 == this.state.vtIndex ? (this.state.maxChooseNum = 4, this.state.minChooseNum = 4, this.state.lIntSWinAmnt = parseInt(6000)) : 4 == this.state.vtIndex && (this.state.maxChooseNum = 5, this.state.minChooseNum = 5, this.state.lIntSWinAmnt = parseInt(50000))
	
			//This is S1 class Removed line
			//spantkt1name.appendChild(document.createTextNode("LUCKY NUMBER")); 0 == this.state.vtIndex ? (this.state.maxChooseNum = 2, this.state.minChooseNum = 2, this.state.lIntSWinAmnt = parseInt(240)) : 1 == this.state.vtIndex ? (this.state.maxChooseNum = 3, this.state.minChooseNum = 3, this.state.lIntSWinAmnt = parseInt(2100)) : 2 == this.state.vtIndex ? (this.state.maxChooseNum = 4, this.state.minChooseNum = 4, this.state.lIntSWinAmnt = parseInt(6000)) : 3 == this.state.vtIndex && (this.state.maxChooseNum = 5, this.state.minChooseNum = 5, this.state.lIntSWinAmnt = parseInt(50000))
			spantkt1name.appendChild(document.createTextNode("LUCKY NUMBER")); if(0 == this.state.vtIndex) {this.state.maxChooseNum = 2; this.state.minChooseNum = 2; this.state.lIntSWinAmnt = parseInt(240);} else if(1 == this.state.vtIndex) {this.state.maxChooseNum = 3; this.state.minChooseNum = 3; this.state.lIntSWinAmnt = parseInt(2100);} else if(2 == this.state.vtIndex){this.state.maxChooseNum = 4; this.state.minChooseNum = 4; this.state.lIntSWinAmnt = parseInt(6000)} else if(3 == this.state.vtIndex) {this.state.maxChooseNum = 5; this.state.minChooseNum = 5; this.state.lIntSWinAmnt = parseInt(50000);}
	
		} else if (1 == this.state.ntIndex) {
			for (var spantkt1name = document.getElementById("lblTkt1Name"); spantkt1name.firstChild; ) spantkt1name.removeChild(spantkt1name.firstChild)
			//spantkt1name.appendChild(document.createTextNode("LUCKY NUMBER")); 0 == this.state.vtIndex ? (this.state.maxChooseNum = 15, this.state.minChooseNum = 2, this.state.lIntSWinAmnt = parseInt(240)) : 1 == this.state.vtIndex ? (this.state.maxChooseNum = 15, this.state.minChooseNum = 3, this.state.lIntSWinAmnt = parseInt(2100)) : 2 == this.state.vtIndex ? (this.state.maxChooseNum = 15, this.state.minChooseNum = 4, this.state.lIntSWinAmnt = parseInt(6000)) : 3 == this.state.vtIndex && (this.state.maxChooseNum = 15, this.state.minChooseNum = 5, this.state.lIntSWinAmnt = parseInt(50000))
			spantkt1name.appendChild(document.createTextNode("LUCKY NUMBER")); if(0 == this.state.vtIndex ) {this.state.maxChooseNum = 15; this.state.minChooseNum = 2; this.state.lIntSWinAmnt = parseInt(240)} else if(1 == this.state.vtIndex ){this.state.maxChooseNum = 15; this.state.minChooseNum = 3; this.state.lIntSWinAmnt = parseInt(2100);} else if (2 == this.state.vtIndex) {this.state.maxChooseNum = 15; this.state.minChooseNum = 4; this.state.lIntSWinAmnt = parseInt(6000)} else if(3 == this.state.vtIndex) {this.state.maxChooseNum = 15; this.state.minChooseNum = 5; this.state.lIntSWinAmnt = parseInt(50000);}
		} else if (2 == this.state.ntIndex) {
			for (var spantkt1name = document.getElementById("lblTkt1Name"); spantkt1name.firstChild; ) spantkt1name.removeChild(spantkt1name.firstChild)
			//spantkt1name.appendChild(document.createTextNode("BANKER")), 0 == this.state.vtIndex ? (this.state.maxChooseNum = 15, this.state.minChooseNum = 2, this.state.lIntSWinAmnt = parseInt(240)) : 1 == this.state.vtIndex ? (this.state.maxChooseNum = 15, this.state.minChooseNum = 3, this.state.lIntSWinAmnt = parseInt(2100)) : 2 == this.state.vtIndex ? (this.state.maxChooseNum = 15, this.state.minChooseNum = 4, this.state.lIntSWinAmnt = parseInt(6000)) : 3 == this.state.vtIndex && (this.state.maxChooseNum = 15, this.state.minChooseNum = 5, this.state.lIntSWinAmnt = parseInt(50000))
			spantkt1name.appendChild(document.createTextNode("BANKER"));
			if(this.state.vtIndex == 0) {this.state.maxChooseNum = 15; this.state.minChooseNum = 2; this.state.lIntSWinAmnt = parseInt(240);} else if(this.state.vtIndex == 1) {this.state.maxChooseNum = 15; this.state.minChooseNum = 3; this.state.lIntSWinAmnt = parseInt(2100);} else if(this.state.vtIndex == 2) {this.state.maxChooseNum = 15; this.state.minChooseNum = 4; this.state.lIntSWinAmnt = parseInt(6000);} else if(this.state.vtIndex == 3) {this.state.maxChooseNum = 15; this.state.minChooseNum = 5; this.state.lIntSWinAmnt = parseInt(50000);}
		} else if (3 == this.state.ntIndex) {
			for (var spantkt1name = document.getElementById("lblTkt1Name"); spantkt1name.firstChild; ) spantkt1name.removeChild(spantkt1name.firstChild)
			spantkt1name.appendChild(document.createTextNode("STAND")); 
			this.state.maxChooseNum = 20;
			this.state.maxChooseNum1 = 10;
			this.state.minChooseNum = 2;
			this.state.lIntSWinAmnt = parseInt(240)
		}
		//betAmount = 0
		//for (var span = this.state.betAmount; span.firstChild; ) span.removeChild(span.firstChild)
		//span.appendChild(document.createTextNode("Total: ₦ " + betAmount))
	};

	BuyLotteryBet = async () => {
		let getIPad = "";
		if (this.props.user==undefined || this.props.user.mobileNo == "0") {
			alert(this.props.t('lblLoginValidation'));
			return;
		}
		if (this.state.cartItems==undefined || this.state.cartItems.length <= 0) {
			alert(this.props.t('lblplcurbet'));
			return;
		}
		let totstake=this.state.cartItems.reduce((a, b) => a = a + b.total, 0);
		let minTotBetAmt=50;
		let maxTotBetAmt=100000;
		if (this.state.currentGamesFamily != undefined) {
			minTotBetAmt=this.state.currentGamesFamily.minStakePerTrans;
			maxTotBetAmt=this.state.currentGamesFamily.maxStakeAmountPerOneBetOption;
		}
		if(totstake<minTotBetAmt) //50
		{
			alert(this.props.t('lblMinimumplayAmt')+" : "+minTotBetAmt);
			return;
		}
		else if(totstake>maxTotBetAmt)//100000
		{
			alert(this.props.t('lblMaximumplayAmt')+" : "+maxTotBetAmt);	
			return;
		}
		var sTime = new Date(this.props.serverTime);
		let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;
		let betInfo = "";
		//let cartList = this.state.cartItems.sort((a, b) => (a.drawID > b.drawID) ? 1 : -1);
		let cartList = this.state.cartItems.sort((a, b) => a.drawID - b.drawID);				
		let _dratID = '0';
		let _betType = "0";
		let _extraBallCount = 0;
		let extraBallData = "";
		let totStakeAmt = 0;

		// DrawID-Gamegroupid^betype^selected numbers^StackTotAmt^StkPerline^LuckPick^|
		//betInfo += cartList[0].drawID + "-";
		cartList.forEach(element => {
			//let betCountPerDraw = cartList.filter(a => a.drawID == element.drawID).length;
			let ballData = element.balls.reduce((result, item) => { return `${result}${item.id};` }, "");
			ballData = ballData.substring(0, ballData.length - 1);

			let A_ballData = element.A_balls.reduce((result, item) => { return `${result}${item.id};` }, "");
			A_ballData = A_ballData.substring(0, A_ballData.length - 1);

			if(A_ballData.length > 0){A_ballData = "-" + A_ballData;} else{A_ballData="";}

			if (_dratID != '0' && _dratID != element.drawID) {
				betInfo += "*";
			}

			if (_dratID != element.drawID) {
				betInfo += element.drawID + "-" + element.GameGroupID;
			}
			else {
				betInfo += element.GameGroupID;
			}

			//if (_dratID == element.drawID) {
			//	_betType = _betType + 1;
			//}
			//else {
			//	_betType = "A".charCodeAt(0);
			//	//betInfo += element.drawID + "," + element.drawDateTime+","+betCountPerDraw + "@";				
			//}
			
			//betInfo += String.fromCharCode(_betType) + "," + ballData + "," + betCountPerDraw + "," + element.ballCount + ",0," + element.amount + "," + element.total + ",$";

			_dratID = element.drawID;
			//totStakeAmt = totStakeAmt + element.total;

			betInfo += "^" + element.ClassID.replace('NAP ','S').replace('PEM ','P').replace('Bank ','B').replace('Against','A2') + "^" + ballData  + A_ballData + "^" + element.total + "^" + element.amount + "^0^|";
		});
		betInfo += "*";

		//let dateArr = cartList[0].drawDateTime.split(" ");
		//let butStr = `0,0,'${this.props.user.mobileNo}',${cartList[0].gameID},'${dateArr[0]}','${betInfo}','${TransID}',0,${cartList.length},${totStakeAmt},0,'',1,'${this.props.user.loginKey}','${this.props.i18n.language}'`;
		
		if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
			getIPad = this.props.ClntIPAddr;
		}
		else{
			getIPad = await commonClass.GetIPAddress();					
		}

		let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		
		//Sample : WEB_TRA_BuyOnline '08973336225','0897333622514222022160913','12257-4^S3^32;71;72^50^50^0^|4^P4^01;02;03;04;05^250^50^0^|*','192.168.11.240',  2   ,      '2005D6DBA60449E1'
																			// Gamegroupid^betype^selected numbers^StackTotAmt^StkPerline^LuckPick^|		 1-bank 2-GI	GetSecKeyFromLogin
		let butStr = `'${this.props.user.mobileNo}','${TransID}','${betInfo}','${getIPad}',2,'${this.props.user.loginKey}'`;
		//let butStr = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${this.props.user.mobileNo}','${TransID}','${betInfo}','',2,'${this.props.user.loginKey}',${intlangflag}`; 

		this.props.Buybet(butStr, getIPad);
		this.clearCart();
	};

	render() {
		const { t } = this.props;
		return (
			<div>
				<Header />
				<div className="gameContainer">       
						<Left selectDraw={this.selectDraw} currentAvailableDraws={this.state.currentAvailableDraws} />
						<div class="col-md-9">

						<div className="drawTabs">
				<a  id="drawTabs1">{t('lblAllDraws')}</a>
				<a  id="drawTabs2">{t('lblgameslip')}<span className="betCount">{this.state.cartItems.length}</span></a>
			</div>

							<div class="Rgt">
								<div class="DateTime">
								<small style={{borderRight: "1px solid #fff"}}>{this.reCount(this.state.currentRunningDraw)}</small>
									<small>{this.state.currentDraw ? this.state.currentDraw.LotName +" "+ this.state.currentDraw.DrawDate+" "+ this.state.currentDraw.DrawTime : "-"}</small>
								</div>
								<div class="playListAmount bg1">									
								<div class="amounEnter">
                    <div class="amountArea">
<span id="betAmount1">Stake Amount</span>
{/* <select name="stakeNum" id="stakeNum" onchange={()=>{this.checkStakeNum()}}>
	<option selected="selected" value="50">50</option>
	<option value="100">100</option>
	<option value="200">200</option>
	<option value="300">300</option>
	<option value="500">500</option>
	<option value="1000">1000</option>
	<option value="2000">2000</option>
	<option value="5000">5000</option>
	<option value="10000">10000</option>
	<option value="50000">50000</option>
</select> */}
{this.Load_stakeamt()}
</div>     
                </div>

				<div class="amounEnter">
                    <div class="amountArea">
									<span>Option</span>
							{/*<select id="selClasses" onChange={(e)=>{this.EnableLottoTabPad(e)}}>*/}
							<select id="selClasses" onChange={()=>{this.SelectClassesBox()}}>							
								<option id="1" value="Direct">Direct</option>
								<option id="2" value="Permutation">Permutation</option>
								<option id="3" value="Banker">Banker</option>
								<option id="4" value="Against">Against</option>
								</select>
								</div>
								</div>

								<div class="amounEnter">
                    <div class="amountArea">
									<span id="optionsSelect">Class</span>
									{this.OptionEnable()}

									{/* <select id="SelectOption1" onChange={()=>{this.SelectIndexBox() }}><option>NAP 2</option><option>NAP 3</option><option>NAP 4</option><option>NAP 5</option></select>
				<select id="SelectOption2" onChange={()=>{this.SelectIndexBox() }} style={{ display: "none"}}><option>PEM 2</option><option>PEM 3</option><option>PEM 4</option><option>PEM 5</option></select>
				<select id="SelectOption3" onChange={()=>{this.SelectIndexBox() }} style={{display: "none"}}><option>Bank 2</option><option>Bank 3</option><option>Bank 4</option><option>Bank 5</option></select>
				<select id="SelectOption4" onChange={()=>{this.SelectIndexBox() }} style={{display: "none"}}><option>Against</option></select>*/}
				</div>
				</div>              
							</div>


							
								<div className="col-md-8">
									<div class="quickPickLiCont">										
										<ul className="quickPickRglrBalCont bg1">
										<div className="ticketnew" id="div1">
			<label><span id="lblTkt1Name">LUCKY NUMBER</span></label>
			<li className="quickPickRglrBalContLi">
			{this.createBall(1)}			
			</li>
			</div>
			<div id="div2" style={{ display: "none"}} className="divticket">
			<div className="ticketnew">
			<label><span id="lblTkt1Name">Against</span>
			
			<div id="divAll">
			<input onChange={()=>{this.SelectAllLAgaintsLottery() }} class="chkAllLottery_2" id="chkAllLottery_2" type="checkbox"/><span>All</span>
            </div>
			</label>
			<li className="quickPickRglrBalContLi">
			{this.createBall(2)}
			</li>
			</div>
			</div>
			<div className="TltNoTrgtCont">
								<span className="flwLft">{t('lblTotstakeAmt')} : { this.state.betAmount}</span>
								<span className="flwRht">{t('lblMaxWinTargt')} : { this.state.Target}</span>
							</div>
							<div className="Tltamount">
							<div className="AddBTn">
							<a className="btn1" onClick={() => { this.clearAfterAddToCart() }}>{t('lblclear')}</a>
							</div>
								<div className="AddBTn">
									<a className="btn1" onClick={() => { this.addToCart() }} >{t('lbladd')}</a>
								</div>
							</div>
			</ul>
				
										</div>
									</div>
{/*<Right buyLotteryBet={this.BuyLotteryBet} clearCart={this.clearCart} removeFromCart={this.removeFromCart} latestResult={this.state.latestResult} />*/}
<Right buyLotteryBet={this.BuyLotteryBet} clearCart={this.clearCart} removeFromCart={this.removeFromCart}/>
								</div>
						
							</div>
							</div>			
							</div>	
)
	};
}

function mapStateToProps(state) {
	const { currentGamesFamily, currentAvailableDraws,  myBet,latestResult } = state.gameReducer;
	const {serverTime, ClntIPAddr } = state.commonReducer;
	const { user } = state.loginReducer;
	const { cartItems, currentDraw } = state.lottoReducer;
	return { currentGamesFamily, user, currentAvailableDraws, cartItems, currentDraw, serverTime, myBet ,latestResult, ClntIPAddr};
}

const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	AddToCart: lottoActions.AddToCart,
	RemoveFromCart: lottoActions.RemoveFromCart,
	RemoveClosedDrawbetFromCart: lottoActions.RemoveClosedDrawbetFromCart,
	SetCurrentDraw: lottoActions.SetCurrentDraw,
	ClearCart: lottoActions.ClearCart,
	Buybet: lottoActions.Buybet,
	GetLatestResult: gameActions.GetLatestResult,
	ClearAvailableGames:gameActions.ClearAvailableGames
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Lotto))