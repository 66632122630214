import { appConstants } from '../helpers/actionType';
import { accountService } from '../services/accountService';
import { alertActions } from "../action/alertAction";
import { commonClass } from "../helpers/commonClass";
export const accountActions = {
	GetBetHistory,
	GetAccountHistory,
	AddMoney,
	WithdrawMoney,
	GetAccountSummary,
	GetResultReport,
	GetResentResult,
	GetMyNetwork,
	GetlstTierNetwork,
	GetAccountActivity,
	ReprintTicket,	
	Gethowtoplay
}

function GetBetHistory(mobileNo, dtFrom, dtTo,langID,getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
		dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			dispatch({ type: appConstants.LOADING });
			////let data = `'0','0','${mobileNo}','0','${dtFrom}','${dtTo}','${langID}'`;
			let data = `'${mobileNo}','${dtFrom}','${dtTo}'`;
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${mobileNo}','${dtFrom}','${dtTo}',${langID}`; 

			accountService.getbetHistory(data)
				.then(
					bet => {
						let strArr = bet.data.split('|');
						let strArr1 = strArr[0].split('~');
						if (strArr1[0] == '0') {
							strArr[0] = strArr1.slice(1, strArr1.length).join("~");
							let _his = [];
							strArr.forEach(element => {
								if (element == "") { return; }
								let strArr2 = element.split('~');
								_his.push({
									// Id: strArr2[0],
									// Ball: strArr2[1].split(';'),
									// GameName: strArr2[2],
									// GameGroupId: strArr2[3],
									// TransDateTime: strArr2[4] + " " + strArr2[5],
									// StakeAmount: strArr2[7],
									// DrawDateTime: strArr2[8],
									// EstimateWin: strArr2[9],
									// Status: strArr2[10],
									//Id: strArr2[0],
									GameGroupId: strArr2[0],									
									GameName: strArr2[1],									
									TransDateTime: strArr2[2],
									Status: strArr2[3],
									DrawDateTime: strArr2[4],
									Ball: strArr2[5],
									TotalStakeAmount: strArr2[6],
									TotalBet: strArr2[7],
									Reslt: strArr2[8],
									WinAmount: strArr2[9],
									Betstatus: strArr2[10]
								});
							});
							let bethistory = { status: "0", data: _his };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.GET_BET_HISTORY, bethistory });
						}
						else {
							let strArr = bet.data.split('~');
							let bethistory = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.GET_BET_HISTORY, bethistory });
						}
					},
					error => {
						let bethistory = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.GET_BET_HISTORY, bethistory });
						dispatch(alertActions.error(bethistory.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAccountHistory(mobileNo, dtFrom, dtTo,langID, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});
			dispatch({ type: appConstants.LOADING });
			////let data = `'${mobileNo}','${dtFrom}','${dtTo}','${langID}'`;
			let data = `'${mobileNo}','${dtFrom}','${dtTo}'`;
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${mobileNo}','${dtFrom}','${dtTo}',${langID}`; 

			accountService.getAccountHistory(data)
				.then(
					bet => {
						let accountHistory = {};
						let accHistMob = {};
						if (bet && bet.length == 1){
							accountHistory = { status: "0", data: bet[0] };
							accHistMob = { status: "0", data: bet[0] };
						}
						else if (bet && bet.length > 1){
							accountHistory = { status: "0", data: bet[0], data1: bet[1]};
							accHistMob = { status: "0", data: bet[0], data1: bet[1]};
						}
						else{
							accountHistory = { status: "1", msg: "No Data" };
							accHistMob = { status: "1", msg: "No Data" };
						}

						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_HISTORY, accountHistory });
						dispatch({ type: appConstants.GET_ACCOUNT_HISTORY_Mob, accHistMob });
					},
					error => {
						let accountHistory = { status: "2", msg: "Something Wrong!" };
						let accHistMob = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.GET_ACCOUNT_HISTORY, accountHistory });
						dispatch({ type: appConstants.GET_ACCOUNT_HISTORY_Mob, accHistMob });
						dispatch(alertActions.error(accountHistory.msg));
						dispatch(alertActions.error(accHistMob.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetResentResult(langID) {
	try {
		return dispatch => {			
			dispatch({ type: appConstants.LOADING });			

			//let data = `'0','0','0','0','0','1','${process.env.REACT_APP_VERSION}','0',${langID}`; 			

			//accountService.getResentResult(data)
			accountService.getResentResult()
				.then(
					RecentResultdts => {
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_Recent_RESULT, RecentResultdts });
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error=error.message;
						dispatch({ type: appConstants.GET_Recent_RESULT, error });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetResultReport(dtFrom, dtTo, familyID,langID,SlctDaysStatus, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			if(familyID!=-1)
			dispatch({ type: appConstants.LOADING });
			let data = `'${dtFrom}','${dtTo}',0,0,'${familyID}','${langID}','${SlctDaysStatus}'`;
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${dtFrom}','${dtTo}','0','0','${familyID}','','${SlctDaysStatus}',${langID}`; 

			accountService.getResultReport(data)
				.then(
					resultReport => {
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_RESULT_REPORT, resultReport });
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error=error.message;
						dispatch({ type: appConstants.GET_RESULT_REPORT, error });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetlstTierNetwork(pstrLginUserID, dtFrom, langID, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

			dispatch({ type: appConstants.LOADING });

			let data = "";
			data = `'${pstrLginUserID}','${dtFrom}'`;
			//data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${pstrLginUserID}','${dtFrom}',${langID}`; 

				accountService.GetlstTierNetwork(data)					
				.then(
					GetlstTierNetworkdtls => {
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_MyNetwork_LstTier, GetlstTierNetworkdtls });						
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error=error.message;
						dispatch({ type: appConstants.GET_MyNetwork_LstTier, error });
					}
				);	
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetMyNetwork(pstrLginUserID, dtFrom, pstrNetworkMobNo ,langID, getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});			
			dispatch({ type: appConstants.LOADING });
			let data = "";
			//pstrNetworkMobNo = '08754323120';
			if(pstrNetworkMobNo == ""){
				data = `'${pstrLginUserID}','${dtFrom}',''`;
				//data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${pstrLginUserID}','${dtFrom}','',${langID}`; 
				accountService.getMyNetwork(data)					
				.then(
					MyNetworkReport => {
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_MyNetwork_REPORT, MyNetworkReport });
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error=error.message;
						dispatch({ type: appConstants.GET_MyNetwork_REPORT, error });
					}
				);
		}
			else{
				data = `'${pstrLginUserID}','','','${pstrNetworkMobNo}'`;
				//data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${pstrLginUserID}','','','${pstrNetworkMobNo}',${langID}`; 
				accountService.getMyNetworkCommission(data)					
				.then(
					MyNetworkReport => {
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_MyNetwork_REPORT, MyNetworkReport });
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						error=error.message;
						dispatch({ type: appConstants.GET_MyNetwork_REPORT, error });
					}
				);
		}
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function AddMoney(mobileNo, txnNo, TransID,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${txnNo}','${TransID}','','${langID}'`;
			accountService.addMoney(data)
				.then(
					bet => {
						let strArr = bet.data.split('~');
						if (strArr[0] == '0') {
							let addmoney = { status: strArr[0], msg: strArr[1] };
							let balance = strArr[2];
							let winbalance = strArr[3];
							let winningAmt = 0;
							let currentcommissions = 0;
							let availabeCommissions = 0;
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.ADD_MONEY_SUCCESS, addmoney });
							dispatch(alertActions.success(addmoney.msg));
							dispatch({ type: appConstants.GET_BALANCE, winningAmt, currentcommissions, availabeCommissions, balance, winbalance });
						}
						else {
							let strArr = bet.data.split('~');
							let addmoney = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.ADD_MONEY_SUCCESS, addmoney });
							dispatch(alertActions.error(addmoney.msg));

						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						let addmoney = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.ADD_MONEY_SUCCESS, addmoney });
						dispatch(alertActions.error(error.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function WithdrawMoney(mobileNo, amt, TransID,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${amt}','${TransID}','','${langID}'`;
			accountService.withdrawMoney(data)
				.then(
					bet => {
						let strArr = bet.data.split('~');
						if (strArr[0] == '0') {
							let withdraw = { status: strArr[0], msg: strArr[1] };
							let withdrawMob = { status: strArr[0], msg: strArr[1] };
							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdraw });
							dispatch(alertActions.success(withdraw.msg));

							dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdrawMob });
							dispatch(alertActions.success(withdrawMob.msg));
						}
						else {
							let strArr = bet.data.split('~');
							let withdraw = { status: strArr[0], msg: strArr[1] };
							let withdrawMob = { status: strArr[0], msg: strArr[1] };

							dispatch({ type: appConstants.LOADED });
							dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdraw });
							dispatch(alertActions.error(withdraw.msg));

							dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdrawMob });
							dispatch(alertActions.error(withdrawMob.msg));
						}
					},
					error => {
						dispatch({ type: appConstants.LOADED });
						let withdraw = { status: "2", msg: "Something Wrong!" };
						let withdrawMob = { status: "2", msg: "Something Wrong!" };

						dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdraw });
						dispatch(alertActions.error(withdraw.msg));

						dispatch({ type: appConstants.WITHDRAW_MONEY_SUCCESS, withdrawMob });
						dispatch(alertActions.error(withdrawMob.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAccountSummary(mobileNo, dtFrom, dtTo,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'0','0','${mobileNo}','${dtFrom}','${dtTo}','${langID}'`;
			accountService.getAccountSummary(data)
				.then(
					accountSummary => {
						if (accountSummary.data != null) {
							let strArr = accountSummary.data.split('~');
							if (strArr[0] == '0') {
								accountSummary = {
									status: strArr[0], openBalance: strArr[3], totSale: strArr[4],
									totClaim: strArr[5], totCashIn: strArr[6], totCashOut: strArr[7], netTotal: strArr[9]
								};
							}
							else {
								accountSummary = { status: strArr[0], msg: strArr[1] };
							}
						}
						else {

							accountSummary = { status: "1", msg: "No Data" };
						}
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_SUMMARY, accountSummary });
					},
					error => {
						let accountSummary = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_SUMMARY, accountSummary });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAccountActivity(mobileNo, dtFrom, dtTo,langID,getIPad) {
	try {
		return dispatch => {
			let ClntIPAddr=getIPad;
			dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','0','000','${dtFrom}','${dtTo}','0'`; //let data = `'${mobileNo}','${dtFrom}','${dtTo}','${langID}'`;
			//let data = `'0','0','0','${getIPad}','0','1','${process.env.REACT_APP_VERSION}','${mobileNo}','0','000','${dtFrom}','${dtTo}',${langID}`; 

			accountService.getAccountActivity(data)
				.then(
					bet => {
						let accountActivity = {};
						let accActMob = {};
						//if (bet && bet.length == 1)						
						//	accountActivity = { status: "0", data1: bet[0] };						
						//else
						if (bet && bet.length > 3){
							accountActivity = { status: "0", data: bet[3], data1: bet[0], data2: bet[2] };
							accActMob = { status: "0", data: bet[3], data1: bet[0], data2: bet[2] };
						}
						else{
							accountActivity = { status: "1", msg: "No Data" };
							accActMob = { status: "1", msg: "No Data" };
						}

						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_ACCOUNT_ACTIVITY, accountActivity });
						dispatch({ type: appConstants.GET_ACCOUNT_ACTIVITY_Mob, accActMob });
					},
					error => {
						let accountActivity = { status: "2", msg: "Something Wrong!" };
						let accActMob = { status: "2", msg: "Something Wrong!" };
						dispatch({ type: appConstants.GET_ACCOUNT_ACTIVITY, accountActivity });
						dispatch({ type: appConstants.GET_ACCOUNT_ACTIVITY_Mob, accActMob });
						dispatch(alertActions.error(accountActivity.msg));
						dispatch(alertActions.error(accActMob.msg));
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function ReprintTicket(transID, mobileNo, _gameGroup,langID) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `'${mobileNo}','${transID}','${langID}'`;
			accountService.ReprintTicket(data)
				.then(
					buyInfo => {
						if (_gameGroup == "-1") {
							// let ss = buyInfo.split('^');
							// if (ss.length > 1) {
							// 	_gameGroup=ss[0];
							// 	buyInfo = ss[1];
							// }
							let ss = buyInfo.includes('Jackpot');
							if (ss)
								_gameGroup = 4;
							else
								_gameGroup = 5;
						}
						let myBet = commonClass.generateBetSlip(buyInfo, _gameGroup);
						dispatch({ type: appConstants.BUY_SUCCESS, myBet });
						dispatch({ type: appConstants.LOADED });
						//let balance = myBet.balance;
						//let winbalance =myBet.winbalance;     
						//dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });  

					},
					error => {
						let myBet = { status: 2, msg: "Something Wrong!" };
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.BUY_SUCCESS, myBet });
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

function Gethowtoplay(langID, formflag) {
	try {
		return dispatch => {
			dispatch({ type: appConstants.LOADING });
			let data = `${langID},${formflag}`;
			accountService.gethowtoplay(data)
				.then(
					gethowtoplay => {					
						dispatch({ type: appConstants.LOADED });
						dispatch({ type: appConstants.GET_HOWTOPLAY, gethowtoplay });
					},
					error => {	
						error=error.message;					
						dispatch({ type: appConstants.GET_HOWTOPLAY, error });						
					}
				);
		}
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};

