import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withTranslation } from 'react-i18next';

class ClassificationChat extends Component {
    constructor(props) {
        super(props);        
        this.state = {         
            appsts: 1
        }

		const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

		//if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;

    }

    render() {
        const { t } = this.props;
        const { appsts } = this.state;
        return (
			<div>
            <div className="mainContainer">
{appsts	== 1 && <Header /> }
                <div class="row bg2">
			<div class="container forPadd1">
            <a href='./assets/img/Lotto_Classification_Chart.jpg' download={'./assets/img/Lotto_Classification_Chart.jpg'}><div className="DateTime">
					<span>{t('lblClassifyChart')}</span>
				</div></a>
				<div class="privacyCont addContforPadd">
					<div class="clasifyChart">
						<img src="./assets/img/Lotto_Classification_Chart.jpg" alt='Classification Chart' />
					</div>
				</div>
			</div>
		</div>          
            </div>
			{appsts	== 1 && <Footer /> } 
			</div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ClassificationChat))