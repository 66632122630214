import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../../helpers/history';
import Header from "../Header/Header";
import { loginActions } from '../../action/loginAction';
import { alertActions } from "../../action/alertAction";
import { commonClass } from "../../helpers/commonClass";
import { withTranslation } from 'react-i18next';
class Login extends Component {

	constructor(props) {
		super(props);		
		//this.props.logout(0);
		this.props.loginSkip(1);
		
		this.state = {
			mobileNo: '',
			password: '',
			submitted: false,			

			forgotMobileNo: '',
			ConfirmforgotMobileNo: '',
			forgotSubmitted: false,

			otpCode: '',
			otpSubmitted: false,

			regOtpCode: '',
			regOtpSubmitted: false,
			isOtpSendNeed: false,
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.submitLogin = this.submitLogin.bind(this);		
		this.submitForgotPassword = this.submitForgotPassword.bind(this);
		this.submitOtpValidate = this.submitOtpValidate.bind(this);
		this.submitSendOtp = this.submitSendOtp.bind(this);

	}	

	static getDerivedStateFromProps(props, state) {
		return {
			pageFlog: props.pageFlog,
			otpMobileNo: props.otpMobileNo
		};
	};
	handleInputChange(e) {
		try {

			this.setState({ submitted: false });
			this.setState({ forgotSubmitted: false });

			let name = e.target.name;
			let value = e.target.value;
			//if ((name.toLowerCase().includes("mobile") || name.toLowerCase().includes("otp")) && value && (/^[0-9\b]+$/.test(value)) == false) {
				if ((name.toLowerCase().includes("mobile")) && value && (/^[0-9\b]+$/.test(value)) == false) {
				return;
			}
			//if (name == "regDob") {
			//	value = document.getElementById("datepicker1").value;
			//}
			//else if (this.state.pageFlog == 1) {
			//	let dt = document.getElementById("datepicker1").value;
			//	if (this.state.regDob != dt) {
			//		this.setState({ regDob: dt });
			//	}
			//}
			if (name == "regTC") {
				//this.setState({ regTC: !regTC }) 
				value = !this.state.regTC;
			}
			if (name == "regChkAge") {			
				value = !this.state.regChkAge;
			}
			
			this.setState({
				[name]: value
			});
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleInputChange");
		}
	};


	//submitLogin(e) {
	submitLogin = async (e) => {
		let getIPad = "";
		try {
			e.preventDefault();				
			this.setState({ submitted: true });
			let { mobileNo, password } = this.state;
			if (mobileNo && password) {
				//mobileNo = "250" + mobileNo.substring(1, mobileNo.length);
				password = password.replace(/'/g, "''");

				if(this.handleFormValidation(5, password)){ }
				else {					
					this.showMsg("lblpwdlength");
					return;
				}

				if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
					getIPad = this.props.ClntIPAddr;
				}
				else{
					getIPad = await commonClass.GetIPAddress();					
				}
				
				let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	
				//this.props.login(this.props.appvariables.countryCode + mobileNo, password, this.props.i18n.language);
				this.props.login(mobileNo, password, intlangflag, getIPad);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitLogin",getIPad);
		}
	};

	//submitForgotPassword(e) {
	submitForgotPassword = async (e) => {
		let getIPad = "";
		try {
			e.preventDefault();
			this.setState({ forgotSubmitted: true });
			let { forgotMobileNo, ConfirmforgotMobileNo } = this.state;
			if (forgotMobileNo &&  ConfirmforgotMobileNo) {
				if(this.handleFormValidation(1, forgotMobileNo) && this.handleFormValidation(1, ConfirmforgotMobileNo)){ }
				else {
					this.showMsg("Must fill all field value with correct format. or else refresh this page and try again.");
					return;
				}

				if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
					getIPad = this.props.ClntIPAddr;
				}
				else{
					getIPad = await commonClass.GetIPAddress();					
				}
				
				let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	

				//forgotMobileNo = "250" + forgotMobileNo.substring(1, forgotMobileNo.length);
				this.props.forgotPassword(forgotMobileNo, ConfirmforgotMobileNo, intlangflag,getIPad);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitForgotPassword");
		}
	};

	submitOtpValidate(e) {
		try {
			e.preventDefault();
			this.setState({ otpSubmitted: true });
			let { otpMobileNo, otpCode } = this.state;
			if (otpMobileNo && otpCode && this.handleFormValidation(1, otpMobileNo)) {
				otpMobileNo = "250" + otpMobileNo.substring(1, otpMobileNo.length);
				otpCode = otpCode.replace(/'/g, "''");
				this.props.otpAuthentication(this.props.appvariables.countryCode + otpMobileNo, otpCode, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitOtpValidate");
		}
	};

	submitSendOtp(e) {
		try {
			e.preventDefault();
			this.setState({ regOtpSubmitted: true });
			let { regMobileNo, regEmailID } = this.state;
			if (regMobileNo && this.handleFormValidation(1, regMobileNo)) {
				this.setState({ isOtpSendNeed: true })
				regMobileNo = "250" + regMobileNo.substring(1, regMobileNo.length);
				regEmailID = regEmailID.replace(/'/g, "''");
				this.props.reSendOtp(this.props.appvariables.countryCode + regMobileNo, regEmailID, 0, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitSendOtp");
		}
	};
	showMsg = (msg) => {
		this.props.alertError(this.props.t(msg));
	}
	handleFormValidation(flog, data) {
		try {
			//Phone number          
			//var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
			//var mobPattern = /^\d{10}$/;
			var mobPattern = /^[0]\d{10}$/;
			if (flog == 1 && !mobPattern.test(data)) {
				this.showMsg('lblInvalidMobileNo');
				return false;
			}
			if (flog == 5 && data && (data.length < 8 || data.length > 15)) {
				this.showMsg('lblpwdlength');
				return false;
			}
			//Email    
			if (flog == 2 && data && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data))) {
				this.showMsg("lblInvalidEmailID");

				return false;
			}
			//DOB           
			var pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/;
			if (flog == 3 && !pattern.test(data)) {
				this.showMsg("lblDateFormatMust");
				return false;
			}
			if (flog == 4 && !mobPattern.test(data)) {
				this.showMsg('lblInvalidRefMobileNo');
				return false;
			}
			return true;
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleFormValidation");
			return false;
		}
	};
	componentDidUpdate() {
		//window.timecalender();
	};
	componentDidMount() {
		//window.docMapVisiblePwd();
	};

	isDate18orMoreYearsOld = (birthday) => {
		var lst = birthday.split('/');
		// it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
		///var optimizedBirthday = birthday.replace(/-/g, "/");	
		var optimizedBirthday = lst[2] + "/" + lst[1] + "/" + lst[0];
		//set date based on birthday at 01:00:00 hours GMT+0100 (CET)
		var myBirthday = new Date(optimizedBirthday + ' 01:00:00');
		// set current day on 01:00:00 hours GMT+0100 (CET)
		var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
		// calculate age comparing current date and borthday
		var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
		if (myAge < 18) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		const { t } = this.props;		
		const { mobileNo, password, submitted } = this.state;		
		const { forgotMobileNo, ConfirmforgotMobileNo, forgotSubmitted } = this.state;
		const { regOtpSubmitted } = this.state;
		const { otpCode, otpSubmitted } = this.state;
		return (
			<div>
				<Header/>
			<div class="mainContainer foBg1">				
				{/* Login Form */}
				{this.state.pageFlog == 0 &&
		<div class="formCont" id="userLoginForm">
			<form class="login-form">
				<div class="head">
					<h3>Welcome back!</h3>
					<p>{t('lblLogin')}</p>
				</div>
				{/* <span class="formInfo">This login page is for players only. Not for Agents! Kindly notify support to login as an agent.</span> */}
				<span class="formInfo">Register as a Player</span>
				<div class="formField">
					<label>Mobile number</label>					
					<input maxLength="11" class="username" placeholder={`${t('lblMobNoformat')}`} type="text" value={this.state.mobileNo} onChange={this.handleInputChange} name="mobileNo" />
					{submitted && !mobileNo && <small>{t('lblRequired')}</small>}
					{submitted && mobileNo && (!(/[0]\d{10}$/.test(mobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
				</div>
				<div class="formField">
					<label>Password</label>					
					<input maxLength="15" minLength="8" class="password" placeholder={`${t('lblPassword')} *`} type="password" value={this.state.password} onChange={this.handleInputChange} name="password" />
					<span class="eyeIcon eyeOpen" onClick={(e) => window.docMapVisiblePwd(e)}></span>
					{submitted && !password && <small>{t('lblRequired')}</small>}
				</div>				
				<a class="forGotPass" id="forgotPg" onClick={() => { this.props.setPage(2) }}>{t('lblForgetpwd')}?</a>
				<div class="formField">					
					<button class="LoginBtn" id="loginBtn" onClick={this.submitLogin}>{t('lblLogin')}</button>
				</div>
				<p class="message">Don't have an account? <a id="RgstrPg" onClick={() => history.push('/Reg')}>Create one</a></p>
			</form>
		</div>
	}	

	{/* Forgot Password Form */}
	{this.state.pageFlog == 2 &&
	<div class="formCont" id="userForgotPwdForm">
	<form class="login-form">
		<div class="head">
			<h3>{t('lblForgetpwd')}</h3>
		</div>
		<div class="formField">
			<label>MobileNo<b>*</b></label>			
			<input maxLength="11" class="username" type="number" placeholder={`${t('lblmobno')}`} value={this.state.forgotMobileNo} onChange={this.handleInputChange} name="forgotMobileNo" />
			{forgotSubmitted && !forgotMobileNo && <small>{t('lblRequired')}</small>}
			{forgotSubmitted && forgotMobileNo && (!(/[0]\d{10}$/.test(forgotMobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
		</div>
		<div class="formField">
			<label>Confirm MobileNo <b>*</b></label>
			<input maxLength="11" class="username" type="number" placeholder={`${t('lblConfirmMobno')} *`} value={this.state.ConfirmforgotMobileNo} onChange={this.handleInputChange} name="ConfirmforgotMobileNo" />
			{forgotSubmitted && !ConfirmforgotMobileNo && <small>{t('lblRequired')}</small>}
			{forgotSubmitted && ConfirmforgotMobileNo && (!(/[0]\d{10}$/.test(ConfirmforgotMobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
		</div>		
		<div class="formField">			
			<button class="LoginBtn" onClick={this.submitForgotPassword} >{t('lblSubmit')}</button>
		</div>
		<p class="message">Already have an account? <a id="loginpg" onClick={() => { this.props.setPage(0) }}>Log In</a></p>
	</form>
</div>
	}
		
	</div>
	</div>			
		)
	}
}

function mapStateToProps(state) {
	const { loggingIn, appvariables, pageFlog, otpMobileNo } = state.loginReducer;
	const { ClntIPAddr } = state.commonReducer;
	return { loggingIn, appvariables, pageFlog, otpMobileNo, ClntIPAddr };
}

const mapActionToProps = {
	login: loginActions.login,
	loginSkip: loginActions.loginSkip,
	logout: loginActions.logout,
	setPage: loginActions.setPage,	
	forgotPassword: loginActions.forgotPassword,
	otpAuthentication: loginActions.otpAuthentication,
	reSendOtp: loginActions.reSendOtp,
	alertError: alertActions.error,

}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Login))