import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FileBase64 from 'react-file-base64';
import { history } from '../../helpers/history';
import Header from "../Header/Header";
import { loginActions } from '../../action/loginAction';
import { alertActions } from "../../action/alertAction";
import { commonClass } from "../../helpers/commonClass";
import { withTranslation } from 'react-i18next';
class Reg extends Component {

	constructor(props) {
		super(props);		
		//this.props.logout(0);
		this.props.loginSkip(1);

		// if (this.props.location.state && this.props.location.state.isRegister) {
		// 	this.props.setPage(this.props.location.state.isRegister)
		// }

		this.state = {			

			regFirstName: '',
			regLastName: '',
			regReffererMobileNo: '',
			regPassword: '',
			regConfirmPassword: '',
			regEmailID: '',
			regMobileNo: '',
			regOtp: '',
			regTC: false,
			regChkAge : false,
			regDob: '',
			regSubmitted: false,
			files: [],			

			otpCode: '',
			otpSubmitted: false,

			regOtpCode: '',
			regOtpSubmitted: false,
			isOtpSendNeed: false,
			ReigtUserFlag: '',
		};
		this.handleInputChange = this.handleInputChange.bind(this);		
		this.submitRegister = this.submitRegister.bind(this);		
		this.submitOtpValidate = this.submitOtpValidate.bind(this);
		this.submitSendOtp = this.submitSendOtp.bind(this);
	}

	getFiles(files){
		this.setState({ files: files })
	}

	static getDerivedStateFromProps(props, state) {
		return {
			pageFlog: props.pageFlog,
			otpMobileNo: props.otpMobileNo
		};
	};
	handleInputChange(e) {
		try {

			this.setState({ regSubmitted: false });

			let name = e.target.name;
			let value = e.target.value;
			//if ((name.toLowerCase().includes("mobile") || name.toLowerCase().includes("otp")) && value && (/^[0-9\b]+$/.test(value)) == false) {
				if ((name.toLowerCase().includes("mobile")) && value && (/^[0-9\b]+$/.test(value)) == false) {
				return;
			}
			//if (name == "regDob") {
			//	value = document.getElementById("datepicker1").value;
			//}
			//else if (this.state.pageFlog == 1) {
			//	let dt = document.getElementById("datepicker1").value;
			//	if (this.state.regDob != dt) {
			//		this.setState({ regDob: dt });
			//	}
			//}
			if (name == "regTC") {
				//this.setState({ regTC: !regTC }) 
				value = !this.state.regTC;
			}
			if (name == "regChkAge") {			
				value = !this.state.regChkAge;
			}
			
			this.setState({
				[name]: value
			});
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleInputChange");
		}
	};

	//submitRegister(e) {
	submitRegister = async (e) => {
		let getIPad = "";
		try {
			e.preventDefault();
			this.setState({ regSubmitted: true });
			let { regFirstName, regLastName, regPassword, regConfirmPassword, regEmailID, regReffererMobileNo, regDob, regMobileNo, regTC, regChkAge, regOtpCode, ReigtUserFlag, files } = this.state;
			//let { regFirstName, regLastName, regPassword, regConfirmPassword, regEmailID, regReffererMobileNo, regDob, regMobileNo, regTC, regChkAge, regOtpCode, ReigtUserFlag } = this.state;
			//let regDobTemp = document.getElementById("datepicker1").value;
			//if (regDobTemp != regDob) {	
			//	regDob = regDobTemp;
			//	this.setState({ regDob: regDobTemp });
			//}

			//if (regFirstName && regLastName && regPassword && regConfirmPassword && regMobileNo && regDob && regTC) {
			if (regFirstName && regLastName && regPassword && regConfirmPassword && regMobileNo && regReffererMobileNo && regTC && regChkAge) {
				if (this.handleFormValidation(1, regMobileNo) && this.handleFormValidation(2, regEmailID) && this.handleFormValidation(4, regReffererMobileNo)) { }
				else {
					//this.showMsg("Must fill all field value with correct format. or else refresh this page and try again.");
					return;
				}

				if(this.handleFormValidation(5, regPassword) && this.handleFormValidation(5, regConfirmPassword)){
					if(regPassword != regConfirmPassword){
						this.showMsg("lblPasswordMismatch");
					return;
					}
				 }
				else {					
					this.showMsg("lblpwdlength");
					return;
				}

				if(files.length > 0){
					const fileSizeKiloBytes = (files[0].size.split(' ')[0] * 1024);					

if(fileSizeKiloBytes > (200 * 1024)){
	this.showMsg("Upload Player image size within 200 kb"); 
	return;
}
if(files[0].type != "image/png" && files[0].type != "image/jpg" && files[0].type != "image/webp" && files[0].type != "image/jpeg"){
	this.showMsg("Upload Player image format of like .png / .jpg / .webp"); 
	return;
}
				}

				//let ss = this.isDate18orMoreYearsOld(regDob);
				//if (ss == false) {
				//	this.showMsg("lblAgeshouldbe");
				//	return;
				//}

				//if (regOtpCode) {
					//regMobileNo = "250" + regMobileNo.substring(1, regMobileNo.length);					
					regFirstName = regFirstName.replace(/'/g, "''");
					regLastName = regLastName.replace(/'/g, "''");
					regPassword = regPassword.replace(/'/g, "''");
					regEmailID = regEmailID.replace(/'/g, "''");
					ReigtUserFlag = "1"; //1--Player 2--Agent(Not option). only player
					//regDob = regDob.replace(/'/g, "''");
					//regOtpCode = regOtpCode.replace(/'/g, "''");

					if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
						getIPad = this.props.ClntIPAddr;
					}
					else{
						getIPad = await commonClass.GetIPAddress();					
					}

					let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	
					////this.props.registration(regFirstName, regLastName, regPassword, regEmailID, this.props.appvariables.countryCode + regMobileNo, regDob, regReffererMobileNo, regOtpCode, '', '0', this.props.i18n.language);
					//this.props.registration(regFirstName, regPassword, regEmailID, regMobileNo, regReffererMobileNo, '', '0', this.props.i18n.language, ReigtUserFlag, files[0].base64.split(',')[1]);

					//this.props.registration(regFirstName, regPassword, regEmailID, regMobileNo, regReffererMobileNo, '', '0', this.props.i18n.language, ReigtUserFlag, files[0].base64.split(',')[1]);
					if(files.length > 0){
						this.props.registration(regFirstName, regLastName, regPassword, regEmailID, regMobileNo, regReffererMobileNo, getIPad, '0', intlangflag, ReigtUserFlag, files[0].base64);	
					}
					else
					{
						this.props.registration(regFirstName, regLastName, regPassword, regEmailID, regMobileNo, regReffererMobileNo, getIPad, '0', intlangflag, ReigtUserFlag, "");							
					}
					
				//}
				//else {
				//	this.showMsg("lblmobileverifymust");
				//}
			}
			else {
				if (this.state.regSubmitted)
					this.props.alertError("Must fill all field value with correct format. or else refresh this page and try again.");
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitRegister", getIPad);
		}
	};	

	submitOtpValidate(e) {
		try {
			e.preventDefault();
			this.setState({ otpSubmitted: true });
			let { otpMobileNo, otpCode } = this.state;
			if (otpMobileNo && otpCode && this.handleFormValidation(1, otpMobileNo)) {
				otpMobileNo = "250" + otpMobileNo.substring(1, otpMobileNo.length);
				otpCode = otpCode.replace(/'/g, "''");
				this.props.otpAuthentication(this.props.appvariables.countryCode + otpMobileNo, otpCode, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitOtpValidate");
		}
	};

	submitSendOtp(e) {
		try {
			e.preventDefault();
			this.setState({ regOtpSubmitted: true });
			let { regMobileNo, regEmailID } = this.state;
			if (regMobileNo && this.handleFormValidation(1, regMobileNo)) {
				this.setState({ isOtpSendNeed: true })
				regMobileNo = "250" + regMobileNo.substring(1, regMobileNo.length);
				regEmailID = regEmailID.replace(/'/g, "''");
				this.props.reSendOtp(this.props.appvariables.countryCode + regMobileNo, regEmailID, 0, this.props.i18n.language);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitSendOtp");
		}
	};
	showMsg = (msg) => {
		this.props.alertError(this.props.t(msg));
	}
	handleFormValidation(flog, data) {
		try {
			//Phone number          
			//var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
			//var mobPattern = /^\d{10}$/;
			var mobPattern = /^[0]\d{10}$/;
			if (flog == 1 && !mobPattern.test(data)) {
				this.showMsg('lblInvalidMobileNo');
				return false;
			}
			if (flog == 5 && data && (data.length < 8 || data.length > 15)) {
				this.showMsg('lblpwdlength');
				return false;
			}
			//Email    
			if (flog == 2 && data && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data))) {
				this.showMsg("lblInvalidEmailID");
				return false;
			}
			//DOB           
			var pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/([0-9]{4})$/;
			if (flog == 3 && !pattern.test(data)) {
				this.showMsg("lblDateFormatMust");
				return false;
			}
			if (flog == 4) { //if (flog == 4 && !mobPattern.test(data)) {
				
				if(data.length != 11){
					//this.showMsg('lblvalidMobileNoLngth');
					this.showMsg('lblvalidRefNoLngth');
					return false;
				}
				// else if(data.substring(0,1) != 0){
				//  this.showMsg('lblInvalidRefMobileNo');
				// }
				//return false;
			}
			return true;
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleFormValidation");
			return false;
		}
	};
	componentDidUpdate() {
		//window.timecalender();
	};
	componentDidMount() {
		//window.docMapVisiblePwd();
	};

	isDate18orMoreYearsOld = (birthday) => {
		var lst = birthday.split('/');
		// it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
		///var optimizedBirthday = birthday.replace(/-/g, "/");	
		var optimizedBirthday = lst[2] + "/" + lst[1] + "/" + lst[0];
		//set date based on birthday at 01:00:00 hours GMT+0100 (CET)
		var myBirthday = new Date(optimizedBirthday + ' 01:00:00');
		// set current day on 01:00:00 hours GMT+0100 (CET)
		var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
		// calculate age comparing current date and borthday
		var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
		if (myAge < 18) {
			return false;
		} else {
			return true;
		}
	}

	render() {
		const { t } = this.props;		
		const { regFirstName, regLastName, regPassword, regConfirmPassword, regEmailID, regMobileNo, regOtpCode, regDob, regReffererMobileNo, regTC, regChkAge, regSubmitted, files } = this.state;		
		const { regOtpSubmitted } = this.state;
		const { otpCode, otpSubmitted } = this.state;
		return (
			<div>
				<Header/>
			<div class="mainContainer foBg1">				
				
	{/* Register Form */}
	
	<div class="formCont" id="userRegForm">
	<form class="login-form">
		<div class="head">
			<h3>{t('lblSignUp')}</h3>
		</div>
		{/* <span class="formInfo">This signup page for players only. Not for Agents! Kindly notify support to login as an</span> */}
		<span class="formInfo">Register as a Player</span>
		<div class="formField">
			<label>Mobile number <b>*</b></label>			
			<input maxLength="11" class="username" type="text" placeholder={`${t('lblMobNoformat')}`} value={this.state.regMobileNo} onChange={this.handleInputChange} name="regMobileNo" />
			{regSubmitted && !regMobileNo && <small>{t('lblRequired')}</small>}
			{regSubmitted && regMobileNo && (!(/[0]\d{10}$/.test(regMobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
		</div>
		<div class="formField">
			<label>Password <b>*</b></label>			
			<input maxLength="15" minLength="8" class="password" type="password" placeholder={`${t('lblPassword')} *`} value={this.state.regPassword} onChange={this.handleInputChange} name="regPassword" />
			<span class="eyeIcon eyeOpen" onClick={(e) => window.docMapVisiblePwd(e)}></span>
			{regSubmitted && !regPassword && <small>{t('lblRequired')}</small>}
		</div>
		<div class="formField">
			<label>Confirm password <b>*</b></label>			
			<input maxLength="15" minLength="8" class="password" type="password" placeholder={`${t('lblConfirmpwd')} *`} value={this.state.regConfirmPassword} onChange={this.handleInputChange} name="regConfirmPassword" />
			<span class="eyeIcon eyeOpen" onClick={(e) => window.docMapVisiblePwd(e)}></span>
			{regSubmitted && !regConfirmPassword && <small>{t('lblRequired')}</small>}
			{regSubmitted && regPassword != regConfirmPassword && <small>{t('lblPasswordMismatch')}</small>}
		</div>

		<div class="formField">
			<label>First Name<b>*</b></label>			
			<input maxLength="20" type="text" placeholder={`${t('lblFirstName')} *`} value={this.state.regFirstName} onChange={this.handleInputChange} name="regFirstName" />
			{regSubmitted && !regFirstName && <small>{t('lblRequired')}</small>}
		</div>

		<div class="formField">
			<label>{t('lblLastName')}<b>*</b></label>			
			<input maxLength="20" type="text" placeholder={`${t('lblLastName')} *`} value={this.state.regLastName} onChange={this.handleInputChange} name="regLastName" />
			{regSubmitted && !regLastName && <small>{t('lblRequired')}</small>}
		</div>

		<div class="formField">
			<label>Email address</label>		
			<input maxLength="50" class="newUserTxt" type="email" placeholder={t('lblEmail')} value={this.state.regEmailID} onChange={this.handleInputChange} name="regEmailID" />
			{regSubmitted && regEmailID && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(regEmailID))) && <small>{t('lblInvalidEmailID')}</small>}
		</div>

		<div class="formField">
			<label>Referral code<b>*</b></label>
			{/* <input maxLength="11" class="newUserTxt" type="text" placeholder={`${t('lblReferalmobno')}(${t('lblMobNoformat')})`} value={this.state.regReffererMobileNo} onChange={this.handleInputChange} name="regReffererMobileNo" />*/}
			<input maxLength="11" class="newUserTxt" type="text" placeholder={t('lblReferalcode')} value={this.state.regReffererMobileNo} onChange={this.handleInputChange} name="regReffererMobileNo" />
			{regSubmitted && !regReffererMobileNo && <small>{t('lblRequired')}</small>}
			{/* {regSubmitted && regReffererMobileNo && (!(/[0]\d{10}$/.test(regReffererMobileNo))) && <small>{t('lblInvalidRefMobileNo')}</small>} */}
			{regSubmitted && regReffererMobileNo && (!(/\d{11}$/.test(regReffererMobileNo))) && <small>{t('lblvalidRefNoLngth')}</small>}
		</div>	

		
		
		<div class='formField file'>		
		  <FileBase64 multiple={ true }  onDone={ this.getFiles.bind(this) } />
		  <small>Upload Player image size of 200 kb with the format of like .png / .jpg / .webp</small>
		</div>

		<div class="termsAndCond">
										<input type="checkbox" class="checkbox" value="Terms and Conditions" defaultChecked={this.state.regTC} onChange={this.handleInputChange} name="regTC" />
										<span class="agree">{t('lblTermsAndCondition')}<Link target="_blank" to="/termscondition" style={{ float: "none" }}>{t('lblTermsAndCondition1')}.</Link></span>
									</div>
									{regSubmitted && !regTC && <small style={{ color: "red", marginLeft: "20px" }}>{t('lblRequired')}</small>}

									<div class="termsAndCond">
					<input type="checkbox" class="checkbox" value="Age" defaultChecked={this.state.regChkAge} onChange={this.handleInputChange} name="regChkAge" />
					<span class="agree"><small>18+</small></span>
				</div>
				{regSubmitted && !regChkAge && <small style={{ color: "red", marginLeft: "20px" }}>{t('lblRequired')}</small>}

			<div class="formField">			
			<button class="LoginBtn" onClick={this.submitRegister}>{t('lblRegister')}</button>			
		</div>

		{/* <a class="forGotPass" id="forgotsignup" onClick={() => { this.props.setPage(2); history.push('/login') } }>{t('lblForgetpwd')}?</a> */}
		
		<p class="message">Already have an account? <a id="loginpg" onClick={() =>{ this.props.setPage(0); history.push('/login') }}>Log In</a></p>
	</form>
</div>	
		
	</div>
	</div>			
		)
	}
}

function mapStateToProps(state) {
	const { loggingIn, appvariables, pageFlog, otpMobileNo } = state.loginReducer;
	const { ClntIPAddr } = state.commonReducer;
	return { loggingIn, appvariables, pageFlog, otpMobileNo, ClntIPAddr };
}

const mapActionToProps = {	
	login: loginActions.login,
	loginSkip: loginActions.loginSkip,
	logout: loginActions.logout,
	setPage: loginActions.setPage,
	registration: loginActions.registration,	
	otpAuthentication: loginActions.otpAuthentication,
	reSendOtp: loginActions.reSendOtp,
	alertError: alertActions.error
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Reg))