import { wait } from "@testing-library/react";
var CryptoJS = require("crypto-js");

export const commonClass = {
    generateBetSlip,
    searchBetSlip,
    checkRptDateFilter,
    GetIPAddress,
    writeLog,    
    handleResponse,
    requestOptions,
    Encryption,    
    Decryption
};

function searchBetSlip(mybet, gamegroupID) {
    return generateBetSlipQW(mybet, gamegroupID);
};

function generateBetSlip(mybet, gamegroupID) {
    if (gamegroupID == "4")
        return generateBetSlipQW(mybet); //return generateBetSlipLotto(mybet);
    else if (gamegroupID == "5")
        return generateBetSlipQW(mybet);
        else if (gamegroupID == "3")
        return generateBetSlipQW(mybet);
    else
        return null;
};

function generateBetSlipLotto(mybet) {
    let betArr = mybet.split('~');
    if (betArr[0] != 0) {
        return { status: betArr[0], msg: betArr[1] };
    }
    let tempStr = betArr[9].split('|');
    //let tempStr1 = tempStr[0].split('@');
    //let tempStr2 = tempStr[1].split('|');
    let tempStr1 = [];
    tempStr.forEach(el => {
        let tempStr2 = el.split('@');
        if (tempStr2.length > 1) {
            tempStr1.push({ no: tempStr2[0], amt: tempStr2[1] });
        }
    });
    let _bMsg="";
if( betArr.length>16)
{
    _bMsg=betArr[16];
}
    let betObj = {
        Id: betArr[2],
        TransDate: betArr[3],
        TransTime: betArr[4],
        DrawNo: betArr[5],
        DrawDate: betArr[6],
        DrawTime: betArr[7],
        Ball: tempStr1,
        TotalStakeAmount: betArr[10],
        GameName: betArr[13],
        status: betArr[0],
        msg: "success",
        balance: betArr[11],
        winbalance: betArr[12],
        ticketStatus: null,//betArr.length > 15 ? betArr[15] : "-"",
        familyID: 4,
        reprint: true,
        buttomMsg:_bMsg,
        winningAmt : 0,
        currentcommissions : 0,
        availabeCommissions : 0
    }
    return betObj;
};

function generateBetSlipQW(mybet) {
    let betArr = mybet.split('~');
    if (betArr[0] != 0) {
        return { status: betArr[0], msg: betArr[1] };
    }
    mybet = mybet.substring(2, mybet.length - 1);
    let tempStr = mybet.split('@');
    let tempStr1 = tempStr[1].split('~');
    let tempStr2 = tempStr[0].split('|');
    let tempStr3 = [];
    tempStr2.forEach(ele => {
        let _betValue = ele.split('~');
        if (_betValue.length > 1) {
            tempStr3.push({
                Id: _betValue[0],
                BallNo: _betValue[2].split(' ')[2],
                StakeValue: parseFloat(_betValue[3]),
                SubTotal: 0, //parseFloat(_betValue[4]),
                OddsValue:0, //_betValue[5],
                GameTime: _betValue[4] + " " + _betValue[5],
                GameID: parseInt(_betValue[6]),
                TransDateTime: _betValue[8],
                GameName: _betValue[10],
            });
        }
    });
let _bMsg="";
//if( tempStr.length>1)
//{
  //  _bMsg=tempStr[1];
//}

_bMsg=tempStr1[3];

    let betObj = {
        betLst: tempStr3,
        status: betArr[0],
        msg: "success",
        balance: tempStr1[0],
        winbalance: tempStr1[1],        
        ticketStatus: null, //tempStr2[tempStr2.length - 1],
        familyID: 4,
        reprint: true,
        buttomMsg:_bMsg
    }
    return betObj;
};

function requestOptions(data,isEncription=true) {
    let _auth = process.env.REACT_APP_API_USERNAME + ':' + process.env.REACT_APP_API_PASSWORD;
    if (data) {
        let en_data = isEncription==true?Encryption(data):data;
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + _auth,
            },
            body: JSON.stringify({ "data": en_data })
        };
    }
    else {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + _auth,
            }
        };
    }
};

function handleResponse(response) {
    try {
        return response.text().then(text => {
            if (!response.ok) {
                //if (response.status === 401) {
                //auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
                //}

                //const error = (data && data.message) || response.statusText;
                //const error ={message:( (data && data.message) || response.statusText)};
                const error = { message: response.statusText };
                return Promise.reject(error);
            }
            const data = text && JSON.parse(text);
            let en_data = Decryption(data);
            return en_data;
        });
    } catch (ex) {
        commonClass.writeLog(ex);
    }
};

function checkRptDateFilter(fr, to) {
    let datepattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    if (!isValidDate(fr)) {
        return false;
    } if (!isValidDate(to)) {
        return false;
    }
    var fr1 = new Date(new Date(fr.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
    var to1 = new Date(new Date(to.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
    if (fr1 > to1) {
        alert("Invalid Date Filter");
        return false;
    }
    fr1.setHours(0, 0, 0, 0);
    to1.setHours(0, 0, 0, 0);
    if (fr1 > to1 || fr1 < to1) {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        var cdate = new Date(new Date(ss.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var to = ("0" + to1.getDate()).slice(-2) + "/" + ("0" + (to1.getMonth() + 1)).slice(-2) + "/" + to1.getFullYear();
        var fdate = new Date(new Date(to.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        cdate.setHours(0, 0, 0, 0);
        fdate.setHours(0, 0, 0, 0);
        if (!(cdate > fdate || cdate < fdate)) {
            alert("Current date can't combine with other date!");
            return false;
        }
    }
    return true;
};

//This function validates the date for DD/MM/YYYY format. 
function isValidDate(dateStr) {
    // Checks for the following valid date formats:
    // DD/MM/YYYY
    // Also separates date into month, day, and year variables
    var datePat = /^(\d{2,2})(\/)(\d{2,2})\2(\d{4}|\d{4})$/;
    var matchArray = dateStr.match(datePat); // is the format ok?
    if (matchArray == null) {
        alert("Date must be in dd/MM/yyyy format!")
        return false;
    }
    let month = matchArray[3]; // parse date into variables
    let day = matchArray[1];
    let year = matchArray[4];
    if (month < 1 || month > 12) { // check month range
        alert("Invalid date format!");
        return false;
    }
    if (day < 1 || day > 31) {
        alert("Invalid date format!");
        return false;
    }
    if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31) {
        alert("Month " + month + " doesn't have 31 days!")
        return false;
    }
    if (month == 2) { // check for february 29th
        var isleap = (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0));
        if (day > 29 || (day == 29 && !isleap)) {
            alert("February " + year + " doesn't have " + day + " days!");
            return false;
        }
    }
    return true;  // date is valid
};


let getIp = async () => {
        // Connect ipapi.co with fetch()        
        try{
            let ipresp = await fetch('https://ipapi.co/json/')            

            if (!ipresp.ok) {
                //throw new Error('Data coud not be fetched!')                
            }else{
                return await ipresp.json()
            }
        }
        catch(ex){
            console.log(ex);
        }       
}

let callgetIp = async () => {
    try {                             
        let res1 = await getIp();        
            return res1.ip; 
    } catch (ex) {
        console.log(ex);
    }
    return "";
}

function GetIPAddress() {
     try {
        let ipresps = callgetIp();       
        return ipresps;           
         } catch (ex) {
             console.log(ex);
         }
         return "";
};

function writeLog(ex, methodName = "", IPAddr = "") {
    try {
        let data = "";  
        
        if (IPAddr != "" && IPAddr != undefined && IPAddr != null) {            
            data=JSON.stringify(ex)+" , Method : "+methodName + " , IP : " + IPAddr;
            return fetch(`${process.env.REACT_APP_API_URL}/log/writeLog`, requestOptions(data,false));
        }
        else{
        getIp()
        .then((res) => {
            data=JSON.stringify(ex)+" , Method : "+methodName + " , IP : " + res.ip;
            return fetch(`${process.env.REACT_APP_API_URL}/log/writeLog`, requestOptions(data,false));
        })
        .catch((e) => {            
            data=JSON.stringify(ex)+" , Method : "+methodName;
            return fetch(`${process.env.REACT_APP_API_URL}/log/writeLog`, requestOptions(data,false));
          }) 
        }
    } catch (ex) {
        console.log(ex);        
    }
};

var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_KEY);
function Encryption(data) {
    try {
        // var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENC_KEY).toString();
        // return ciphertext;

        var ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(data)), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }).toString();
        return ciphertext;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
    return '';
};

function Decryption(ciphertext) {
    try {
        // var bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_ENC_KEY);
        // let _str=bytes.toString(CryptoJS.enc.Utf8);
        // var decryptedData = JSON.parse(_str);
        // return decryptedData;

        var decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        let _str = decrypted.toString(CryptoJS.enc.Utf8);
        var decryptedData = JSON.parse(_str);
        return decryptedData;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
    return '';
};
