import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../action/accountAction';
import { Link } from 'react-router-dom';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withTranslation } from 'react-i18next';
//import { loginActions } from '../../action/loginAction';

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);

		this.state = {		
			appsts: 1
		}
		const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

		//if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}		
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;
		

		//let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.Gethowtoplay(langflag,3);
    }

	componentDidMount() {
		if(this.state.appsts == "1"){
		document.getElementById('intro').classList.add('activeBrdrLft');
		}
	}

	static getDerivedStateFromProps(props, state) {
        return {            
            gethowtoplay: props.gethowtoplay != undefined ? props.gethowtoplay : {}
        }
    };

	tet=()=> {
		//let ddd='<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p><h2>EXAMPLE:</h2><div className="contntPrizeTble"><div className="contntInrPrizeTble"></div></div>';
		let ddd=this.state.gethowtoplay;
		 let dd= <div className="privacyRhtCont" dangerouslySetInnerHTML={{__html:ddd }}></div>			
		  return dd;
	}
	
	handleDaysChange= (e) => {
         document.getElementById('intro').classList.remove('activeBrdrLft');
		 document.getElementById('intro1').classList.remove('activeBrdrLft');
		 document.getElementById('intro2').classList.remove('activeBrdrLft');
		 document.getElementById('intro3').classList.remove('activeBrdrLft');
		 document.getElementById('intro4').classList.remove('activeBrdrLft');
		 document.getElementById('intro5').classList.remove('activeBrdrLft');
		 document.getElementById('intro6').classList.remove('activeBrdrLft');
		 document.getElementById('intro7').classList.remove('activeBrdrLft');
		 document.getElementById('intro8').classList.remove('activeBrdrLft');

         e.target.classList.add('activeBrdrLft');        
    };

    render() {
        const { i18n } = this.props;
		const { appsts } = this.state;
        return (
			<div>
            <div className="mainContainer">
		{appsts	== 1 && <Header /> }
		<div className="row">
			<div className="container forPadd1">
				<div className="privacyCont">
					<div className="privacyLftCont">
						<h1>Privacy policy</h1>
						{appsts	== 1 && 
						<ul className="contentFlowLinks">
							<li><a id="intro" href="#intro" onClick={this.handleDaysChange}>Introduction</a></li>
							<li><a id="intro1" href="#collection_info" onClick={this.handleDaysChange}>Collection of information</a></li>
							<li><a id="intro2" href="#user_resposone" onClick={this.handleDaysChange}>Users responsibilities</a></li>
							<li><a id="intro3" href="#user_info" onClick={this.handleDaysChange}>Use of infromation</a></li>
							<li><a id="intro4" href="#disclosure_info" onClick={this.handleDaysChange}>Disclosure of information</a></li>
							<li><a id="intro5" href="#info_share" onClick={this.handleDaysChange}>Information you choose to share with third parties</a></li>
							<li><a id="intro6" href="#modify_info" onClick={this.handleDaysChange}>Modification of information</a></li>
							<li><a id="intro7" href="#change_privacypolicy" onClick={this.handleDaysChange}>Changes to this privacy policy</a></li>
							<li><a id="intro8" href="#child_privacy" onClick={this.handleDaysChange}>Children's privacy</a></li>
						</ul>
	}
					</div>
					{this.tet()}
					{/* <div className="privacyRhtCont">
						<h2 id="intro">INTRODUCTION</h2>
							<p>This privacy policy details our commitment to safeguard your information, and the limited way we will use and disclose the information you provide to us. By using our web site/ mobile application, you consent to the collection, use and disclosure of your information by us as set forth in this policy. We may from time to time change this privacy policy so please check this privacy policy regularly for any changes or updates. If you have any questions regarding this privacy policy, then you may contact us at admin@bazookalotto.com. For additional information about the use of our web site/ mobile application, please review the General Terms and Conditions of Use.</p>
						
						
						<h2 id="collection_info">COLLECTION OF INFORMATION</h2>
							<p>There are different ways in which we might obtain information about our users but primarily, information is collected from users at the time of registration on the website. During registration, we ask users for their personal information which may include but are not limited to, name, gender, date of birth, email address, phone number etc. We may also ask you to provide us with some additional information such as payment card details, bank account details, and billing and delivery information (account information) in connection with any transaction or payment services.
							We may also obtain information from other companies that are owned or operated by us, or any other third-party sources, and combine that with the information we collect through our services.
							Like most online services and mobile applications, we may use cookies and other technologies, such as web beacons, web storage, and unique advertising identifiers, to collect information about your activity, browser, and device. We may also use these technologies to collect information when you interact with services, we offer through one of our partners, such as commerce features. Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser cookies through the settings on your browser or device. Keep in mind, though, that removing or rejecting cookies could affect the availability and functionality of our services.</p>
						
							<p>If you intend to use the services on behalf of a business or some other entity, you state that you are authorised to grant all licenses set forth in these terms of use and to agree to these terms on behalf of the business or entity.</p>
						
						<h2 id="user_resposone">USERS RESPONSIBILITIES</h2>
							<p>By using this mobile app/website, you agree that :</p>
							<ul className="marleft">
								<li><p>1.You will use this mobile app/website in accordance with the applicable law and terms of this agreement;</p></li>
								<li><p>2.You will provide accurate and correct personal information;</p></li>
								<li><p>3.You will be solely responsible for any activity that occurs in your Sagenic Tech. account;</p></li>
								<li><p>4.You will not create more than one account for yourself, neither will you create another account if your account has been previously disabled by us, unless you have our written permission to do so;</p></li>
								<li><p>5. You will not share your account details with others;</p></li>
								<li><p>6. You will not use the mobile app for any purpose that is illegal or prohibited in these Terms;</p></li>
								<li><p>7. You will not upload viruses or other malicious code or otherwise compromise the security of the mobile app;</p></li>
								<li><p>8. You will not use any robot or other automated means or interface to access the mobile app or extract other user’s information;</p></li>
								<li><p>9. You will not use the mobile app in a way that could interfere with, negatively affect, or inhibit other users from fully enjoying the mobile app, or that could damage, disable or impair the functioning of the mobile app;</p></li>
								<li><p>10.You will not encourage or promote any activity that violates these Terms.</p></li>
							</ul>
						<h2 id="user_info">USE OF INFROMATION</h2>
							<p>The information we collect is used to:</p>
							<ul className="marleft">
								<li><p>Verify the identity of our users</p></li>
								<li><p>Set up and manage the accounts of our users</p></li>
								<li><p>Prevent fraud or other unauthorized or illegal activity.</p></li>
								<li><p> Monitor and analyze your usage as well as carry out analytical statistics and research on the website to improve and maintain our services</p></li>
								<li><p>Ensure that our website’s content is presented as effectively as possible for you, and to enable you to participate in interactive features of our website like customer service when you choose to do so</p></li>
								<li><p>Develop, operate, improve, deliver, maintain, and protect our products and services.</p></li>
								<li><p> Communicate with you;</p></li>
								<li><p>Send newsletter and other information about our products or services (e.g. e-mail notification of daily winning lottery drawing results or information about becoming a lottery retailer)</p></li>
								<li><p> Enhance the safety and security of our products and services.</p></li>
								<li><p> Use information we’ve collected from cookies and other technology to enhance the services and your experience with them.</p></li>
								<li><p> Enforce our Terms of Service and other usage policies.</p></li>
								<li><p>We will NEVER</p></li>
								<li><p>Sell your personally identifiable information – including your name, address, e-mail address, or credit card information - to any third party.</p></li>
								<li><p> Share your information with third-party advertisers or ad networks.</p></li>
								
							</ul>
						
						<h2 id="disclosure_info">DISCLOSURE OF INFORMATION</h2>
							<p>Sagenic Tech. promises not to disclose your personal information with any third party. However, you agree that Sagenic Tech may disclose and transfer your information to:</p>
						
							<ul className="marleft">
								<li><p>Verify the identity of our users</p></li>
								<li><p> Any law enforcement agencies or other organisations as required by law, regulations, and guidelines or in respect of any investigations, claims, or potential claims brought on or against us or legal action against someone who may be violating our Rights or Terms of Use</p></li>
								<li><p> Banks to enable completion of transactions made on the mobile app/website and also to confirm the credit worthiness of our users.</p></li>
								
							</ul>
						
						
						<h2 id="info_share">INFORMATION YOU CHOOSE TO SHARE WITH THIRD PARTIES</h2>
							<p>The mobile app/website may contain third-party links, third-party integrations, or offer a co-branded or third-party-branded service. By going to those links, using the third-party integration, or using a co-branded or third-party-branded service, you may be providing information (including personal information) directly to the third party, us, or both. You acknowledge and agree that we are not responsible for how those third parties collect or use your information. As always, we encourage you to review the privacy policies of every third-party website or service that you visit or use, including those third parties you interact with through our services.</p>
						<h2 id="modify_info">MODIFICATION OF INFORMATION</h2>
							<p>If you use our products, services, or website, then you have voluntarily consented to our information collection as described in this privacy policy and other written documentation provided to you regarding the products, services, or mobile app. If you do not want us to use your information as described herein or would like to modify your information or remove your information from our databases, then please contact us at admin@bazookalotto.com. However, without the use of your information, we may not be able to provide you the products or services or the same quality of products or services you request. If you request removal of certain information from your account, then we will only retain copies of such information as necessary for us to comply with governmental orders, resolve disputes, troubleshoot problems, enforce any agreement you have entered with us and as otherwise reasonably necessary for our business purposes.</p>
						
						<h2 id="change_privacypolicy">CHANGES TO THIS PRIVACY POLICY</h2>
							<p>Sagenic Tech. may make changes to this privacy policy at any time. The amended privacy policy would be posted on the mobile app/website and become effective immediately. Users are advised to check constantly for new changes.</p>
						
						<h2 id="child_privacy">CHILDREN'S PRIVACY</h2>
							<p>We do not intend for this site/application to be used by children under the age of 18 for any purpose that may be prohibited by applicable federal or state laws and regulations, nor do we seek to collect information about children under the age of 18. If we become aware of a child under the age of 18 that submits Personally Identifiable Information to this site, we will delete the information as soon as we discover it and will not use it for any purpose. We encourage parents or legal guardians to talk with their children about the potential risks of providing information over the Internet.</p>
					</div>	 */}
				</div>		
			</div>
		</div>		
	</div>
	{appsts	== 1 && <Footer /> }
	</div>
        )
    };
}

function mapStateToProps(state) {
    const { gethowtoplay } = state.accountReducer;    
    return { gethowtoplay };
}
const mapActionToProps = {
	Gethowtoplay: accountActions.Gethowtoplay
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PrivacyPolicy))