import { commonClass } from "..//helpers/commonClass"
export const gameService = {
	GetGameFamily,
	GetAvailableGames,
	GetServerTime,
	GetLatestResult,
	LotteryOnlineSale,
	GetCasinoGames
};
//const _apiUrl="http://localhost:2000/api";
//const _apiUrl="http://www.nodetest.com/api";
//const _apiUrl="http://220.225.104.132:8080/api";
const _apiUrl = process.env.REACT_APP_API_URL;

function GetGameFamily(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/games/GameFamily`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				//0~4~Lotto 5/90~90~1~15~50~100000~50000~10000000^500000^50000^20000^2500^2000^1000~50,50000,10$50-100-200-300-500-1000-2000-5000-10000-50000~S2,50000,NAP 2,50^S3,50000,NAP 3,50^S4,50000,NAP 4,50^S5,50000,NAP 5,50$P2,50000,PEM 2,50^P3,50000,PEM 3,50^P4,50000,PEM 4,50^P5,50000,PEM 5,50$B2,50000,Bank 2,50^B3,50000,Bank 3,50^B4,50000,Bank 4,50^B5,50000,Bank 5,50$A2,50000,Against,50$|";
				if (games != null && games.data != null) { games = games.data; }
				if (games != null && games.startsWith("0~")) {

					//games = "0~4~Lotto 5/90~90~1~15~50~100000~50000~10000000^500000^50000^20000^2500^2000^1000~50,50000,10$50-100-200-300-5000-10000-50000~S2,50000,NAP 2,50^S3,50000,NAP 3,50^S4,50000,NAP 4,50^S5,50000,NAP 5,50$P2,50000,PEM 2,50^P3,50000,PEM 3,50^P4,50000,PEM 4,50^P5,50000,PEM 5,50$B2,50000,Bank 2,50^B3,50000,Bank 3,50^B4,50000,Bank 4,50^B5,50000,Bank 5,50$A2,50000,Against,50$|";
					gamefamily = [];
					games = games.replace("0~", "");
					let strArr = games.split('|');

					strArr.forEach(elementobj => {
						if (elementobj !== "") {
							let element = elementobj.split('~');
							let minSt=50;
							let maxSt=50000;
							let maxStAmt=100000;
							let prizeSt="";
							let StakeEnter="";
							let Option_enable="";							

							if(element.length>6)
							{
								minSt=parseInt(element[5]);
								maxSt=parseInt(element[7]);
								maxStAmt=parseInt(element[6]);
							if(element.length>8)
							{
								prizeSt=element[8];
							}
							}
							
							if(element.length> 9)
							{
								StakeEnter=element[9].split('$')[1]; //StakeEnter=element[9].split('$')[0];

								Option_enable=element[10];
							}

							gamefamily.push(
								{
									id: element[0],
									name: element[1],
									ball: parseInt(element[2]),
									minSelect: parseInt(element[3]),
									maxSelect: parseInt(element[4]),
									minStakePerTrans: minSt,
									maxStakePerTrans: maxSt,
									maxStakeAmountPerOneBetOption: maxStAmt,
									prizeInfo:prizeSt,
									extraBall: 0,
									StakeEnter: StakeEnter,
									Option_enable : Option_enable									
								}
							);
						}
					});

				}

				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAvailableGames(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/games/GetAvailableGames`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				if (games != null && games.data != null) { games = games.data; }
				gamefamily = games;
				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetLatestResult(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/quickwin/latestResult`, commonClass.requestOptions(data))
			 .then(commonClass.handleResponse)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};


function GetServerTime(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// };
		return fetch(`${_apiUrl}/games/GetServerTime`, commonClass.requestOptions())
			 .then(commonClass.handleResponse)
			.then(serverTime => {
				return serverTime.data;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function LotteryOnlineSale(data) {
	try {
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: {
		// 		'Content-Type': 'application/json'
		// 	},
		// 	body: JSON.stringify({ "data": data })
		// };
		return fetch(`${_apiUrl}/games/onlineSale`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				if (games != null && games.data != null) { games = games.data; }
				gamefamily = games;
				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};
function GetCasinoGames(data) {
	try {	
		return fetch(`${_apiUrl}/games/GetCasinoGames`, commonClass.requestOptions(data))
			 .then(commonClass.handleResponse)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};



function handleResponse_old(response) {
	try {
		return response.text().then(text => {
			const data = text && JSON.parse(text);
			if (!response.ok) {
				if (response.status === 401) {
					// auto logout if 401 response returned from api
					//logout();
					// location.reload(true);
				}
				const error = (data && data.message) || response.statusText;
				return Promise.reject(error);
			}
			return data;
		});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}