import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import {lottoActions} from '../../../action/lottoAction';
class Left extends Component {
	constructor(props) {
		super(props);
	}
	static getDerivedStateFromProps(props, state) {
		return {
			gameFamily: props.games.filter(a => a.id == 4)
		};
	};
	render() {
		const { t } = this.props;
        this.props.currentAvailableDraws.map((y, indy)=>{
            if(y.LotName.endsWith("Awoof Mach")){y.iny=6}
            else if(y.LotName.endsWith("Awoof")){y.iny=1}

            else if(y.LotName.endsWith("Cash Mach")){y.iny=7}
            else if(y.LotName.endsWith("Cash")){y.iny=2}

            else if(y.LotName.endsWith("Billionaire Mach")){y.iny=3}
            else if(y.LotName.endsWith("Billionaire")){y.iny=8}

            else if(y.LotName.endsWith("Hammer Mach")){y.iny=9}
            else if(y.LotName.endsWith("Hammer")){y.iny=4}

            else if(y.LotName.endsWith("Jackpot Mach")){y.iny=10}
            else if(y.LotName.endsWith("Jackpot")){y.iny=5}

            else if(y.GameGroupId == 5){y.iny=(indy+1)}
        });

		return (
			
        <div className="col-md-3">
            <div className="lft bg1 scrollbar">		                
			{this.props.currentAvailableDraws && this.props.currentAvailableDraws.length > 0 ? this.props.currentAvailableDraws.map((x, indx) =>

                <div className="NxtDraw"> 
				{indx == 0 &&
				<div className="NxtDrawName">
                        <span>{x.GameGroupDesc.split(" ")[0]}</span>
                        <h4>{x.GameGroupDesc.split(" ")[1]}</h4>
                    </div>
					}                    
                    <div className="NxtDrawInner">               
                        <ul>
                        {x.GameGroupId == 5 &&
                        <li class={`ghanaGameBg${x.iny}`}>
									<div class="GameContent">
										<div class="drwLft"><a href="#bet1" att="0" class="drawBallswrap"><span class="drawBalls"><img src={`./assets/img/ghana/ghanaBall${x.iny}.png`} /></span></a></div>
										<div class="drwRht">
											<div class={`drwLftTop gahanaTxtClr${x.iny}`}>{x.LotName}</div>
											<div class="drwLftBottom">
												<div class="drwLftBox"><span>{x.DrawDate}</span><small>DRAW DATE</small></div>
												<div class="drwLftBox"><span>{x.DrawTime}</span><small>DRAW TIME</small></div>
											</div>
											<a onClick={() => { this.props.selectDraw(x) }} key={`draw_${x.DrawID}`} class={`playgame ghanabtn${x.iny}`}>PLAY NOW</a>
										</div>
									</div>
								</li>
    } 
    {x.GameGroupId == 4 &&
        <li class={`borColor${x.iny}`}>
        <div class="GameContent">
            <div class="drwLft"><a href="#bet1" att="0" class="drawBallswrap"><span class="drawBalls"><img src={`./assets/img/games/game${x.iny}.png`} /></span></a></div>
            <div class="drwRht">
                <div class={`drwLftTop txtColor${x.iny}`}>{x.LotName}</div>
                <div class="drwLftBottom">
                    <div class="drwLftBox"><span>{x.DrawDate}</span><small>DRAW DATE</small></div>
                    <div class="drwLftBox"><span>{x.DrawTime}</span><small>DRAW TIME</small></div>
                </div>
                <a onClick={() => { this.props.selectDraw(x) }} key={`draw_${x.DrawID}`} class={`playgame gamebtn${x.iny}`}>PLAY NOW</a>
            </div>
        </div>
        </li>
    }
                        </ul>                        
                        </div>
                    </div>
	):
	<div className="NxtDraw">
                    <div className="NxtDrawName">
                        <span>Lotto Game</span>
                        <h4>5/90</h4>
                    </div>
                    <div className="NxtDrawInner">
                        <ul className="scrollbar">
                            <li class="borColor1">	
                            <div class="GameContent">
								<div class="drwLft"><a href="#bet1" att="0" class="drawBallswrap"><span class="drawBalls"></span></a></div>
									<div class="drwRht">
										<div class="drwLftTop txtColor1"></div>
											<div class="drwLftBottom">
												<div class="drwLftBox"><span>{t('lblNoMoreDraws')}</span><small></small></div>												
										</div>											
									</div>
							    </div>
                            </li>                        
                        </ul>                        
                        </div>
                    </div>
	}
           
            </div>
        </div>
        
        
    
		);
	}
}


function mapStateToProps(state) {
	const { user } = state.loginReducer;
	const { currentDraw } = state.lottoReducer;
	const { games } = state.gameReducer;
	return { user, currentDraw, games };
}

const mapActionToProps = {
	//SetCurrentDraw:lottoActions.SetCurrentDraw,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Left))