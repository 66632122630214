import { commonClass } from "..//helpers/commonClass"
export const accountService = {
	getbetHistory,
	getAccountHistory,
    addMoney,
    withdrawMoney,
	getAccountSummary,
	getResultReport,
	getResentResult,
	getMyNetwork,
	getMyNetworkCommission,
	GetlstTierNetwork,
	getAccountActivity,
	ReprintTicket,	
	gethowtoplay
};
//const _apiUrl="http://localhost:2000/api";
//const _apiUrl="http://www.nodetest.com/api";
//const _apiUrl="http://220.225.104.132:8080/api";
const _apiUrl=process.env.REACT_APP_API_URL;

function getbetHistory(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getBetBistory`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getAccountHistory(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getAccountHistoryReport`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function addMoney(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/addMoney`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function withdrawMoney(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/withdrawMoney`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getAccountSummary(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getAccountSummary`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getResentResult(data) {
	try{	
	return fetch(`${_apiUrl}/account/getResentResult`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getResultReport(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getResultReport`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function GetlstTierNetwork(data) {
	try{	
	return fetch(`${_apiUrl}/account/GetlstTierNetworkdtls`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex,"GetlstTierNetwork");
	}
};

function getMyNetworkCommission(data) {
	try{	
	return fetch(`${_apiUrl}/account/getMyNetworkCommission`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {	
			let NetworkPlayers = null;
				if (user != null && user.data != null) { user = user.data; }
				if (user != null && user.startsWith("0~")) {
					NetworkPlayers = [];
					user = user.replace("0~", "");
					let strArr = user.split('|');
					strArr.forEach(elementobj => {
						if (elementobj !== "") {
							let element = elementobj.split('~');
							let lstrSaleComm="";
							let lstrWinComm="";
							let lstrNTWPLlevel="";
							let lstrNetworkMob="";	
							
							NetworkPlayers.push(
								{
									lstrNetworkMob: element[0],									
									lstrSaleComm: element[1],
									lstrWinComm: element[2],
									lstrNTWPLlevel: element[3]
								}
							);							
						}
					});
				}
				else if (user != null && user.startsWith("1~")) {
					NetworkPlayers = [];
					user = user.replace("1~", "");
					NetworkPlayers.push({lstrError : user});
				}
			return NetworkPlayers;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getMyNetwork(data) {
	try{
	return fetch(`${_apiUrl}/account/getMyNetwork`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {	
			let NetworkPlayers = null;
				if (user != null && user.data != null) { user = user.data; }
				if (user != null && user.startsWith("0~")) {
					NetworkPlayers = [];
					user = user.replace("0~", "");
					let strArr = user.split('|');
let lstrTotComm="";
					strArr.forEach(elementobj => {
						if (elementobj !== "") {
							let element = elementobj.split('~');
							let lstrSaleComm="";
							let lstrWinComm="";
							let lstrTierlevel="";							

							if(element[0] == "TotalCommission")
							{
								lstrTotComm = element[1];
								NetworkPlayers.push(
									{								
										lstrTotComm : lstrTotComm
									}
								);																								
							}
							else{
								NetworkPlayers.push(
									{
										lstrTierlevel: element[1],
										lstrSaleComm: element[3],
										lstrWinComm: element[4]										
									}
								);
							}
						}
					});

				}	
			return NetworkPlayers;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function getAccountActivity(data) {
	try{
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: {
	// 		'Content-Type': 'application/json'
	// 	},		
	// 	body: JSON.stringify({"data":data})
	// };
	return fetch(`${_apiUrl}/account/getAccountActivityReport`, commonClass.requestOptions(data))
		.then(commonClass.handleResponse)
		.then(user => {		
			return user;
		});
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function ReprintTicket(data) {
	try{
    // const requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ "data": data })
    // };
    return fetch(`${_apiUrl}/account/getReprintTicket`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function gethowtoplay(data) {
	try{    
    return fetch(`${_apiUrl}/account/getHowtoplay`, commonClass.requestOptions(data))
        .then(commonClass.handleResponse)
        .then(games => {
            let gamefamily = null;
            if (games != null && games.data != null) { games = games.data; }
            gamefamily = games;
            return gamefamily;
        });
	}
	catch (ex) {		
		commonClass.writeLog(ex);
	}
};

function handleResponse_old(response) {
	try{
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				//auto logout if 401 response returned from api
				//logout();
				//location.reload(true);
			}
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
catch (ex) {		
	commonClass.writeLog(ex);
}
};
