export const appConstants={
    LOGIN_REQUEST : 'LOGIN_REQUEST',
    LOGIN_SUCCESS : 'LOGIN_SUCCESS',
    LOGIN_ERROR : 'LOGIN_ERROR',
    LOGOUT : 'LOGOUT',

    REGISTER_REQUEST : 'REGISTER_REQUEST',
    REGISTER_SUCCESS : 'REGISTER_SUCCESS',
    REGISTER_ERROR : 'REGISTER_ERROR',

    SUCCESS: 'ALERT_SUCCESS',
	ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',
    
    GET_GAMEFAMILY_SUCCESS : 'GET_GAMEFAMILY',
    GET_GAMEFAMILY_ERROR : 'GET_GAMEFAMILY',

    SET_GAMEFAMILY: 'SET_GAMEFAMILY',

    //GET_GAME_SUCCESS: 'GET_GAME_SUCCESS',
    //GET_GAME_ERROR: 'GET_GAME_ERROR',

    GET_AVAILDRAWS_SUCCESS:"GET_AVAILDRAWS_SUCCESS",
    GET_AVAILDRAWS_ERROR:"GET_AVAILDRAWS_ERROR",

    GET_SERVERTIME_SUCCESS:"GET_SERVERTIME_SUCCESS",
    GET_SERVERTIME_ERROR:"GET_SERVERTIME_ERROR",
    GET_IPADDRESS:"GET_IPADDRESS",

    SET_DRAW:"SET_DRAW",

    ADD_CARTITEM: 'ADD_CARTITEM',    
    UPDATE_CARTITEM: 'UPDATE_CARTITEM',
    REMOVE_CARTITEM: 'REMOVE_CARTITEM',
    CLEAR_CARTITEM: 'CLEAR_CARTITEM',
    REMOVE_CLOSEDDRAW_CARTITEM: 'REMOVE_CLOSEDDRAW_CARTITEM',

    BUY_SUCCESS: 'BUY_SUCCESS',
    BUY_ERROR:'BUY_ERROR',
    LOADING:'LOADING',
    LOADED:'LOADED',

    GET_BALANCE:'GET_BALANCE',

    GET_BET_HISTORY:'GET_BET_HISTORY',
    GET_ACCOUNT_HISTORY:'GET_ACCOUNT_HISTORY',
    GET_ACCOUNT_HISTORY_Mob:'GET_ACCOUNT_HISTORY_Mob',
    GET_ACCOUNT_ACTIVITY:'GET_ACCOUNT_ACTIVITY',
    GET_ACCOUNT_ACTIVITY_Mob:'GET_ACCOUNT_ACTIVITY_Mob',
    GET_RESULT_REPORT:'GET_RESULT_REPORT',
    GET_Recent_RESULT:'GET_Recent_RESULT',
    GET_MyNetwork_REPORT:'GET_MyNetwork_REPORT',
    GET_MyNetwork_LstTier:'GET_MyNetwork_LstTier',
    GET_ACCOUNT_SUMMARY:'GET_ACCOUNT_SUMMARY',
    ADD_MONEY_SUCCESS:'ADD_MONEY_SUCCESS',
    WITHDRAW_MONEY_SUCCESS:'WITHDRAW_MONEY_SUCCESS',
    SET_PAGE_MENU:'SET_PAGE_MENU',
    UPDATE_STAKE_ALL:"UPDATE_STAKE_ALL",

    GET_LATEST_RESULT_SUCCESS:"GET_LATEST_RESULT_SUCCESS",
    GET_LATEST_RESULT_ERROR:"GET_LATEST_RESULT_ERROR",
    GET_CURRENT_RESULT:"GET_CURRENT_RESULT",
    GET_USER_PROFILE:"GET_USER_PROFILE",
    GET_HOWTOPLAY:"GET_HOWTOPLAY",

    GET_CASINO_GAMES:"GET_CASINO_GAMES",

}
