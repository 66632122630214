import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../action/accountAction';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { loginActions } from '../../action/loginAction';
import { withTranslation } from 'react-i18next';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            mobileNo: '',
            email: '',
            message: '',
            submitted: false,
            appsts: 1,
        }

        const queryParams = window.location.search;
		let fkg = 1;
		let intflsts = 1;
		let langflag = 1;

		if(queryParams.includes('formflag')){
			fkg = queryParams.split('=')[1];
			langflag = fkg.split('~')[0];
			intflsts = fkg.split('~')[1];
		}

        //if(intflsts == "1"){ //web
          //  this.props.i18n.language == "en" ? langflag = 1 : langflag = 2;
        //}
		
		this.setState({								
			appsts: intflsts // 1 -web, 2 -mobapp
		});

		this.state.appsts = intflsts;

    //let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
		this.props.Gethowtoplay(langflag,5);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.submitCustomerQuery = this.submitCustomerQuery.bind(this);
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name.toLowerCase().includes("mobile") && value && (/^[0-9\b]+$/.test(value)) == false) {
            return;
        }
        this.setState({
            [name]: value
        });
    };

    submitCustomerQuery(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        let { firstName, lastName, mobileNo, email, message } = this.state;
        if (mobileNo && firstName && message) {
            mobileNo = "250" + mobileNo.substring(1, mobileNo.length);
            email = email.replace(/'/g, "''");
            message = message.replace(/'/g, "''");
            firstName = firstName.replace(/'/g, "''");
            lastName = lastName.replace(/'/g, "''");
            this.props.saveCustomerComplaint(firstName + lastName, this.props.appvariables.countryCode + mobileNo, email, message,this.props.i18n.language);
            this.setState({
                firstName: '',
                lastName: '',
                mobileNo: '',
                email: '',
                message: '',
                submitted: false,
            })
        }
    };

    componentDidMount() {
        //window.scrollTo(0,0);
    };

    static getDerivedStateFromProps(props, state) {
        return {            
            gethowtoplay: props.gethowtoplay != undefined ? props.gethowtoplay : {}
        }
    };
    
    tet=()=> {
      //let ddd='<p>BazookaLotto allows all active players to earn commissions by building an affiliate network through referring other players to register on www.bazookalotto.com(or our App), by using the referrer’s mobile phone number as their reference number (referral code). The example below explains how the program works and how you as a player can effectively build your own affiliate network, and you effectively become the principal agent of thethree-tier network of referrals that fall below you.</p><h2>EXAMPLE:</h2><div className="contntPrizeTble"><div className="contntInrPrizeTble"></div></div>';
      let ddd=this.state.gethowtoplay;
       let dd= <div className="col-md-4" dangerouslySetInnerHTML={{__html:ddd }}></div>			
        return dd;
    }

    render() {
        const { t } = this.props;
        const { firstName, lastName, mobileNo, email, message, submitted, appsts } = this.state;
        return (
            <div>
            <div className="mainContainer">
                {appsts	== 1 && <Header /> }
                <div className="contactUsSec bg2 forPadd1">
                <div class="container">
                    <div>
                    {this.tet()}
                    {/* <div className="contactUsDetls contUsBxClr">                        
                    <h1>{t('lblGetIntouch')}</h1>
                        <div class="contactBtm">                            
                        <p>Bazooka Lotto
								14th Floor Necom house Marina,
								Lagos Nigeria </p>
                        </div>
                        <div class="contactBtm2">
                            <p>{t('lblSalesenquriy')} / {t('lblBecanagt')}</p>                            
                            <p>Website : www.bazookalotto.com</p>
                        </div>
                        <div class="contactBtm2">
                            <p>{t('lblcustomerservice')}</p>
                            <p>{t('lblmobno')} : 07007070070 </p>
                            <p>{t('lblEmail')} : support@bazookalotto.com </p>
                        </div>
                    </div> */}
                    </div>
                    <div class="col-md-8">
                    <div className="col-md-12 col-sm-12 col-xs-12 contUsBxClr">
                    <form className="contactForm" name="contactForm" id="contactForm" method="post" novalidate="novalidate">
                                <div class="form-group row">
                                    <div class="col-md-6 field">
                                        <label>{t('lblFirstName')} *</label>
                                        <input maxLength="20" type="text" name="firstName" class="form-control required" aria-required="true" value={this.state.firstName} onChange={this.handleInputChange} />
                                        {submitted && !firstName && <small>{t('lblRequired')}</small>}
                                    </div>

                                    <div class="col-md-6 field">
                                        <label>{t('lblLastName')}</label>
                                        <input type="text" maxLength="20" name="lastName" class="form-control required" aria-required="true" value={this.state.lastName} onChange={this.handleInputChange} />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 field">
                                        <label>{t('lblmobno')} *</label>
                                        <input maxLength="15" minlength="11" type="text" name="mobileNo" class="form-control required" aria-required="true" value={this.state.mobileNo} onChange={this.handleInputChange} />
                                        {submitted && !mobileNo && <small>{t('lblRequired')}</small>}
                                        {submitted && mobileNo && (!(/\d{11}$/.test(mobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
                                    </div>
                                    <div class="col-md-6 field">
                                        <label>{t('lblEmail')}</label>
                                        <input maxLength="50" type="email" name="email" class="form-control required" aria-required="true" value={this.state.email} onChange={this.handleInputChange} />
                                        {submitted && email && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) && <small>{t('lblInvalidEmailID')}</small>}
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12 field">
                                        <label>{t('lblDescription')} *</label>
                                        <textarea maxLength="140" name="message" cols="10" rows="5" class="form-control required" aria-required="true" value={this.state.message} onChange={this.handleInputChange}></textarea>
                                        {submitted && !message && <small>{t('lblRequired')}</small>}
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-12 field">
                                        <button class="btn btn1" name="send" type="button" onClick={this.submitCustomerQuery}>{t('lblSubmit')}</button>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
                </div>                
            </div>
            {appsts	== 1 && <Footer /> }
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { appvariables } = state.loginReducer;
    const { gethowtoplay } = state.accountReducer;    
    return { appvariables, gethowtoplay }
}
const mapActionToProps = {
    saveCustomerComplaint: loginActions.saveCustomerComplaint,
    Gethowtoplay: accountActions.Gethowtoplay
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Contact))