import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';
class AccHistMob extends Component {

    constructor(props) {
        super(props);
        this.state = {         
            PLID: 0,
        } 
        
        const queryParams = window.location.search;
		let MobplayerID = 1;		

		if(queryParams.includes('PLID')){
			MobplayerID = queryParams.split('=')[1];			
		}

        this.setState({								
			PLID: MobplayerID
		});

		this.state.PLID = MobplayerID;
    }

    getRecord = async (flg) => {
        let getIPad = "";
        if (flg != 1) {
            this.state.startDate = document.getElementById("date-time1").value;
            this.state.endDate = document.getElementById("date-time2").value;
        }

        if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
            getIPad = this.props.ClntIPAddr;
        }
        else{
            getIPad = await commonClass.GetIPAddress();					
        }

        let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

        let _flog=  commonClass.checkRptDateFilter(this.state.startDate,this.state.endDate)
        if(_flog)
        this.props.GetAccountHistory(this.state.PLID, this.state.startDate, this.state.endDate,intlangflag,getIPad);
    };
    reSetFilter() {
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };

    static getDerivedStateFromProps(props, state) {
        return {
            AccHistMob: props.AccHistMob,
        }
    };

    componentDidMount() {
        window.timecalender();
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        this.setState({
            startDate: ss,
            endDate: ss
        });
        this.state.startDate = ss;
        this.state.endDate = ss;
        this.getRecord(1);
    };


    render() {
        //window.applyRptAccordion();
        const { t } = this.props;
        return (
            <section id="cTab11" class="tabCont">
                <div className="accountDetls forpadd3">
                <div className="detailsHead">
                    <h2>{t('lblAccountDetails')}</h2>
                    </div>
                    <div class="HstryDetls">
                        <ul>
                            <li class="dateCont">
                                <div class="HstryDetlsCont">
                                    <p>{t('lblfrom')} :</p>
                                    <input class="startDate white hasDatepicker" type="text" value={this.state.startDate} id="date-time1" name="startDate" placeholder="2017-06-10" onChange={(date) => this.setState({ startDate: date.target.value })} />
                                </div>
                                <div class="HstryDetlsCont">
                                    <p>{t('lblto')} :</p>
                                    <input class="endDate white hasDatepicker" type="text" value={this.state.endDate} id="date-time2" name="endDate" placeholder="2017-06-11" onChange={(date) => this.setState({ endDate: date.target.value })} />
                                </div>
                            </li>
                            <li class="BtnCont">
                                <a href="#" class="btn1" onClick={() => { this.getRecord() }}>{t('lblView')}</a>
                                <a href="#" class="btn1" onClick={() => { this.reSetFilter() }}>{t('lblreset')}</a>
                            </li>
                        </ul>
                    </div>
                    
                        <div class="trstractionHstryDetls">
                            <div class="trstractionHstryInrCont">
                            <div className="booking scrollbar">
                                <table>
                                <tbody>
                                    <tr>
                                        <th className='Col13'>{t('lblPlayername')}</th>                                        
                                        <th className='Col10'>{t('lblPlayerMobile')}</th>
                                        <th className='Col12'>{t('lblHierLevel')}</th>
                                        <th className='Col14'>{t('lblSoldAmt')} ({this.props.appvariables.currency})</th>
                                        <th className='Col12'>{t('lblCommAmt')} ({this.props.appvariables.currency})</th>
                                    </tr>
                                    {this.state.AccHistMob && this.state.AccHistMob.status == 0 && this.state.AccHistMob.data.map((x, idx) =>
                                        <tr key={'arpt_' + idx}>
                                            <td>{x.PlayerName}</td>
                                            <td>{x.MobileNo}</td>
                                            <td>{x.Tier}</td>
                                            <td>{x.Sold}</td>
                                            <td>{x.Commission}</td>
                                        </tr>
                                    )}
                                    {this.state.AccHistMob && this.state.AccHistMob.status != 0 &&
                                        <tr>
                                            <td colspan="5">{this.state.AccHistMob.msg}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>                               

                   <div class="trstractionHstryDetls">
                            <div class="trstractionHstryInrCont">
                            <div className="booking scrollbar">
                                <table>
                                <tbody>
                                    <tr>
                                        <th className='Col12'>{t('lblHierLevel')}</th>
                                        <th className='Col10'>{t('lblRefCount')}</th>
                                        <th className='Col14'>{t('lblSoldAmt')} ({this.props.appvariables.currency})</th>
                                        <th className='Col12'>{t('lblCommAmt')} ({this.props.appvariables.currency})</th>
                                    </tr>
                                    {this.state.AccHistMob && this.state.AccHistMob.status == 0 && this.state.AccHistMob.data1.map((x, idx) =>
                                        <tr key={'arpt_' + idx}>
                                            <td>{x.Level}</td>
                                            <td>{x.RefCount}</td>                                            
                                            <td>{x.SoldAmount}</td>
                                            <td>{x.SalesComm}</td>
                                        </tr>
                                    )}
                                    {this.state.AccHistMob && this.state.AccHistMob.status != 0 &&
                                        <tr>
                                            <td colspan="5">{this.state.AccHistMob.msg}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        )
    }
}
function mapStateToProps(state) {
    const { AccHistMob } = state.accountReducer;
    const { user, appvariables } = state.loginReducer;
    return { AccHistMob, user, appvariables };
}

const mapActionToProps = {
    GetAccountHistory: accountActions.GetAccountHistory,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AccHistMob))