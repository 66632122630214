import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';

class SocialResponsibility extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //window.scrollTo(0, 0);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    <div class="PressMeetContainter">
                        <h1>{t('lblPressRelease')}</h1>
                        <div class="PressMeetCont">
                        <div class="PressMeet">
                                <h4>The New Times</h4>
                                <h2>FEATURED: Winner bags Rwf2m in Bazooka Lotto’s new jackpot</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/lottowinner3.jpeg" />
                                </div>
                                <a target="_blank" href="https://www.newtimes.co.com/featured/winner-bags-rwf2m-Bazooka-lottos-new-jackpot">https://www.newtimes.co.com/featured/winner-bags-rwf2m-Bazooka-lottos-new-jackpot</a>
                            </div>
                            <div class="PressMeet">
                                <h4>Kigali today</h4>
                                <h2>Yatsindiye Miliyoni ebyiri muri Tombola Bazooka Lotto’</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/lottowinner2.jpeg" />
                                </div>
                                <a target="_blank" href="https://www.kigalitoday.com/kwamamaza/article/yatsindiye-miliyoni-ebyiri-muri-tombola-Bazooka-lotto">https://www.kigalitoday.com/kwamamaza/article/yatsindiye-miliyoni-ebyiri-muri-tombola-Bazooka-lotto</a>
                            </div>
                        <div class="PressMeet">
                                <h4>Igihe</h4>
                                <h2>Nsabimana yashyikirijwe miliyoni 1 NGN yatsindiye binyuze muri Bazooka Lotto</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/lottowinner1.jpg" />
                                </div>
                                <a target="_blank" href="https://igihe.com/amakuru/article/nsabimana-yashyikirijwe-miliyoni-1-NGN-yatsindiye-binyuze-muri-Bazooka-lotto">https://igihe.com/amakuru/article/nsabimana-yashyikirijwe-miliyoni-1-NGN-yatsindiye-binyuze-muri-Bazooka-lotto</a>
                            </div>
                            <div class="PressMeet">
                                <h4>Kigali today</h4>
                                <h2>Video : Nsabimana abaye uwa mbere utsindiye miliyoni muri tombola Bazooka Lotto’</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/winnerlotto.png" />
                                </div>
                                <a target="_blank" href="https://www.kigalitoday.com/imikino-11/indi-mikino/Video-Nsabimana-abaye-uwa-mbere-utsindiye-miliyoni-muri-tombola-Bazooka-Lotto">https://www.kigalitoday.com/imikino-11/indi-mikino/Video-Nsabimana-abaye-uwa-mbere-utsindiye-miliyoni-muri-tombola-Bazooka-Lotto</a>
                            </div>
                            <div class="PressMeet">
                                <h4>The New Times</h4>
                                <h2>FEATURED : New lottery lanched to boost national sports development</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/newsTime.jpg" />
                                </div>
                                <a target="_blank" href="https://www.newtimes.co.com/featured/new-lottery-launched-boost-national-sport-development">https://www.newtimes.co.com/featured/new-lottery-launched-boost-national-sport-development</a>
                            </div>
                            <div class="PressMeet">
                                <h4>Kigali today</h4>
                                <h2>Sobanukirwa byinshi kuri tombola Bazooka Lotto’ yatangijwe ku mugaragaro</h2>
                                <div class="PressMeetImg">
                                    <img src="./assets/img/kigali.jpg" />
                                </div>
                                <a target="_blank" href="https://www.kigalitoday.com/imikino-11/indi-mikino/article/sobanukirwa-byinshi-kuri-tombola-Bazooka-lotto-yatangijwe-ku-mugaragaro">https://www.kigalitoday.com/imikino-11/indi-mikino/article/sobanukirwa-byinshi-kuri-tombola-Bazooka-lotto-yatangijwe-ku-mugaragaro</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(SocialResponsibility))