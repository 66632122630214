import { appConstants } from "../helpers/actionType";

let user = JSON.parse(localStorage.getItem('user'));
let appVar = JSON.parse(localStorage.getItem('appvariables'));
appVar = appVar != null ? appVar : { countryCode: "", currency: "₦", version: process.env.REACT_APP_VERSION };
let pagemenu = localStorage.getItem('pagemenuid');
pagemenu = pagemenu == null ? "0" : pagemenu;
const initialState = user ? { loggedIn: true, user, appvariables: (appVar ? appVar : { countryCode: "", currency: "₦", version: process.env.REACT_APP_VERSION }), pageFlog: 0, pageMenuID: pagemenu } : { pageFlog: 0, pageMenuID: pagemenu, appvariables: appVar };
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.LOGIN_REQUEST:
      return {
        ...state,
        user: action.user
      };
    case appConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.userdata
      };
    case appConstants.LOGIN_ERROR:
      return {        
        pageFlog: state.pageFlog,
        appvariables: state.appvariables
      };
    case appConstants.LOGOUT:
      let usernull = state.user;
      if (usernull != undefined) {        
        localStorage.setItem('user', JSON.stringify(usernull));
      }

      return { 
        ...state,       
        pageFlog: state.pageFlog,
        appvariables: state.appvariables
      };
    case appConstants.REGISTER_SUCCESS:
      return {
        ...state,
        pageFlog: action.pageFlog,
        otpMobileNo: action.otpMobileNo
      };
    case appConstants.REGISTER_ERROR:
      return {
        pageFlog: state.pageFlog,
        appvariables: state.appvariables
      };

    case appConstants.GET_BALANCE:
      let userdata = state.user;
      if (userdata != undefined) {
        userdata.winningAmt = action.winningAmt;
        userdata.currentcommissions = action.currentcommissions;
        userdata.availabeCommissions = action.availabeCommissions;
        userdata.balance = action.balance;
        userdata.winbalance = action.winbalance;
        userdata.Totwithdrawal = action.Totwithdrawal;
        userdata.Nooftktplay = action.Nooftktplay;
        userdata.affiliateID = action.affiliateID;
        userdata.totWing = action.totWing;
        userdata.withdrawlimit = action.withdrawlimit;
        userdata.WithdrawApprSts = action.WithdrawApprSts;
        userdata.AutoPurchseTkt = action.AutoPurchseTkt;
        userdata.kycstatus = action.kycstatus;
        userdata.kycapprvalstatus = action.kycapprvalstatus;

        localStorage.setItem('user', JSON.stringify(userdata));
      }
      return {
        ...state,
        user: { ...state.user, winningAmt: action.winningAmt, currentcommissions: action.currentcommissions, availabeCommissions: action.availabeCommissions, balance: action.balance, winbalance: action.winbalance, Totwithdrawal: action.Totwithdrawal, Nooftktplay: action.Nooftktplay, affiliateID: action.affiliateID, totWing: action.totWing, withdrawlimit: action.withdrawlimit, WithdrawApprSts: action.WithdrawApprSts, AutoPurchseTkt: action.AutoPurchseTkt, kycstatus: action.kycstatus, kycapprvalstatus: action.kycapprvalstatus }
      };
      case appConstants.GET_USER_PROFILE:
          let userprof = state.user;
          if (userprof != undefined) {
            userprof.emailID = action.emailID;
            localStorage.setItem('user', JSON.stringify(userprof));
          }
          return {
            ...state,
            //userProfile: action.profile
            user: { ...state.user, emailID: action.emailID }
          };

    case appConstants.SET_PAGE_MENU:
      localStorage.setItem('pagemenuid', action.pageMenuID);
      return {
        ...state,
        pageMenuID: action.pageMenuID
      };
    default:
      return { ...state }
  }
}