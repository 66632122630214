import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loginActions } from '../../../action/loginAction';
import { alertActions } from "../../../action/alertAction";
import { commonClass } from "../../../helpers/commonClass";
import KYCStatus from './KYCStatus';
import { withTranslation } from 'react-i18next';
class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            UpdtEmailID: '',
			ConfirmUpdtEmailID: '',
			UpdtProfileSubmitted: false,
        }; 
        this.handleInputChange = this.handleInputChange.bind(this);       
        this.submitPrfleUpdt = this.submitPrfleUpdt.bind(this);
        //this.props.GetUserProfile(this.props.user.mobileNo,this.props.i18n.language);
    }

    setTab = (id) => {		
		this.setState({
			tabID: id,
		});		
	};

    static getDerivedStateFromProps(props, state) {
        return {
            userProfile: props.userProfile,
        }
    };

    handleInputChange(e) {
		try {
			let name = e.target.name;
			let value = e.target.value;
			this.setState({
				[name]: value
			});
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleInputChange");
		}
	};

    showMsg = (msg) => {
		this.props.alertError(this.props.t(msg));
	}
	handleFormValidation(flog, data) {
		try {			
			//Email    
			if (flog == 2 && data && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data))) {
				this.showMsg("lblInvalidEmailID");

				return false;
			}			
			return true;
		}
		catch (ex) {
			commonClass.writeLog(ex, "handleFormValidation");
			return false;
		}
	};
    
    submitPrfleUpdt = async (e) => {
        let getIPad = "";
		try {
			e.preventDefault();
			this.setState({ UpdtProfileSubmitted: true });
			let { UpdtEmailID, ConfirmUpdtEmailID } = this.state;
			if (UpdtEmailID &&  ConfirmUpdtEmailID) {
				if(this.handleFormValidation(2, UpdtEmailID) && this.handleFormValidation(2, ConfirmUpdtEmailID)){ }
				else {
                    this.showMsg("Must fill all field value with correct format. or else refresh this page and try again.");
					//this.showMsg("Email id does not match");
					return;
				}

                if(UpdtEmailID != ConfirmUpdtEmailID){this.showMsg("Email id does not match"); return;}
				//forgotMobileNo = "250" + forgotMobileNo.substring(1, forgotMobileNo.length);

                if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
					getIPad = this.props.ClntIPAddr;
				}
				else{
					getIPad = await commonClass.GetIPAddress();					
				}

                let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

				this.props.GetUserProfile(this.props.user.mobileNo,UpdtEmailID, ConfirmUpdtEmailID, intlangflag, getIPad);
			}
		}
		catch (ex) {
			commonClass.writeLog(ex, "submitPrfleUpdt");
		}
	};

    render() {
        const { t } = this.props;
        const { UpdtEmailID, ConfirmUpdtEmailID, UpdtProfileSubmitted } = this.state;
        return (
            <section id="cTab8" class="tabCont">
                <div className="profileCont forpadd3">
                <div className="detailsHead">
                    <h2>{t('lblProfile')}</h2>
                    </div>
                
                {/*<div class="col-md-2 col-sm-2 col-xd-12">
                    <div class="browswImg">
                        <i class="fa fa-picture-o" aria-hidden="true"></i>
                        <div class="browswImgbtm">
                            <span><i class="fa fa-edit"></i>{t('lblBrowseImage')}</span>
                        </div>
        </div>
                </div>*/}                
                    <div class="profileContInr">                    
                    <a onClick={() => { this.setTab(10) }} class="bankLink">CUSTOMER KYC</a>
                        <div class="profileDetlsRow">                            
                                <label>{t('lblFullName')}:</label>
                                <span>{this.props.user.username}</span>                            
                        </div>
                        <div class="profileDetlsRow">                            
                                <label>{t('lblLastName')}:</label>                            
                                {/* <span>{this.state.userProfile.DOB}</span> */}
                                <span>{this.props.user.PlayLastName}</span>                                
                        </div>
                        <div class="profileDetlsRow">                                
                                    <label>{t('lblmobno')}:</label>                                
                                    <span>{this.props.user.mobileNo}</span>                                
                            </div>
                            {this.props.user.emailID &&
                            <div class="profileDetlsRow">                                
                                    <label>{t('lblEmail')}:</label>
                                    <span>{this.props.user.emailID}</span>                                
                            </div>
                            }
                            <div class="profileDetlsRow">                                
                                    <label>Referrer's code:</label>
                                    <span>{this.props.user.PlayRefMobNo}</span>                                
                            </div>
                            <div class="profileDetlsRow">                                
                                    <label>My Referral code:</label>
                                    <span>{this.props.user.myRefralcode}</span>                                
                            </div>
                    </div>                    
                </div>      
							<div className="emailConfrmSection">
                            <div className="detailsHead">
							<h2>{t('lblupdMail')}</h2>
							</div>
								<form>
									<div class="formField">	
                                    <label>Email*</label>																			
                                        <input maxLength="50" class="username" type="text" placeholder={`${t('lblEmail')} *`} value={this.state.UpdtEmailID} onChange={this.handleInputChange} name="UpdtEmailID" />										
										{UpdtProfileSubmitted && !UpdtEmailID && <small>{t('lblRequired')}</small>}										
                                        {UpdtProfileSubmitted && UpdtEmailID && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(UpdtEmailID))) && <small>{t('lblInvalidEmailID')}</small>}
									</div>
									<div class="formField">										
                                    <label>Confirm Email*</label>
                                        <input maxLength="50" class="username" type="text" placeholder={`${t('lblConfirmEmail')} *`} value={this.state.ConfirmUpdtEmailID} onChange={this.handleInputChange} name="ConfirmUpdtEmailID" />										
										{UpdtProfileSubmitted && !ConfirmUpdtEmailID && <small>{t('lblRequired')}</small>}
										{UpdtProfileSubmitted && ConfirmUpdtEmailID && (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ConfirmUpdtEmailID))) && <small>{t('lblInvalidEmailID')}</small>}
									</div>
									<div class="formField">
										<a className="confrmBtn" href="#"><button onClick={this.submitPrfleUpdt}>{t('lblSubmit')}</button></a>
									</div>									
								</form>
							</div>
                            {this.state.tabID == 10 && <KYCStatus />}
            </section>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { userProfile } = state.accountReducer;
    return { user, userProfile }
}

const mapActionToProps = {
    GetUserProfile: loginActions.GetUserProfile,
    alertError: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(UserProfile))