import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';

//function HappyPlayers() {
    class HappyPlayers extends Component {
        constructor(props) {
            super(props);
    
        }
        static getDerivedStateFromProps(props, state) {
            return {
                ResentResult: props.ResentResult
            };
        }

        Load_WinPlayers = () => {
		
            let stkamtselt = [];
            let stkamtopt1 = [];					
    
                for (let i = 0; i < this.props.RecentResultdts[1][0].WinnerImages.split('|').length; i++) {

                    stkamtopt1.push(<div className="col-md-4 col-xs-6 fornonePadd">
                        <div className="productCont">
                        <img src={this.props.RecentResultdts[1][0].WinnerImages.split('|')[i].split('~')[0]}/> {/* <img src="./assets/img/players/happy-player1.png"/> */}
                            <div className="title2 forPadd2">
                                <small>{this.props.RecentResultdts[1][0].WinnerImages.split('|')[i].split('~')[1]}</small>
                                <div class="ratings">
                                    {this.fixratings(this.props.RecentResultdts[1][0].WinnerImages.split('|')[i].split('~')[2])}                        
                                </div>
                            </div>
                            <p>{this.props.RecentResultdts[1][0].WinnerImages.split('|')[i].split('~')[3]}</p>
                        </div>
                    </div>);
                    
                    if(i == 2 || i == 5){
                        stkamtselt.push(<div className="NewsEction">{stkamtopt1}</div>);				
                        stkamtopt1 = [];
                    }
                }
            return stkamtselt;
        };

        fixratings = (Ratings_cnt) => {
            let stkamtopt = [];
            for (let i = 0; i < 5; i++) {
                if(i >= Ratings_cnt){                        
                    stkamtopt.push(<span><i class="fa fa-star" aria-hidden="true"></i></span>);
                }
                else{
                    stkamtopt.push(<span><i class="fa fa-star active" aria-hidden="true"></i></span>);
                    }				
                }			
            return stkamtopt;
        };

        render() {
            const { t } = this.props;
  return (   
    
    <div className="customerReview">
    {this.props.RecentResultdts && this.props.RecentResultdts.length > 0 && this.props.RecentResultdts[1][0].WinnerImages.length > 0 &&
                                    <div>
                                        {this.Load_WinPlayers()}                                     
                                    </div>
    }
    </div>
    
  );
}
}

function mapStateToProps(state) {
    const { RecentResultdts } = state.accountReducer;
    return { RecentResultdts };
}

const mapActionToProps = {    
}

//export default HappyPlayers
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(HappyPlayers))