import {appConstants} from '../helpers/actionType';
import { gameService } from '../services/gameService';
import { commonClass } from "..//helpers/commonClass";
import { alertActions } from "../action/alertAction";
import { history } from '../helpers/history';
export const lottoActions={
    AddToCart,
    SetCurrentDraw,
    SetCurrentDraw_call,
    RemoveFromCart,
    ClearCart,
    RemoveClosedDrawbetFromCart,
    Buybet,   
}

function AddToCart(cItem){ 
    return dispatch =>{        
    dispatch({type:appConstants.ADD_CARTITEM,cItem});        
   }
};

function RemoveFromCart(cid){ 
    return dispatch =>{        
    dispatch({type:appConstants.REMOVE_CARTITEM,cid});        
   }
};

function RemoveClosedDrawbetFromCart(did){ 
    return dispatch =>{        
    dispatch({type:appConstants.REMOVE_CLOSEDDRAW_CARTITEM,did});        
   }
};

function ClearCart(){ 
    return dispatch =>{        
    dispatch({type:appConstants.CLEAR_CARTITEM});        
   }
};

function SetCurrentDraw(cDraw){ 
    return dispatch =>{        
    dispatch({type:appConstants.SET_DRAW,cDraw});        
   }
};

function SetCurrentDraw_call(cDraw){ 
    localStorage.removeItem('cSetChooseDraw');    

    return dispatch =>{  
    localStorage.setItem('cSetChooseDraw', JSON.stringify(cDraw));   
    dispatch({type:appConstants.SET_DRAW,cDraw});           
   }
};

function Buybet(buyStr,getIPad) {
    try{
    return dispatch => {        
        dispatch({ type: appConstants.LOADING });
        let data = buyStr;
        commonClass.writeLog(data,"App_buyReq", getIPad);

        let ClntIPAddr=getIPad;
		dispatch({ type: appConstants.GET_IPADDRESS, ClntIPAddr});

        gameService.LotteryOnlineSale(data)
            .then(
                buyInfo => {
                    let myBet = generateBetSlip(buyInfo);                                   
                    dispatch({ type: appConstants.LOADED });

                    if (myBet.status == 1) {
                        dispatch(alertActions.error(myBet.msg));
                    }
                    else if (myBet.status == 3) {
                        dispatch(alertActions.error(myBet.msg));
                        setTimeout(() => { history.push({ pathname: '/account', state: { tabIDT: 1 } }) }, 2000)
                    }
                    else if (myBet.status == 4) {
                        dispatch(alertActions.error(myBet.msg));
                        setTimeout(() => { history.push('/login'); }, 2000)
                    }
                    else {
                        dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                    }
                    if (myBet.balance) {
                        let balance = myBet.balance;
                        let winbalance =myBet.winbalance;
                        let winningAmt = 0;
                        let currentcommissions = 0;
                        let availabeCommissions = 0;
                        let TktValidity = 0;
                    dispatch({ type: appConstants.GET_BALANCE, winningAmt, currentcommissions, availabeCommissions, balance, winbalance, TktValidity });
                    }                       
                },
                error => {
                    //dispatch({ type: appConstants.BUY_ERROR, error });
                    let myBet = { status: 2, msg: "Something Wrong!" };
                    dispatch({ type: appConstants.LOADED });
                    dispatch({ type: appConstants.BUY_SUCCESS, myBet });
                }
            );
    }
}
catch (ex) {
    commonClass.writeLog(ex, "", getIPad);
}
};

function generateBetSlip(mybet) {
    try {
        let betArr = mybet.split('~');
        if (betArr[0] != 0) {
            return { status: betArr[0], msg: betArr[1] };
        }
        mybet = mybet.substring(2, mybet.length);
        let tempStr = mybet.split('@');

        let tempStr1 = tempStr[1].split('~'); // this is get balance/bottom msg

        let tempStr2 = tempStr[0].split('|'); // this is buy string
        let tempStr3 = [];
        tempStr2.forEach(ele => {
            let _betValue = ele.split('~');
            if (_betValue.length > 1) {
                tempStr3.push({
                    Id: _betValue[0],
                    BallNo: _betValue[2], //_betValue[2].split(' ')[1],
                    StakeValue: parseFloat(_betValue[3]),
                    SubTotal:0, //parseFloat(_betValue[4]),
                    OddsValue:0, //_betValue[5],
                    GameTime: _betValue[4] + " " + _betValue[5],
                    GameID: parseInt(_betValue[6]),
                    TransDateTime: _betValue[8],
                    GameName: _betValue[10],
                    TktValidity: _betValue[11],            
                });
            }
        });

        let _bMsg="";

        //if( tempStr.length>2)
        //{
         //   _bMsg=tempStr[2];
        //}

        _bMsg=tempStr1[3];

        let betObj = {
            betLst: tempStr3,
            status: betArr[0],
            msg: "success",
            balance: tempStr1[0],
            winbalance: tempStr1[1],            
            //TktValidity: tempStr1[2],            
            familyID: 4,            
            buttomMsg:_bMsg,
            winningAmt : 0,
        currentcommissions : 0,
        availabeCommissions : 0
        }
        return betObj;
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

//function generateBetSlip(mybet) {
  //  try{
  //  let betArr = mybet.split('~');
  //  if (betArr[0] != 0) {
  //      return { status: betArr[0], msg: betArr[1] };
  //  }
  //  let tempStr = betArr[9].split('|');
 //   //let tempStr1 = tempStr[0].split('@');
 //   //let tempStr2 = tempStr[1].split('|');
 //   let tempStr1 = [];
 //   tempStr.forEach(el => {
  //      let tempStr2 = el.split('@');
   //     if (tempStr2.length > 1) {
   //         tempStr1.push({ no: tempStr2[0], amt: tempStr2[1] });
   //     }
  //  });
  //  let _bMsg="";
   // if( betArr.length>16)
   // {
   //     _bMsg=betArr[16];
   // }
   // let betObj = {
   //     Id: betArr[2],
   //     TransDate: betArr[3],
   //     TransTime: betArr[4],
   //     DrawNo: betArr[5],
   //     DrawDate: betArr[6],
   //     DrawTime: betArr[7],
   //     Ball: tempStr1,
     //   TotalStakeAmount: betArr[10],
       // GameName: betArr[13],
        //status: betArr[0],
        //msg: "success",
       // balance : betArr[11],
      //  winbalance : betArr[12],        
      //  familyID:4,
      //  buttomMsg:_bMsg,
      //  winningAmt : 0,
      //  currentcommissions : 0,
      //  availabeCommissions : 0
   // }    
    //return betObj;
//}
//catch (ex) {		
//    commonClass.writeLog(ex);
//}
//};