import React, { useState, Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginActions } from '../../action/loginAction'
import { gameActions } from '../../action/gamesAction';
import { accountActions } from '../../action/accountAction';
import { commonClass } from "../../helpers/commonClass";
import { history } from '../../helpers/history';
import IdelSessionTimer from "../Pages/IdelSessionTimer";
import LangSelector from '../LangSelector';
import { withTranslation } from 'react-i18next';

class Header extends Component {
	constructor(props) {

		super(props);
		this.submitLogout = this.submitLogout.bind(this);
		this.state = {			
			gamesTab: "0",
			homeTab: "1",
			accountTab: "2",
			logoutTab: "3",
			resultTab: "4",
			contactTab: "5",
			howtoplay: "6",
			becomeanagent: "7",
			helpsupport: "8",
			MyNetwork: "12",
			dashboardTab: "13",
			NetworkRewardsTab: "14",
			searchTransID: ""
		}
		if (history && history.location) {
			switch (history.location.pathname) {
				case "/":
					this.setPageMenu(0);
					break;
				case "/home":
					this.setPageMenu(1);
					break;
				case "/howtoplay":
					this.setPageMenu(6);
					break;
				case "/dashboard":
					this.setPageMenu(13);
					break;
				case "/result":
					this.setPageMenu(4);
					break;
				case "/becomeanagent":
					this.setPageMenu(7);
					break;
				case "/helpsupport":
					this.setPageMenu(8);
					break;
				case "/pressrelease":
					this.setPageMenu(9);
					break;
				case "/winners":
					this.setPageMenu(10);
					break;
				case "/underconstruction":
					this.setPageMenu(10);
					break;
				case "/instant":
					this.setPageMenu(11);
					break;
				case "/MyNetwork":
					this.setPageMenu(12);
					break;
				case "/NetworkRewards":
					this.setPageMenu(14);
					break;
			}
		}
		//props.setPageMenu(6);
	}	

	//submitLogout(e) {
		submitLogout = async (e) => {
		e.preventDefault();
		let getIPad = "";

		if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
			getIPad = this.props.ClntIPAddr;
		}
		else{
			getIPad = await commonClass.GetIPAddress();					
		}
		
		let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

		//let user = JSON.parse(localStorage.getItem('user'));		
		this.props.logout(this.props.user.mobileNo,intlangflag,getIPad); //this.props.logout();
	};

	setPageMenu = (id) => {
		this.props.setPageMenu(id);
	};
	componentDidMount() {
		window.documentReadyjQuery();
	};

	static getDerivedStateFromProps(props, state) {
		return {
			games: props.games,
			pageMenuID: props.pageMenuID
		};
	};

	searchPrintTicker = () => {
		if (this.state.searchTransID && this.state.searchTransID.trim() != "") {
			this.props.ReprintTicket(this.state.searchTransID.trim(), this.props.user.mobileNo, "-1", this.props.i18n.language);
		}
		else {
			alert(this.props.t('lblEnter') + " Barcode/SlipId");
		}
	};
	

	render() {
		const { t } = this.props;
		return (
				<header className="bg1">			
				{this.props.user && this.props.user.mobileNo != "0" && <IdelSessionTimer />}
            <div className="headBtom">
                <div className="container">

                    <nav className='navbar'>
                        <Link to ='/home' className='nav-logo logo'><img src="./assets/img/logo.jpg" alt="Bazookalotto Logo" /></Link>                        						
                        <a id="mobile-nav" className="menu-nav" href="#"></a>
                        <div className='menu'>
						<ul className='mobilemenu'>
							{this.props.user && <li><Link to ='/home' className={`${this.props.pageMenuID == '1' ? 'activeMenu' : ''}`}>{t('lblhome')}</Link></li>}          
							{this.props.user && this.props.user.mobileNo != "0" && <li><Link className={`${this.props.pageMenuID == '13' ? 'activeMenu' : ''}`} to="/dashboard" >{t('lblDashboard')}</Link></li>}
                            {this.props.user && <li className={`dropdown ${this.props.pageMenuID == '0' ? 'activeMenu' : ''}`}>								
									<Link class="dropbtn">{t('lblGames')}<i class="fa fa-angle-down" aria-hidden="true"></i></Link>
									<div class="dropdown-content">
										{this.props.games && this.props.games.map((x, indx) =>
											<a key={`headergame_${indx}`} onClick={() => this.props.SelectGameFamily(x)} >{x.name}</a>
										)}
									</div>
							</li>}
							{this.props.user && <li><Link className={`${this.props.pageMenuID == '4' ? 'activeMenu' : ''}`} to="/result" >{t('lblResults')}</Link></li>}                                							
                            {this.props.user && this.props.user.mobileNo != "0" && <li><Link className={`${this.props.pageMenuID == '12' ? 'activeMenu' : ''}`} to="/MyNetwork" >{t('lblMyNetwork')}</Link></li>}
							{this.props.user && <li><Link className={`${this.props.pageMenuID == '6' ? 'activeMenu' : ''}`} to="/howtoplay" >{t('lblhowitwork')}</Link></li>}
							{this.props.user && <li><Link className={`${this.props.pageMenuID == '14' ? 'activeMenu' : ''}`} to="/NetworkRewards">Rewards</Link></li>}
                            </ul>
							</div>
							{this.props.user && this.props.user.mobileNo == "0" &&
                            <div className='loginSec'>	
							<div className='beforeLogin'>						
                                <Link to ={{ pathname: "/login", state: { isRegister: 0 } }}><button>{t('lblsignin')}</button></Link>
								<Link to={{ pathname: "/Reg", state: { isRegister: 1 } }}><button id="topsignBtn">{t('lblRegister')}</button></Link>								
                            </div>
							</div>
							}
							{this.props.user && this.props.user.mobileNo != "0" &&
							<div className='loginSec'>							
							<Link className='afterLoginSec' to ='/account'>                    
						    <div className="afterLoginCntent">
							<div className="aftrCon">								
							</div>
							<div className="profile">
								<div className="loginImgCon"><img src={this.props.user.ImgBase64str}/></div>
								<span>{t('lblHello')}, {this.props.user.username} <i className="fa fa-caret-down" aria-hidden="true"></i></span>
								{this.props.user.mobileNo != "0" ? <small> {this.props.user.mobileNo}</small> : <small></small>}
							</div>

                            <div className="headCurBlnce"><div id="mycurrentBal"><span>{t('lblCreditsBal')}</span><span> {this.props.appvariables.currency} {this.props.user.balance}</span></div><div id="mycurrentBal"><span>{t('lblWinBal')}</span> <span> {this.props.appvariables.currency} {this.props.user.winbalance}</span></div></div>							
						</div>
					
                    </Link>
					<div class="logoutSec"><a onClick={this.submitLogout}><i class="fa fa-sign-out" aria-hidden="true"></i></a></div>
							</div>							
							}
							{this.props.user && this.props.user.mobileNo != "0" &&
							<div className="headReferal">
							<span>{t('lblmyRefcode')}</span>
							<span>{this.props.user.myRefralcode}</span>
						    </div>
	                        }
                        						
						{/* <Link to="/mobileapp" target="_blank" className="appMobile"><img src="./assets/img/Appstore.png"/></Link> */}
                    </nav> 
					
                </div>

            </div>
        </header>	
			
		)
	};
}

function mapStateToProps(state) {
	const { loggingIn, user, pageMenuID, appvariables } = state.loginReducer;
	const { games } = state.gameReducer;
	return { loggingIn, user, pageMenuID, games, appvariables };
}
const mapActionToProps = {
	logout: loginActions.logout,
	SelectGameFamily: gameActions.SetGameFamily,
	setPageMenu: loginActions.setPageMenu,
	ReprintTicket: accountActions.ReprintTicket,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Header))