import React, { Component } from 'react'
import { connect } from 'react-redux';

import { accountActions } from '../../../action/accountAction';
import { commonClass } from "../../../helpers/commonClass";
import { alertActions } from '../../../action/alertAction';
import Header from "../../Header/Header";
import { history } from '../../../helpers/history';
import { withTranslation } from 'react-i18next';

class MyNetwork extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            NetworkmobileNo: '',            
            //GetlstTierNetworkdtls:[]
        }
        this.setState({
            reportID: 12,            
        });
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        if ((name.toLowerCase().includes("mobile")) && value && (/^[0-9\b]+$/.test(value)) == false) {
            return;
        }
        this.setState({
            [name]: value
        })
    };

    getRecord = async () => {
        let getIPad = "";
        this.state.callflag=0;
        if(document.getElementById("date-time3").value == 'NaN-NaN' || document.getElementById("date-time3").value == 'MM-YYYY'){alert('select month');}
        this.state.startDate = document.getElementById("date-time3").value;  
        
        if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
            getIPad = this.props.ClntIPAddr;
        }
        else{
            getIPad = await commonClass.GetIPAddress();					
        }

        let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
        
        this.props.GetMyNetwork(this.props.user.mobileNo, this.state.startDate, this.state.NetworkmobileNo, intlangflag, getIPad);        
    };

    getlstTierRecord = async () => { 
        let getIPad = "";
        this.state.callflag=1;
        //if(document.getElementById("datepicker4").value == 'NaN-NaN'){alert('select month');}
        //this.state.startDate = document.getElementById("datepicker4").value;                         

        if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
            getIPad = this.props.ClntIPAddr;
        }
        else{
            getIPad = await commonClass.GetIPAddress();					
        }

        let intlangflag = this.props.i18n.language == "en" ? 1 : 2;

        this.props.GetlstTierNetwork(this.props.user.mobileNo, this.state.startDate, intlangflag, getIPad);        
        //this.props.GetMyNetwork(this.props.user.mobileNo, this.state.startDate, this.state.NetworkmobileNo, this.props.i18n.language);
    };

    reSetFilter() {
        var date = new Date();
        var ss = ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
        this.setState({
            startDate: ss,
            reportID: 12,
            callflag:0,
        });
        this.state.reportID = 12;
        this.state.startDate = ss;
        this.state.callflag=0;
        this.getRecord();
    };

    static getDerivedStateFromProps(props, state) {
        return {
            MyNetwork: props.MyNetworkReport != undefined ? props.MyNetworkReport : [],
            GetlstTierNetworkdtls: props.GetlstTierNetworkdtls != undefined ? props.GetlstTierNetworkdtls : []
        }
    };

    componentDidMount() {
        window.timecalender();
        var date = new Date();
        var ss = ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
        this.setState({
            startDate: ss,
            reportID: 12
        });
        this.state.reportID = 12;
        this.state.startDate = ss;
        this.state.callflag=0;
        document.getElementById("date-time3").value = ss;
        //this.getRecord();

        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            history.push('/login');
        }
    };

    render() {
        const { t } = this.props;
        const { NetworkmobileNo } = this.state;
        return (
            <div>
                <Header />
            <div className="mainContainer">
            <div className="row">                
            <div className="container forPadd1">
                <div className="detailsHead">
                    <h2>{t('lblMyNetwork')}</h2>
				</div>

                <div className="netWrkContHead">                    
                    <div className="netWrkCol1">
                        <div className="netWrkDetlsCont">
                                    <p>{t('lblNetwrkPlayerNo')} :</p>
                                    <input maxLength="11" type="text" placeholder={`${t('lblmobno')}`} name="NetworkmobileNo" value={this.state.NetworkmobileNo} onChange={this.handleInputChange} />
                                    {NetworkmobileNo && (!(/[0]\d{10}$/.test(NetworkmobileNo))) && <small>{t('lblInvalidMobileNo')}</small>}
                                </div>
                            </div>
                            <div className="netWrkCol2">
                            <div className="netWrkDetlsCont mnthYeaPicker">
                                    <p>{t('lblMonthPicker')} :</p>                                    
                                    <input class="startDate result" id="date-time3" name="date-time3" placeholder="MM-YYYY" maxLength="10" type="text" readOnly />
                                </div>
                            </div>
                            <div className="netWrkCol3">
									<div className="netWrkDetlsCont">
                                <button onClick={() => { this.getRecord() }}>{t('lblView1')}</button>
                                <button onClick={() => { this.reSetFilter() }}>{t('lblreset')}</button>
                            </div>                        
                            </div>
                    </div>

                    <div className="netWrkCont bg1">
                            {this.state.MyNetwork && this.state.MyNetwork.length > 0 && this.state.MyNetwork[0].lstrTierlevel &&
                                <div>
                                     <div className="netWrkSection">
                                     <ul>
                                            <li>
                                            <div className="levl1 levlDetls"><p className="text-bold">Level 1 : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[0].lstrTierlevel}</p></div>
                                            <div className="levl3 netWrksBtn"><button onClick={() => { this.getlstTierRecord() }}>{t('lblbtntierdtls')}</button></div>
                                            </li>
                                            <li>
                                            <div className="levl1 levlDetls"><p>Sold : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[0].lstrSaleComm}</p></div>
                                            </li>
                                            <li>
                                            <div className="levl1 levlDetls"><p>Commissions : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[0].lstrWinComm}</p></div>
                                            </li>
                                            </ul>
                                        </div>

                                        {this.state.callflag == 1 &&                                        
                                        <div class="netWrkDataShown">
                                            <span>1-TIER PLAYER COMMISION DETAILS</span>                                           

                                                <div class="booking scrollbar">
                        <div class="container">
                            <table>
                                <tr>
                                    <th className="Col20">{t('lblPlayername')}</th>
                                    <th className="Col20">{t('lblmobno')}</th>
                                    <th className="Col20">{t('lblSoldAmt')}</th>
                                    <th className="Col20">{t('lblCommAmt')}</th>                                    
                                </tr>
                                {this.state.GetlstTierNetworkdtls && this.state.GetlstTierNetworkdtls.length > 0 && this.state.GetlstTierNetworkdtls[0].PlayerName && this.state.GetlstTierNetworkdtls.map((x, idx) =>
                                    <tr>
                                        <td className="Col20">{x.PlayerName}</td>
                                        <td className="Col20">{x.MobileNo}</td>
                                        <td className="Col20">{x.Sold}</td>                                        
                                        <td className="Col20">{x.Commission}</td>                                        
                                    </tr>
                                )}
                                
                            </table>
                        </div>
                    </div>
                    {(this.state.GetlstTierNetworkdtls == null || this.state.GetlstTierNetworkdtls.length <= 0 || (this.state.GetlstTierNetworkdtls.length > 0 && this.state.GetlstTierNetworkdtls[0].PlayerName == undefined)) &&
                                    <div className="notFndData">{t('lblnorecordfound')}</div>
                                }           
                                        </div>}

                                        <div class="netWrkSection">
                                        <ul>
										<li>
                                        <div className="levl1 levlDetls"><p className="text-bold">Level 2 : </p></div>
                                        <div className="levl2 levlDetls"><p>{this.state.MyNetwork[1].lstrTierlevel}</p></div>
                                            </li>
                                            <li>
                                            <div className="levl1 levlDetls"><p>Sold : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[1].lstrSaleComm}</p></div>
                                            </li>
                                            <li>
                                            <div className="levl1 levlDetls"><p>Commissions : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[1].lstrWinComm}</p></div>                                            
                                            </li>
                                            </ul>
                                        </div>

                                        <div className="netWrkSection">
									<ul>
										<li>
											<div className="levl1 levlDetls"><p className="text-bold">Level 3 : </p></div>
											<div className="levl2 levlDetls"><p>{this.state.MyNetwork[2].lstrTierlevel}</p></div>
										</li>
										<li>
											<div className="levl1 levlDetls"><p>Sold : </p></div>
											<div className="levl2 levlDetls"><p>{this.state.MyNetwork[2].lstrSaleComm}</p></div>
										</li>
										<li>
											<div className="levl1 levlDetls"><p>Commissions : </p></div>
											<div className="levl2 levlDetls"><p>{this.state.MyNetwork[2].lstrWinComm}</p></div>
										</li>
									</ul>
								</div>
                                <div className="netWrkSection">
									<ul>
										<li>
											<div className="levl1 levlDetls"><p className="text-bold">Total Commissions : </p></div>
											<div className="levl2 levlDetls"><p className="text-bold">{this.state.MyNetwork[3].lstrTotComm}</p></div>
										</li>
									</ul>
								</div>
                                </div>
                            }
                            {this.state.MyNetwork && this.state.MyNetwork.length > 0 && this.state.MyNetwork[0].lstrNTWPLlevel &&
                                <div>
                                       <div class="netWrkSection">
                                        <ul>
										<li>
                                        <div className="levl1 levlDetls"><p className="text-bold">Mobile No : </p></div>
                                        <div className="levl2 levlDetls"><p>{this.state.MyNetwork[0].lstrNetworkMob} ( {this.state.MyNetwork[0].lstrNTWPLlevel} )</p></div>
                                            </li>
                                            <li>
                                            <div className="levl1 levlDetls"><p>Sold : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[0].lstrSaleComm}</p></div>
                                            </li>
                                            <li>
                                            <div className="levl1 levlDetls"><p>Commissions : </p></div>
                                            <div className="levl2 levlDetls"><p>{this.state.MyNetwork[0].lstrWinComm}</p></div>                                            
                                            </li>
                                            </ul>
                                        </div>
                                </div>
                            }

                            {this.state.MyNetwork && this.state.MyNetwork.length > 0 && this.state.MyNetwork[0].lstrError &&
                                <div>
                                    <table>
                                        <tr>
                                            <td>{this.state.MyNetwork[0].lstrError}</td>
                                        </tr>
                                    </table>
                                </div>
                            }

                            {this.state.MyNetwork == null &&
                                <div>
                                    <table>
                                        <tr>
                                            <td>{t('lblnorecordfound')}</td>
                                        </tr>
                                    </table>
                                </div>
                            } 
                    </div>

                </div>
            </div>
            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { MyNetworkReport, GetlstTierNetworkdtls } = state.accountReducer;    
    // const { user } = state.loginReducer;
    const { user, appvariables } = state.loginReducer;
    return { user, appvariables, MyNetworkReport, GetlstTierNetworkdtls };    
}

const mapActionToProps = {
    GetMyNetwork: accountActions.GetMyNetwork,
    GetlstTierNetwork: accountActions.GetlstTierNetwork,
    error: alertActions.error,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(MyNetwork))
