import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../Header/Header";
import { withTranslation } from 'react-i18next';

class Winners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            winnerList: []
        };
    }

    componentDidMount() {
        this.setjsondata();
    };
    componentDidUpdate() {
		window.applywinnerslid();
	};

    setjsondata = (myJson) => {
        fetch('assets/img/banner/banner.json')
            .then(response => response.json())
            .then(data =>
                this.setState({ winnerList: data.winnerList })
            );
    };

    render() {
        const { t } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div class="container">
                    <div class="wrap">
                        <h1 style={{textAlign:'center',color:"#009A2C",padding:"10px 0p;"}} >{t('lblOurWinners')}</h1>
                        <div class="flexGallery">
                            <div id="slider" class="flexslider">
                                <ul class="slides">
                                {this.state.winnerList && this.state.winnerList.map((x, indx) =>
								<li key={`wimg_${indx}`}><img src={x.imagPath} /></li>
							)}
                                </ul>
                            </div>

                             <div id="carousel" class="flexslider">
                                <ul class="slides">
                                {this.state.winnerList && this.state.winnerList.map((x, indx) =>
								<li key={`swimg_${indx}`}><img src={x.imagPath} /></li>
							)}
                                </ul>
                            </div> 

                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Winners))