import React, { Component } from 'react'
import { connect } from 'react-redux';
import { accountActions } from '../../../action/accountAction';
import { alertActions } from '../../../action/alertAction';
import { withTranslation  } from 'react-i18next';
import { commonClass } from "../../../helpers/commonClass"
class Withdraw extends Component {

	constructor(props) {
		super(props);
		this.state = {
			amount: 0,
			WDAprAmt:"0"
		};
	}


	
	submitWithdraw = () => {
		if (this.state.amount > 0) {
			var sTime = new Date(this.props.serverTime);
			let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo;			
			this.props.WithdrawMoney(this.props.user.mobileNo, this.state.amount, TransID,this.props.i18n.language);
			this.clearWithdraw();
		}
		else {
			this.props.error(this.props.t('lblInvaildWithdrawAmt'));						
		}
	};

	ChekKYC_data = () => {

		let _Withdrawdata = "";
		let intlangflag = "en-us"; //this.props.i18n.language == "en" ? 1 : 2;	

		//To test
		//_Withdrawdata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~${intlangflag}~1~${this.state.amount}~0`);
		//let _swdwlUrl = `${process.env.REACT_APP_PG_URL}/payment/withdraw?PlayerInfoWithdraw=${_Withdrawdata}`;
		//window.location.href = _swdwlUrl;
		//return false;
//end

		if (this.state.WDAprAmt != "0") {
			if (this.state.amount == 0) {
			if(this.state.WDAprAmt.search('Pending') == -1){
				_Withdrawdata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~${intlangflag}~1~${this.state.WDAprAmt}~0`);
			}
			else{
				this.props.error("Kindly select Approval status / enter the amount for new withdrawal request.");
				return false;
			}
			}
			else{
					this.props.error("Kindly select Approval status / enter the amount for new withdrawal request.");
					return false;
				}
		}
		else{
			if (this.state.amount > 0) {

				if(parseInt(this.props.user.winbalance) < parseInt(this.state.amount)){
					this.props.error('Kindly enter eligible amount to withdraw processing!');
					return false;
				}

				if(parseInt(this.props.user.withdrawlimit.split(',')[0]) > parseInt(this.state.amount)){
					this.props.error('Minimum Withdrawable amount is N' + this.props.user.withdrawlimit.split(',')[0]);
					return false;
				}

				if(parseInt(this.props.user.withdrawlimit.split(',')[1]) < parseInt(this.state.amount)){
					this.props.error('Maximum Withdrawable amount is N' + this.props.user.withdrawlimit.split(',')[1]);
					return false;
				}

				if (this.state.WDAprAmt != "0") {
					this.props.error('Select anyone Approval status /  enter the amount for new withdrawal request.');
					return false;
				}

				if(this.props.user.kycstatus == "0"){ // 0-Not Kyc upload, 1-Kyc already uploaded
										
					this.props.error('Kindly update CustomerKYC form using customerKYC link before withdrawal');
					let _wdwlUrlkyc = ``;
		            let _edatakyc=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~${intlangflag}~1~0$${_wdwlUrlkyc}`);

		            let _addUrlkyc = `${process.env.REACT_APP_PG_URL}/KYCForm/Save?KYCInfo=${_edatakyc}`;

					setInterval(() => {
						window.location.href = _addUrlkyc;
					}, 8000);					

					return false;
				}

			_Withdrawdata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~${intlangflag}~1~${this.state.amount}~0`);
			}
			else {
				this.props.error(this.props.t('lblInvaildWithdrawAmt'));
				return false;
			}
		}
		
		let _wdwlUrl = `${process.env.REACT_APP_PG_URL}/payment/withdraw?PlayerInfoWithdraw=${_Withdrawdata}`;
		let _edata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~${intlangflag}~1~0$${_wdwlUrl}`);
        
		//let _addUrl = `http://localhost:50264/KYCForm/Save?KYCInfo=Z1F5fkGqV7YsxlxckyctPyENhYUbzIeYedgJmvctkPbEUyNHquXVuKZcxedh/OpFZM4oPwCbrQeZd97vl6EW7Wt1nQowdjGEOMACy5oVpb3zPmnaiJLvIx/M1tyNgtJTwd3X7kCdPLtpEHvqwRd7Fka6KIJt0sjEfhO4jlrbmnFQX9A5e9UJStbxngBIsmuL`;

		let _addUrl = `${process.env.REACT_APP_PG_URL}/KYCForm/Save?KYCInfo=${_edata}`;

		if((this.props.user.kycstatus == "1" && this.props.user.kycapprvalstatus.split('-')[0] == "1")){ // 0- Not KYC upload , 1- KYC Uploaded
			window.location.href = _wdwlUrl;
		}
		else{
			if((this.props.user.kycstatus == "1" && this.props.user.kycapprvalstatus.split('-')[0] == "0")) // KYC Not Approval
			{
				this.props.error(this.props.user.kycapprvalstatus.split('-')[1]);
				//alert(this.props.user.kycapprvalstatus.split('-')[1]);
				return false;				
			}
			else{				
				window.location.href = _addUrl;
			}
		}

		this.clearWithdraw();
		
	};

	// ChekKYC = (WDPGlink) => {

	// 	if(this.props.user.kycstatus == "0"){
	// 		window.location.href = WDPGlink;
	// 	}
	// 	else{
	// 		if(this.props.user.kycapprvalstatus.split('-')[0] == "0")
	// 		{
	// 			//alert(this.props.user.kycapprvalstatus.split('-')[1]);

	// 			window.location.href = WDPGlink;				
	// 		}
	// 		else{				
	// 			window.location.href = WDPGlink;
	// 		}			
	// 	}
		
	// };

	CheckAll = (e) => {

		if(e.target.checked == true)
		{
			var inputs = document.querySelectorAll('.checkbox');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].checked = false;
        }

			e.target.checked = true;			
		}
		else{e.target.checked = false;}				
	}

	clearWithdraw = () => {
		this.setState({
			amount: 0,
			WDAprAmt : ""
		});
		this.state.amount = 0;
		this.state.WDAprAmt = "0";
	};
	render() {
        const { t } = this.props;
		// //let _addUrl=`${process.env.REACT_APP_PG_URL}/Paymode/Withdraw?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`;
		// //let _edata=commonClass.Encryption(`player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`)

		// let _Withdrawdata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1~${this.state.amount}`); //let _Withdrawdata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1`);
		// let _wdwlUrl = `${process.env.REACT_APP_PG_URL}/payment/withdraw?PlayerInfoWithdraw=${_Withdrawdata}`;
		// let _edata=commonClass.Encryption(`${this.props.user.mobileNo}~${this.props.user.loginKey}~en-us~1$${_wdwlUrl}`);
        
		// //let _addUrl = `http://localhost:50264/KYCForm/Save?KYCInfo=Z1F5fkGqV7YsxlxckyctPyENhYUbzIeYedgJmvctkPbEUyNHquXVuKZcxedh/OpFZM4oPwCbrQeZd97vl6EW7Wt1nQowdjGEOMACy5oVpb3zPmnaiJLvIx/M1tyNgtJTwd3X7kCdPLtpEHvqwRd7Fka6KIJt0sjEfhO4jlrbmnFQX9A5e9UJStbxngBIsmuL`;

		// let _addUrl = `${process.env.REACT_APP_PG_URL}/KYCForm/Save?KYCInfo=${_edata}`;

        return (
			<section class="tabCont">
				<div className="accountDetls forpadd1">
				<div className="detailsHead">
				<h2>{t('lblWithdrawal')}								
				</h2>
				</div>
				<div className="accountDetlsInr moneyWthDrwDtls">						
							<span><img src="./assets/img/icons/money-bill.png" />{this.props.appvariables.currency} {this.props.user.winbalance}</span>						
						{/*<div class="accountinr">
							<input type="text" maxLength="8" placeholder={`${t('lblAmount')} *`} value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })} />
		</div>
						<div class="accountBtn">
							<a class="blueBg" onClick={() => { this.submitWithdraw() }}>{t('lblWithdrawal')}</a>
						</div>*/}

						<div class="accountinr">
							<input type="text" maxLength="8" placeholder={`${t('lblAmount')} *`} value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })} />
						</div>
						<div class="wthDrwBtn">	
							{/*<a href={_addUrl}  target="_self">{t('lblWithdrawal')}</a>*/}
							{/*<a onClick={() => { this.ChekKYC(_addUrl) }}>{t('lblWithdrawal')}</a>*/}
							<a onClick={() => { this.ChekKYC_data() }}>{t('lblWithdrawal')}</a>
						</div>
						<div>
						<p>Note : </p>
						<p> 1. Minimum Withdrawable amount : {this.props.user.withdrawlimit.split(',')[0] } & Maximum Withdrawable amount : {this.props.user.withdrawlimit.split(',')[1]}</p>
						<p> 2. Before withdraw, upload your KYC form</p>
						</div>
				</div>

{this.props.user.WithdrawApprSts && this.props.user.WithdrawApprSts.length > 0 && 
	<div className="accountDetlsInr moneyWthDrwDtls">
						<div>
							<h3 className='detailsHead'>Requested withdrawal Approval status</h3>
						</div>								
					
						<ul>
						{
						this.props.user.WithdrawApprSts && this.props.user.WithdrawApprSts.length > 0 && this.props.user.WithdrawApprSts.split('|').map((x,inx) => 
							
						<li>							
							<input type="checkbox" class="checkbox" onChange={(e) =>{this.CheckAll(e); this.setState({ WDAprAmt: e.target.checked == true? e.target.value : "0" })}} value={x} /><label>{x.split('$')[2]}</label>
						</li>												
						)}
						</ul>
					</div>	
}
							

					<div className="payStacks">					
							<p>{t('lblOtherMethods')} </p>	
								<ul>
									<li>
										<a href="https://paystack.com/" target="_blank"><img src="./assets/img/icons/Paystackimg.png"/></a>
									</li>
									<li>
                                    <a href="#"><img src="./assets/img/icons/MasterCard_Logo.jpg"/></a>
									</li>           
									<li>            
                                    <a href="#"><img src="./assets/img/icons/vervehighestres.png"/></a>
									</li>    
									<li>            
                                    <a href="#"><img src="./assets/img/icons/Visa_Logo.jpg"/></a>
									</li>           
									<li>            
                                    <a href="#"><img src="./assets/img/icons/Secure-Payment.jpg"/></a>
									</li>
								</ul>
							</div>					
				</div>
			</section>
		)
	}
}

function mapStateToProps(state) {
	const { WithdrawMoney } = state.accountReducer;
	const { user , appvariables} = state.loginReducer;
	//const { serverTime } = state.gameReducer;
	const {serverTime } = state.commonReducer;
	return { WithdrawMoney, user, serverTime, appvariables };
}

const mapActionToProps = {
	WithdrawMoney: accountActions.WithdrawMoney,
	error: alertActions.error,
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Withdraw))