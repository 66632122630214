import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from "../../Header/Header";
import { gameActions } from '../../../action/gamesAction';
import { loginActions } from '../../../action/loginAction';
import { lottoActions } from '../../../action/lottoAction';
import { withTranslation } from 'react-i18next';
class DashBoard extends Component {

    constructor(props) {
        super(props);

		this.state = {
			currentDraw: {},	
			currentAvailableDraws: []
		};
        
        if (this.props.user && this.props.user.mobileNo != "0") {
			let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	
            this.props.getBalance(this.props.user.mobileNo,this.props.user.loginKey,intlangflag);
			this.props.GameAvailableGames(props.user.mobileNo, 0, intlangflag); //this.props.GameAvailableGames(props.user.mobileNo, props.currentGamesFamily.id,this.props.i18n.language);
        }
    }
	static getDerivedStateFromProps(props, state) {
		return {
			currentAvailableDraws: props.currentAvailableDraws,
			currentDraw: props.currentDraw
		};
	};
	
	BindAvaildraws = () => {
	if(this.props.currentAvailableDraws && this.props.currentAvailableDraws.length > 0)
	{
		let ballLst = [];	
		//var lstrdatabind = "";	
		for (let i = 0; i < this.props.currentAvailableDraws.length; i++) {
			//if(i == 0){lstrdatabind += "<div class=row>"};
			//lstrdatabind += "<div class='col-md-4'><div class='gameLiCont'><h5>" + this.props.currentAvailableDraws[i].DrawDate + "</h5><span><img src='./assets/img/gamescreen.png'/></span><a onClick= " + this.selectDraw(this.props.currentAvailableDraws[i]) + " key={`draw_" + this.props.currentAvailableDraws[i].DrawID + "`} className=" + (this.props.currentDraw != undefined && this.props.currentAvailableDraws[i].DrawID == this.props.currentDraw.DrawID) ? 'btn1 Active' : 'btn1' + ">PLAY</a></div></div>";
			ballLst.push(<div class="col-md-4"><div class="gameLiCont"><h5>{this.props.currentAvailableDraws[i].LotName + " - " + this.props.currentAvailableDraws[i].DrawDate + " " + this.props.currentAvailableDraws[i].DrawTime}</h5><span><img src="./assets/img/gamescreen.png"/></span><a onClick={() => { this.selectDraw(this.props.currentAvailableDraws[i]) }} key={`draw_${this.props.currentAvailableDraws[i].DrawID}`} className={(this.props.currentDraw != undefined && this.props.currentAvailableDraws[i].DrawID == this.props.currentDraw.DrawID) ? "btn1 Active" : "btn1"}>PLAY</a></div></div>);
			//if(i == 0){lstrdatabind += "</div>"};			
		}
		//ballLst.push(lstrdatabind);
		return ballLst;
	}		
	};

	selectDraw = (_draw) => {
		this.props.SetCurrentDraw_call(_draw);

		{this.props.games && this.props.games.map((z, indx) =>
			{if(z.id == _draw.GameGroupId){
				this.props.SelectGameFamily(z)
			}
		}
		)}
	};

    render() {
        const { t } = this.props;     
        return (
            <div>
<Header />
          
            <div className="mainContainer">            
	  <div className="row bg2 forPadd">
			<div className="container">		
				<div className="DateTime">
					<span>{t('lblDashboard')}</span>
				</div>
					
				<div className="col-md-3">
					<div className="playerDtls">
						<div className="playerDtlsHead">
							<span className="playerImg">
								<img src={this.props.user.ImgBase64str}/>
							</span>
							<small>{this.props.user.username} ( {t('lblAfflNum')} {this.props.user.mobileNo} )</small>
						</div>
					</div>
					</div>
					<div className="col-md-9 playerRprt">
						<ul>
							<li>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lblNTktPlay')}</span>
										<span>{this.props.user.Nooftktplay}</span>
									</div>
								</div>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lblWaltBal')}</span>
										<span>{this.props.user.balance}</span>
									</div>
								</div>
							</li>
							<li>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lbltotwin')}</span>
										<span>{this.props.user.totWing}</span>
									</div>
								</div>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lbltotwdral')}</span>
										<span>{this.props.user.Totwithdrawal}</span>
									</div>
								</div>
							</li>
							<li>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lblNoplayAssoc')}</span>
										<span>{this.props.user.affiliateID}</span>
									</div>
								</div>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lblcommearn')}</span>
										<span>{this.props.user.availabeCommissions}</span>
									</div>
								</div>
							</li>
							<li>
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lblActPurcTkt')}</span>
										<span>{this.props.user.AutoPurchseTkt}</span>
									</div>
								</div>	
								<div className="col-md-6 col-sm-6">
									<div className="bordEr">
										<span className="transDtls">{t('lblWinBal')} :</span>
										<span>{this.props.user.winbalance}</span>
									</div>
								</div>
							</li>							
						</ul>
					</div>
				</div>
			</div>

            <div class="accTabsdashbaord">
					<section class="tabCont">				
						<div class="accTabsRhtCont">
							<div class="dashRht">
								<div class="dashRhtTop">
									<div class="welcomeName">
										<h1>Welcome back, {this.props.user.username}</h1>
										<span>Check out new games specially curated for you</span>
									</div>
									{/* <a class="btn1" href="#">+ Invite a friend</a> */}
								</div>
								<div class="dashGameLi">
								<div class="row">
								{this.BindAvaildraws()}							
								</div>
								</div>
							</div>
						</div>
					</section>					
                </div>

            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    //const { user, appvariables } = state.loginReducer;
	//const { currentDraw } = state.lottoReducer;
	//const {currentGamesFamily, currentAvailableDraws } = state.gameReducer;
   // return {currentGamesFamily, user, appvariables,currentAvailableDraws,currentDraw }

	const {games,currentAvailableDraws} = state.gameReducer;	
	const { currentDraw } = state.lottoReducer;
	const { user, appvariables } = state.loginReducer;	
	return {games, currentAvailableDraws, currentDraw, user, appvariables};
}

const mapActionToProps = {
    getBalance: loginActions.getBalance,	
	SelectGameFamily: gameActions.SetGameFamily,
	GameAvailableGames: gameActions.GameAvailableGames,
	SetCurrentDraw_call: lottoActions.SetCurrentDraw_call
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(DashBoard))