import React, { Component } from 'react'
import { connect } from 'react-redux';
import AccountInfo from "./AccountInfo";
import Addmoney from "./Addmoney";
import Withdraw from "./Withdraw";
import BetHistory from "./BetHistory";
import AccountActivity from "./AccountActivity";

import { loginActions } from '../../../action/loginAction';
import AccountHistory from './AccountHistory';
import AccountSummary from './AccountSummary';
import ChangePassword from './ChangePassword';
import UserProfile from './UserProfile';
import KYCStatus from './KYCStatus';

import Header from "../../Header/Header";
import { history } from '../../../helpers/history';
import { commonClass } from "../../../helpers/commonClass";
import { withTranslation  } from 'react-i18next';

class AccountIndex extends Component {
	constructor(props) {
		super(props);
		// this.state = {
		// 	tabID: 0,
		// }
		if(this.props.location.state &&this.props.location.state.tabIDT){
			this.state = {tabID: this.props.location.state.tabIDT}
		}		
		else
		{
			this.state = {tabID: 0}
		}
		if (this.props.user && this.props.user.mobileNo != "0") {
			let intlangflag = this.props.i18n.language == "en" ? 1 : 2;	
			this.props.getBalance(this.props.user.mobileNo,this.props.user.loginKey,intlangflag);
		}
	}
	setTab = (id) => {
		if (id == 0 && this.props.user && this.props.user.mobileNo != "0") {
			let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
			this.props.getBalance(this.props.user.mobileNo,this.props.user.loginKey,intlangflag);
		}
		this.setState({
			tabID: id,
		});
		window.closeOption();	
		if(window.innerWidth < 801){		
			document.getElementsByClassName("dashSlideCloseBtn")[0].click();
		}			
	};

	componentDidMount() {		
		window.documentReadyjQueryAcc();
		window.dashLftSlide();
		if (this.props.user == undefined || this.props.user.mobileNo == "0") {
			history.push('/login');
		}
	};

	handleLogout = async () => {  
		let getIPad = "";

		if (this.props.ClntIPAddr != "" && this.props.ClntIPAddr != undefined && this.props.ClntIPAddr != null) {
			getIPad = this.props.ClntIPAddr;
		}
		else{
			getIPad = await commonClass.GetIPAddress();					
		}
		
		let intlangflag = this.props.i18n.language == "en" ? 1 : 2;
        
        this.props.logout(this.props.user.mobileNo,intlangflag,getIPad);
    }

	render() {
		const { t } = this.props;
		return (<div>
			<Header />					
							<nav>
							<button className="dashSlideOpenBtn btn1"><img src="./assets/img/icons/dashboard-btn.png"/><i class="fa fa-arrow-right"></i></button>
								<div class="content leftToggSlide">
								<button className="dashSlideCloseBtn"><i class="fa fa-close"></i></button>
								<ul id="accTabLi">								            					
										<li onClick={() => { this.setTab(0) }}><a class="tabBg0 active" data="-1"><img src="./assets/img/Acc_Icons/Asset5.png" /><span>{t('lblCheckBalance')}</span></a></li>
										{/* <li class="dropDownLi">
											<a class="tabBg1 dropDownC" data="#cTab1"><span><img src="./assets/img/icons/Withdraw-money.png" /></span>Add Money<i class="fa fa-angle-down" style={{ float: 'right' }} aria-hidden="true"></i></a>
											<div class="AddSubdiv">
												<ul>
													<li><a onClick={() => { this.setTab(1) }}>
														<i class="fa fa-google-wallet" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														Wallet</a></li>
													<li><a href={`${process.env.REACT_APP_PG_URL}/Paymode/Addmoney?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`} target="_self">
														<i class="fa fa-credit-card" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														KPay</a>
													</li>
												</ul>
											</div>
										</li>
										<li class="dropDownLi">
											<a class="tabBg1 dropDownC" data="#cTab1"><span><img src="./assets/img/icons/Withdraw-money.png" /></span>Withdraw<i class="fa fa-angle-down" style={{ float: 'right' }} aria-hidden="true"></i></a>
											<div class="AddSubdiv">
												<ul>
													<li><a onClick={() => { this.setTab(2) }}>
														<i class="fa fa-google-wallet" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														Wallet</a>
													</li>
													<li><a href={`${process.env.REACT_APP_PG_URL}/Paymode/Withdraw?data=player_id~${this.props.user.mobileNo}|user_id~${this.props.user.userid}|email_id~${this.props.user.emailID}|player_name~${this.props.user.username}|currency~RWF|cbk_url~${process.env.REACT_APP_PG_CALLBACK_URL}|cbk_card_url~${process.env.REACT_APP_PG_CALLBACK_CARD_URL}`} target="_self">
														<i class="fa fa-credit-card" style={{ color: "#f2b71d", marginRight: "10px" }} aria-hidden="true"></i>
														KPay</a>
													</li>
												</ul>
											</div>
										</li> */}
										{/* <li onClick={() => { this.setTab(9) }}><a className="tabBg4"><img src="./assets/img/Acc_Icons/Asset2.png"/><span>Previous Games</span></a></li> */}
										 <li onClick={() => { this.setTab(1) }}><a class="tabBg1" ><img src="./assets/img/Acc_Icons/Asset6.png" /><span>{t('lblAddMoney')}</span></a></li> 
										 <li onClick={() => { this.setTab(2) }}><a class="tabBg2" ><img src="./assets/img/Acc_Icons/Asset13.png" /><span>{t('lblWithdrawMoney')}</span></a></li> 
										<li onClick={() => { this.setTab(3) }}><a class="tabBg3" ><img src="./assets/img/Acc_Icons/Asset1.png" /><span>{t('lblGameDetails')}</span></a></li>
										<li onClick={() => { this.setTab(6) }}><a class="tabBg3" ><img src="./assets/img/Acc_Icons/Asset14.png" /><span>{t('lblAccountDetails')}</span></a></li>
										<li onClick={() => { this.setTab(5) }}><a class="tabBg4" ><img src="./assets/img/Acc_Icons/Asset3.png" /><span>{t('lblAccountSummary')}</span></a></li>
										{/*<li onClick={() => { this.setTab(4) }}><a class="tabBg4" ><img src="./assets/img/icons/Account Summary icon.png" /><span>{t('lblAccountSummary')}</span></a></li>
										<li onClick={() => { this.setTab(10) }}><a class="tabBg4" ><img src="./assets/img/Acc_Icons/Asset2.png" /><span>{t('lblKycstatus')}</span></a></li>*/}
										<li onClick={() => { this.setTab(7) }}><a class="tabBg4" ><img src="./assets/img/Acc_Icons/Asset11.png" /><span>{t('lblChangepwd')}</span></a></li>
										<li onClick={() => { this.setTab(8) }}><a class="tabBg4" ><img src="./assets/img/Acc_Icons/Asset12.png" /><span>{t('lblProfile')}</span></a></li>
										<li onClick={(e) => { e.preventDefault(); this.handleLogout() }}><a class="tabBg4" data="#cTab4"><img src="./assets/img/Acc_Icons/Asset15.png" /><span>{t('lblLogout')}</span></a></li>
									</ul>
								</div>
							</nav>
							{/* <div class="toogleacctCont">
								<span class="toogleacct" id="btnOption" >{t('lblOption')}</span>
							</div> */}
							
								{this.state.tabID == 0 && <AccountInfo />}
								{this.state.tabID == 1 && <Addmoney />}
								{this.state.tabID == 2 && <Withdraw />}								
								{this.state.tabID == 3 && <BetHistory />}
								{/*this.state.tabID == 4 && <AccountSummary />*/}
								{this.state.tabID == 5 && <AccountActivity />}								
								{this.state.tabID == 6 && <AccountHistory />}
								{this.state.tabID == 7 && <ChangePassword />}
								{this.state.tabID == 8 && <UserProfile />}
								{this.state.tabID == 9 && <UserProfile />}												
								{this.state.tabID == 10 && <KYCStatus />}
		</div>
		)
	}
};


function mapStateToProps(state) {
	const { user, appvariables } = state.loginReducer;
	return { user, appvariables }
}

const mapActionToProps = {
	getBalance: loginActions.getBalance,
	logout: loginActions.logout
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AccountIndex))