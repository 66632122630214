import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation  } from 'react-i18next';

//function ExpertsComments() {
	class ExpertsComments extends Component {
        constructor(props) {
            super(props);
    
        }
        static getDerivedStateFromProps(props, state) {
            return {
                ResentResult: props.ResentResult
            };
        }
        render() {
            const { t } = this.props;
  return (   
	
	<div className="revIew">
	<h3>Comments from experts</h3>
	{this.props.RecentResultdts && this.props.RecentResultdts.length > 0 && this.props.RecentResultdts[1].map((x, idx) =>                                    
	<div>
                                       {x.comments.split('|').map((z, inx) =>
									   <div className="revIewInner">
									   <div className="appArea">
										   <div className="appIcon"><img src={z.split('~')[0]}/></div>
										   <div className="appTxt">
											   <span>{z.split('~')[1]}</span>
										   </div>
									   </div>
									   <p>{z.split('~')[3]}</p>
									   <small>{z.split('~')[2]}</small>
								   </div>
                                        )}
										</div>
    )}
		{/* <div className="revIewInner">
			<div className="appArea">
				<div className="appIcon"><img src="./assets/img/comments_Imgs/comment_img1.png"/></div>
				<div className="appTxt">
					<span>James Tilly</span>
				</div>
			</div>
			<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
			<small>Nov 11,2023</small>
		</div>*/}
	</div>
  );
}
}

function mapStateToProps(state) {
    const { RecentResultdts } = state.accountReducer;
    return { RecentResultdts };
}

const mapActionToProps = {    
}

//export default ExpertsComments
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ExpertsComments))