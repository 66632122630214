import { appConstants } from "../helpers/actionType";
export const accountReducer = (state = { BetHistory: {},ResultReport:[], RecentResultdts:[], MyNetworkReport:[], GetlstTierNetworkdtls:[], AccountSummary: {} ,AccountHistory:{},AccHistMob:{},AccountActivity:{},AccActMob:{}, gethowtoplay:{}}, action) => {
  switch (action.type) {
    case appConstants.GET_BET_HISTORY:
      return {
        ...state,
        BetHistory: action.bethistory
      };
    case appConstants.ADD_MONEY_SUCCESS:
      return {
        ...state,
        AddMoney: action.addmoney
      };
    case appConstants.WITHDRAW_MONEY_SUCCESS:
      return {
        ...state,
        WithdrawMoney: action.withdraw,
        WithdrawMoney: action.withdrawMob,
      };
    case appConstants.GET_ACCOUNT_SUMMARY:
      return {
        ...state,
        AccountSummary: action.accountSummary
      };
      case appConstants.GET_RESULT_REPORT:
      return {
        ...state,
        ResultReport: action.resultReport        
      };
      case appConstants.GET_Recent_RESULT:
      return {
        ...state,        
        RecentResultdts: action.RecentResultdts
      };
      case appConstants.GET_HOWTOPLAY:
      return {
        ...state,        
        gethowtoplay: action.gethowtoplay
      };
      case appConstants.GET_MyNetwork_REPORT:
      return {
        ...state,
        MyNetworkReport: action.MyNetworkReport        
      };
      case appConstants.GET_MyNetwork_LstTier:
      return {
        ...state,        
        GetlstTierNetworkdtls : action.GetlstTierNetworkdtls
      };
    case appConstants.GET_ACCOUNT_HISTORY:
      return {
        ...state,
        AccountHistory: action.accountHistory        
      };
    case appConstants.GET_ACCOUNT_HISTORY_Mob:
      return {
        ...state,        
        AccHistMob:action.accHistMob
      };
    case appConstants.GET_ACCOUNT_ACTIVITY:
        return {
          ...state,
          AccountActivity: action.accountActivity          
        };
    case appConstants.GET_ACCOUNT_ACTIVITY_Mob:
        return {
          ...state,          
          AccActMob: action.accActMob
        };
        // case appConstants.GET_USER_PROFILE:
        //   let userdata = state.user;
        //   if (userdata != undefined) {
        //     userdata.emailID = action.emailID;
        //     localStorage.setItem('user', JSON.stringify(userdata));
        //   }
        //   return {
        //     ...state,
        //     //userProfile: action.profile
        //     user: { ...state.user, emailID: action.emailID }
        //   };
        
    default:
      return { ...state }

  }
}