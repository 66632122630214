import React, { Component } from 'react'
import { connect } from 'react-redux';
import HappyPlayers from './happy-players'
//import BazookaNews from './bazooka-news'
import ExpertsComments from './experts-comments'
import { accountActions } from '../../../action/accountAction';
import { withTranslation  } from 'react-i18next';

class RecentResult extends Component{
    constructor(props)
    {
        super(props);  
        this.state={
            RecentResult : {}
        };
        let intlangflag = this.props.i18n.language == "en" ? 1 : 2;		
        this.props.GetResentResult(intlangflag);  
    }
    static getDerivedStateFromProps(props, state) {
        return {            
            ResentResult: props.RecentResultdts != undefined ? props.RecentResultdts : [],
        }
    };
    componentDidUpdate() { 
        window.blexslider();    
    };

    
    render(){ 
		const { t } = this.props;
        return(

        //     <div className="row bg2">
		// <div className="container">
		// 	<div className="col-md-8 ">
<div>
		

				<div className="latesWinning forPadd">
                <p class="theme-Name">						
						<span>Results</span>
					</p>                
                <ul className="brands">						
                        {this.state.ResentResult && this.state.ResentResult.length > 0 && this.state.ResentResult[0].map((x, idx) =>
                        <li className="col-md-4">
                        <a href="#">
                            <div className="latestWiningMSg">											
                                <div className="newsEvents">
                                {(x.SubGameName.match("Awoof")) && <span class="latsLogo"><img src="./assets/img/latest-winning/awoof.png"/></span>}
                                {(x.SubGameName.match("Cash")) && <span class="latsLogo imgSize"><img src="./assets/img/latest-winning/cashcash.png"/></span>}
                                {(x.SubGameName.match("Billionaire")) && <span class="latsLogo imgSize"><img src="./assets/img/latest-winning/billionaire.png"/></span>}
                                {(x.SubGameName.match("Hammer")) && <span class="latsLogo imgSize"><img src="./assets/img/latest-winning/hammer.png"/></span>}                                
                                {(x.SubGameName.match("Jackpot")) && <span class="latsLogo"><img src="./assets/img/latest-winning/jockpot.png"/></span>}

                                {(x.GameName.match("Ghana")) && <span class="latsLogo ghanaLogo"><img src={`./assets/img/ghana/ghanaBall${idx+1}.png`}/></span>}

                                <div class="newsDate">
											<span>Date & Time</span>
											<label>{x.GameDateTime}</label>
										</div>
                                        <div class="newsDate">
											<span>Game ID</span>
                                            {(x.GameName.match("Ghana")) && <label>#{x.GameID} {x.SubGameName}</label>}
                                            {(x.GameName.match("Indoor")) && <label>#{x.GameID}</label>}
										</div>

                                        <div class="newsEventsContent">
											<div class="newsEventsDiv">                          
												<small>Winning Number</small>
												<div class="balls">	
                                                {x.Result.split(',').map((z, inx) =>
                                            <div class={`bg${inx+1}`}>{z}</div>                                               
                                                )}	
												</div>												
											</div>
											<div class="newsEventsDiv">                          
												<small>Machine Number</small>
												<div class="balls">	
                                                {x.MachineNo.split(',').map((m, iny) =>                                                                                                
                                            <div class={`bg${6+(iny)}`}>{m}</div>													
                                                )}	
												</div>	                                
											</div>
										</div>
                                </div>
                            </div>
                        </a>
                    </li>                               
                                )}
                                {(this.state.ResentResult ==null ||this.state.ResentResult.length <= 0 ||  (this.state.ResentResult.length > 0 && this.state.ResentResult[0][0].GameName==undefined) )&&
                                <li className="col-md-4">
                                <a href="#">
                                    <div className="latestWiningMSg">											
                                        <div className="newsEvents">
                                            <div className="newsDate">
                                                <h3></h3>                                    
                                                <small></small>                                                                                                
                                                <div>{t('lblnorecordfound')}</div>                                                				                                                                                            
                                            </div>                                            
                                        </div>
                                    </div>
                                </a>
                            </li>                                    
                                }
                        </ul>
				</div>
                
                <HappyPlayers />
				<ExpertsComments />
                </div>
                //</div>
                //<BazookaNews />
                //</div>
		//</div>
        )
    };
}

function mapStateToProps(state) {
    const { RecentResultdts } = state.accountReducer;
    return { RecentResultdts };
}

const mapActionToProps = {
    GetResentResult: accountActions.GetResentResult,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(RecentResult))
